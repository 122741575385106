import React from "react";

const EcosystemEclipseSVG = ({ isMobile }) => {
  return (
    <svg
      width={isMobile ? "345" : "690"}
      height={isMobile ? "82.5" : "165"}
      viewBox="0 0 690 165"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="eclipse-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor="#00FF82" stopOpacity="0.5" />
          <stop offset="70%" stopColor="#00FF82" stopOpacity="0.5" />
          <stop offset="100%" stopColor="#00FF82" stopOpacity="0.5" />
        </linearGradient>
      </defs>

      <path
        d="M687.651 82.9238C687.651 92.8747 679.093 103.073 661.721 112.84C644.536 122.5 619.518 131.276 588.404 138.674C526.212 153.462 440.178 162.63 345.066 162.63C249.953 162.63 163.919 153.462 101.728 138.674C70.6138 131.276 45.5953 122.5 28.4111 112.84C11.0383 103.073 2.48087 92.8747 2.48087 82.9238C2.48087 72.9728 11.0383 62.7741 28.4111 53.0076C45.5953 43.3472 70.6138 34.572 101.728 27.1738C163.919 12.386 249.953 3.2172 345.066 3.2172C440.178 3.2172 526.212 12.386 588.404 27.1738C619.518 34.572 644.536 43.3472 661.721 53.0076C679.093 62.7741 687.651 72.9728 687.651 82.9238Z"
        stroke="#1d1d1d59"
        strokeWidth="4.59846"
      />
      <path
        d="M570.885 131.75L570.885 131.75L570.888 131.752C571.476 132.083 571.68 132.847 571.346 133.425L565.259 143.969L565.134 144.185L565.35 144.31L575.883 150.401L575.883 150.401C576.476 150.744 576.678 151.497 576.335 152.09C575.993 152.683 575.24 152.885 574.647 152.543L562.561 145.565L562.561 145.565C562.454 145.503 562.361 145.422 562.286 145.325C562.212 145.227 562.157 145.116 562.125 144.998C562.093 144.879 562.085 144.756 562.101 144.634C562.117 144.513 562.157 144.395 562.219 144.289L562.219 144.289L569.197 132.203L569.197 132.203C569.278 132.062 569.386 131.939 569.515 131.84C569.643 131.741 569.79 131.668 569.947 131.626C570.104 131.584 570.268 131.574 570.429 131.595C570.589 131.616 570.745 131.669 570.885 131.75Z"
        fill="#1d1d1d59"
        stroke="#1d1d1d59"
        strokeWidth="0.5"
      />
      <path
        d="M507.134 22.6453L507.134 22.6453L507.132 22.6429C506.743 22.0916 506.885 21.3142 507.435 20.9348L517.453 14.0153L517.658 13.8733L517.516 13.6676L510.609 3.65162L510.609 3.65148C510.219 3.088 510.36 2.32089 510.923 1.9317C511.487 1.5425 512.254 1.68284 512.643 2.24632L520.574 13.7292L520.574 13.7294C520.644 13.8302 520.693 13.9438 520.719 14.0637C520.745 14.1836 520.747 14.3075 520.725 14.4281C520.703 14.5488 520.657 14.6639 520.591 14.7669C520.524 14.8698 520.438 14.9587 520.337 15.0283L520.336 15.0284L508.854 22.9596L508.853 22.9598C508.72 23.0523 508.569 23.1175 508.411 23.1517C508.252 23.1859 508.088 23.1885 507.928 23.1593C507.769 23.13 507.616 23.0696 507.48 22.9814C507.344 22.8932 507.226 22.779 507.134 22.6453Z"
        fill="#1d1d1d59"
        stroke="#1d1d1d59"
        strokeWidth="0.5"
      />
      <path
        d="M141.511 137.855L141.511 137.855L141.512 137.857C141.843 138.446 141.621 139.204 141.036 139.525L130.358 145.376L130.139 145.496L130.259 145.715L136.098 156.389L136.098 156.389C136.428 156.99 136.209 157.739 135.608 158.068C135.008 158.397 134.259 158.178 133.93 157.578L127.224 145.339L127.224 145.339C127.165 145.231 127.128 145.113 127.114 144.991C127.101 144.869 127.112 144.746 127.146 144.628C127.18 144.51 127.238 144.401 127.315 144.305C127.391 144.209 127.486 144.13 127.594 144.071L127.594 144.071L139.833 137.365L139.833 137.365C139.976 137.286 140.132 137.237 140.293 137.219C140.455 137.202 140.618 137.216 140.774 137.262C140.93 137.307 141.075 137.383 141.202 137.485C141.328 137.586 141.433 137.712 141.511 137.855Z"
        fill="#1d1d1d59"
        stroke="#1d1d1d59"
        strokeWidth="0.5"
      />
      <path
        d="M170.047 25.9908L170.047 25.9908L170.045 25.9894C169.457 25.658 169.252 24.8946 169.586 24.3164L175.674 13.7721L175.799 13.5558L175.583 13.4307L165.05 7.3399L165.05 7.33982C164.457 6.99741 164.255 6.24414 164.597 5.65107C164.94 5.05799 165.693 4.85616 166.286 5.19857L178.372 12.1764L178.372 12.1765C178.478 12.2377 178.572 12.3193 178.646 12.4166C178.721 12.5138 178.776 12.6249 178.808 12.7434C178.839 12.8618 178.847 12.9854 178.831 13.107C178.815 13.2286 178.775 13.3458 178.714 13.452L178.714 13.4522L171.736 25.5381L171.736 25.5384C171.655 25.6791 171.547 25.8025 171.418 25.9014C171.289 26.0003 171.142 26.0729 170.985 26.1149C170.829 26.1569 170.665 26.1676 170.504 26.1463C170.343 26.125 170.188 26.0722 170.047 25.9908Z"
        fill="#1d1d1d59"
        stroke="#1d1d1d59"
        strokeWidth="0.5"
      />

      <path
        d="M687.651 82.9238C687.651 92.8747 679.093 103.073 661.721 112.84C644.536 122.5 619.518 131.276 588.404 138.674C526.212 153.462 440.178 162.63 345.066 162.63C249.953 162.63 163.919 153.462 101.728 138.674C70.6138 131.276 45.5953 122.5 28.4111 112.84C11.0383 103.073 2.48087 92.8747 2.48087 82.9238C2.48087 72.9728 11.0383 62.7741 28.4111 53.0076C45.5953 43.3472 70.6138 34.572 101.728 27.1738C163.919 12.386 249.953 3.2172 345.066 3.2172C440.178 3.2172 526.212 12.386 588.404 27.1738C619.518 34.572 644.536 43.3472 661.721 53.0076C679.093 62.7741 687.651 72.9728 687.651 82.9238Z"
        stroke="url(#eclipse-gradient)"
        strokeWidth="4.59846"
        fill="none"
        strokeDasharray="200 1600"
        strokeDashoffset="0"
        style={{
          animation: "dash 5s linear infinite",
        }}
      />

      <style>
        {`
          @keyframes dash {
            from {
              stroke-dashoffset: 0;
            }
            to {
              stroke-dashoffset: -1800;
            }
          }
        `}
      </style>
    </svg>
  );
};

export default EcosystemEclipseSVG;
