import React, { useState } from "react";

import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import { EmailOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";

import {
  DuneButton,
  FadeInSection,
  GreenItalicText,
  LinkedInIcon,
} from "../../../components";

import { Translate } from "react-auto-translate";

import "./company.css";
import { spacing } from "../../../styles";

function Company() {
  const leadership = [
    {
      name: "David DellaPelle",
      role: "Co-founder & CEO",
      picture: "https://d3oo9a669kwmx7.cloudfront.net/headshots/david.jpg",
      linkLinkedIn: "https://www.linkedin.com/in/daviddellapelle/",
      email: "david@dune.security",
    },
    {
      name: "Michael Waite",
      role: "Co-founder & CTO",
      picture: "https://d3oo9a669kwmx7.cloudfront.net/headshots/michael.jpg",
      linkLinkedIn: "https://www.linkedin.com/in/mr-michael-waite/",
      email: "michael@dune.security",
    },
    {
      name: "Kyle Ryan",
      role: "Head of AI & Backend Engineering",
      picture: "https://d3oo9a669kwmx7.cloudfront.net/headshots/kyle.jpg",
      linkLinkedIn: "https://www.linkedin.com/in/kylejohnryan/",
    },
    {
      name: "Kaila Mathis",
      role: "Head of Growth",
      picture: "https://d3oo9a669kwmx7.cloudfront.net/headshots/kaila.jpg",
      linkLinkedIn: "https://www.linkedin.com/in/kaila-mathis-687395175/",
    },
    {
      name: "Claudia Dalmau Gomez",
      role: "Chief of Staff",
      picture: "https://d3oo9a669kwmx7.cloudfront.net/headshots/claudia.jpg",
      linkLinkedIn:
        "https://www.linkedin.com/in/claudia-dalmau-knownas-clauderocks/",
    },
    {
      name: "Rogelio Victorio Jr.",
      role: "Head of Frontend Engineering",
      picture: "https://d3oo9a669kwmx7.cloudfront.net/headshots/rogelio.jpg",
      linkLinkedIn: "https://www.linkedin.com/in/rogelio-victorio-jr/",
    },
    {
      name: "Sean Finn",
      role: "Head of Product Design",
      picture: "https://d3oo9a669kwmx7.cloudfront.net/headshots/sean.jpg",
      linkLinkedIn: "https://www.linkedin.com/in/seanpfinn/",
    },
    {
      name: "Paola Di Leo",
      role: "Content Producer",
      picture: "https://d3oo9a669kwmx7.cloudfront.net/headshots/paola.jpg",
      linkLinkedIn: "https://www.linkedin.com/in/paoladileo/",
    },
    {
      name: "Casey Zduniak",
      role: "Senior Software Engineer",
      picture: "https://d3oo9a669kwmx7.cloudfront.net/headshots/casey.jpg",
      linkLinkedIn: "https://www.linkedin.com/in/casey-zduniak-858764169/",
    },
    {
      name: "Grace Gately",
      role: "Marketing Lead",
      picture: "https://d3oo9a669kwmx7.cloudfront.net/headshots/grace.jpg",
      linkLinkedIn: "https://www.linkedin.com/in/gracegately/",
    },
    {
      name: "Tarun Ramesh",
      role: "Senior Software Engineer",
      picture: "https://d3oo9a669kwmx7.cloudfront.net/headshots/tarun.jpg",
      linkLinkedIn: "https://www.linkedin.com/in/tarunaravind2000/",
    },
    {
      name: "Juliëtte van Ravenswaay Claasen",
      role: "Cybersecurity Analyst",
      picture: "https://d3oo9a669kwmx7.cloudfront.net/headshots/juliette.png",
      linkLinkedIn:
        "https://www.linkedin.com/in/juli%C3%ABttevanravenswaayclaasen/",
    },
    {
      name: "Shivam Shekhar",
      role: "Frontend Software Engineer",
      picture: "https://d3oo9a669kwmx7.cloudfront.net/headshots/shivam.jpg",
      linkLinkedIn: "https://www.linkedin.com/in/shivamshekhar19/",
    },
    {
      name: "Jordan Migneault",
      role: "Data Engineer",
      picture: "https://d3oo9a669kwmx7.cloudfront.net/headshots/jordan.jpg",
      linkLinkedIn: "https://www.linkedin.com/in/jordan-migneault/",
    },
  ];

  const investors = [
    {
      name: "Craft",
      picture: "https://d3oo9a669kwmx7.cloudfront.net/logos/craft-logo.png",
    },
    {
      name: "Alumni Ventures",
      picture:
        "https://d3oo9a669kwmx7.cloudfront.net/logos/alumni-ventures-logo.png",
    },
    {
      name: "Antler",
      picture: "https://d3oo9a669kwmx7.cloudfront.net/logos/antler-logo.png",
    },
    {
      name: "Sequoia Scout",
      picture:
        "https://d3oo9a669kwmx7.cloudfront.net/logos/sequoia-scout-logo.png",
    },
    {
      name: "Firestreak",
      picture:
        "https://d3oo9a669kwmx7.cloudfront.net/logos/firestreak-logo.png",
    },
  ];

  const [imageLoaded, setImageLoaded] = useState({});

  const handleImageLoaded = (index) => {
    setImageLoaded((prev) => ({ ...prev, [index]: true }));
  };

  return (
    <>
      <FadeInSection>
        <Box
          display="flex"
          justifyContent="center"
          marginTop={{
            xs: "80px",
            md: "120px",
          }}
          padding="0 28px"
        >
          <Grid container maxWidth={spacing.MAX_WIDTH_WEBSITE} width="100%">
            <Grid item xs={12}>
              <Typography component="h1" className="dune-text-header-uppercase">
                <Translate>About</Translate>
              </Typography>
              <Typography
                variant="h2"
                component="h2"
                className="dune-text-header-main"
                marginTop="20px"
              >
                Dune Security{" "}
                <Translate>is reducing the attack surface for</Translate>{" "}
                <GreenItalicText text="every enterprise " />
              </Typography>
              <Typography variant="body" component="p" marginTop="20px">
                <Translate>
                  Led by a team of industry experts, we're on a mission to fight
                  AI with AI. We know that for today's enterprises, CISOs'
                  biggest risk lies in their employees, not their systems. By
                  utilizing contextualized testing and training to significantly
                  reduce employee error, our platform is eliminating social
                  engineering risk with automated remediation.
                </Translate>
              </Typography>
              <Box marginTop="40px">
                <Link to="/demo/">
                  <DuneButton variant="dune-glow" text=" Meet the team" />
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </FadeInSection>
      <Box display="flex" justifyContent="center" padding="0 28px">
        <Grid
          container
          width="100%"
          maxWidth={spacing.MAX_WIDTH_WEBSITE}
          height="100%"
        >
          <Grid
            item
            xs={12}
            marginTop={{
              xs: "80px",
              md: "120px",
            }}
          >
            <Typography
              variant="h2"
              component="h2"
              className="dune-text-header-main"
            >
              <Translate>Our team</Translate>
            </Typography>
            <Box display="flex" justifyContent="center">
              <Grid
                container
                marginTop={{
                  xs: "40px",
                  md: "80px",
                }}
                maxWidth="1080px"
              >
                {leadership.map((leader, index) => (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    marginTop={{
                      xs: index === 0 ? 0 : "40px",
                      md: index === 0 || index === 1 ? 0 : "40px",
                    }}
                    paddingLeft={{
                      xs: 0,
                      md: index % 2 === 1 ? "20px" : 0,
                    }}
                    paddingRight={{
                      xs: 0,
                      md: index % 2 === 0 ? "20px" : 0,
                    }}
                    key={`leader-${index}`}
                  >
                    <Stack direction="row" alignItems="center">
                      <Box maxWidth="200px" width="100%">
                        <Box
                          component="img"
                          className="company-image-leader"
                          src={leader.picture}
                          alt={leader.name}
                          onLoad={() => handleImageLoaded(index)}
                          sx={{
                            filter: imageLoaded[index] ? "none" : "blur(8px)",
                            transition: "filter 0.5s ease-in-out",
                          }}
                        />
                      </Box>
                      <Box marginLeft="40px">
                        <Typography
                          variant="body-emphasized"
                          component="p"
                          className="company-text-name"
                        >
                          {<Translate>{leader.name}</Translate>}
                        </Typography>
                        <Typography
                          variant="body"
                          component="p"
                          marginTop="20px"
                        >
                          {<Translate>{leader.role}</Translate>}
                        </Typography>
                        <Stack
                          direction="row"
                          alignItems="center"
                          marginTop="20px"
                          marginLeft="-2px"
                        >
                          <IconButton
                            className="company-icon-button"
                            title={`Go to ${leader.name}"s LinkedIn page.`}
                            aria-label={`Go to ${leader.name}"s LinkedIn page.`}
                            href={leader.linkLinkedIn}
                            target="_blank"
                          >
                            <LinkedInIcon size="24px" />
                          </IconButton>
                          {leader.email && (
                            <IconButton
                              className="company-icon-button"
                              title={`Email ${leader.name}.`}
                              aria-label={`Email ${leader.name}.`}
                              href={`mailto:${leader.email}`}
                            >
                              <EmailOutlined />
                            </IconButton>
                          )}
                        </Stack>
                      </Box>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        className="company-box-mission"
        display="flex"
        justifyContent="center"
        marginTop={{
          xs: "80px",
          md: "120px",
        }}
        padding="0 28px"
      >
        <Grid
          container
          alignItems="center"
          maxWidth={spacing.MAX_WIDTH_WEBSITE}
          padding="48px 0"
        >
          <Grid item xs={12} md paddingRight={{ xs: 0, md: "40px" }}>
            <Box maxWidth="544px" width="100%">
              <Box
                component="img"
                className="company-image-team"
                width="100%"
                alt="Dune Security team"
                src="https://d3oo9a669kwmx7.cloudfront.net/dune-team-small.jpg"
              />
            </Box>
          </Grid>
          <Grid
            item
            container
            xs={12}
            md
            justifyContent={{
              xs: "flex-start",
              md: "flex-end",
            }}
            marginTop={{
              xs: "8px",
              md: 0,
            }}
            paddingLeft={{ xs: 0, md: "40px" }}
          >
            <Typography variant="body" component="p">
              <Translate>
                Our mission is to strengthen cybersecurity by
              </Translate>{" "}
              <GreenItalicText text="equipping and educating employees, " />
              <Translate>
                transforming them into the most effective defense against
                threats.
              </Translate>
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        marginTop={{
          xs: "80px",
          md: "120px",
        }}
        marginBottom={{
          xs: "80px",
          md: "120px",
        }}
        padding="0 28px"
      >
        <Box
          display="grid"
          justifyContent="center"
          maxWidth={spacing.MAX_WIDTH_WEBSITE}
          width="100%"
        >
          <Typography
            variant="h2"
            component="h2"
            className="dune-text-header-main"
            textAlign="center"
          >
            <Translate>We're backed by incredible investors</Translate>
          </Typography>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            marginTop="80px"
            maxWidth="100%"
          >
            {investors.map((investor, index) => (
              <Grid
                item
                container
                xs={12}
                sm={6}
                md={4}
                lg
                justifyContent="center"
                marginTop={{
                  xs: index > 0 ? "40px" : 0,
                  sm: index > 1 ? "40px" : 0,
                  md: index > 2 ? "40px" : 0,
                  lg: 0,
                }}
              >
                <Box
                  component="img"
                  src={investor.picture}
                  alt={investor.name}
                  maxHeight="36px"
                  height="100%"
                  marginLeft={{
                    xs: 0,
                    sm: index % 2 !== 0 ? "28px" : 0,
                    md: index % 3 !== 0 ? "28px" : 0,
                    lg: index > 0 ? "28px" : 0,
                  }}
                  key={`investor-${index}`}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default Company;
