import React from "react";

const SaveTime = () => {
  return (
    <svg
      width="63"
      height="63"
      viewBox="0 0 63 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.5 11.5V31.5H46.5M61.5 31.5C61.5 35.4397 60.724 39.3407 59.2164 42.9805C57.7087 46.6203 55.499 49.9274 52.7132 52.7132C49.9274 55.499 46.6203 57.7087 42.9805 59.2164C39.3407 60.724 35.4397 61.5 31.5 61.5C27.5603 61.5 23.6593 60.724 20.0195 59.2164C16.3797 57.7087 13.0726 55.499 10.2868 52.7132C7.50104 49.9274 5.29125 46.6203 3.78361 42.9805C2.27597 39.3407 1.5 35.4397 1.5 31.5C1.5 23.5435 4.6607 15.9129 10.2868 10.2868C15.9129 4.66071 23.5435 1.5 31.5 1.5C39.4565 1.5 47.0871 4.66071 52.7132 10.2868C58.3393 15.9129 61.5 23.5435 61.5 31.5Z"
        stroke="#00FF82"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SaveTime;
