import React, { useEffect, useState } from "react";

import {
  CardAreasToImprove,
  CardDepartmentRiskScores,
  CardEmployeesByDepartment,
  CardInteractions,
  CardProfileDepartment,
  CardProfileUser,
  CardRecentTestingActivity,
  CardRecentTrainingActivity,
  CardRiskEmployees,
  CardRiskScore,
  CardRiskScoreOverTime,
  CardScoreChange,
  CardSimulationTracker,
  CardTrainingCompletion,
} from "./utils";

import { Select, Tooltip, UsersTable } from "..";

import { CardHeadsUp } from "../../features";

import {
  pagesPlatform,
  pagesPlatformEndUser,
  pagesPlatformManager,
} from "../../../constants";

import "./dashboard.css";

function Dashboard({
  // All pages
  pageTitle,
  riskScore,
  comparedRiskScore,
  scoreChange,
  riskScoreOverTime, // Excluding direct reports
  percentagesOfUsers, // Excluding direct reports
  areasToImprove, // Excluding direct reports
  complianceFrameworks,
  simulationAssets, // Excluding end user
  testHTMLSimulationSchedule, // Excluding end user
  onChangeSelectedDateForSimulationTracker, // Excluding end user
  onChangeSelectedDays,
  onClickViewTestAsset, // Excluding end user

  // Organization
  interactions,
  usersInteraction,
  highestRiskUsers,
  lowestRiskUsers,
  departments, // Also for department
  trainingCompletion, // Also for department
  trainingCompletionUsers, // Also for department
  onClickShareReport, // Also for department
  onClickInteraction,
  email,

  // Departments
  selectedDepartment,
  onChangeDepartment,
  totalUsers,
  employeesByDepartment,

  // Users
  profileData, // Also for end user
  blastRadius, // Also for end user
  recentTestingActivity,
  learningModulesActivity,
  onLoadTestHTML, // Also for end user

  // End user
  showHeadsUp,
  descriptionData,

  // Manager
  users,
}) {
  const [color, setColor] = useState(null);
  const [riskLevel, setRiskLevel] = useState("");
  const [riskInsightsCopyForUsers, setRiskInsightsCopyForUsers] =
    useState(null);

  // Days filter
  const [selectedDays, setSelectedDays] = useState(90); // Default value is 90.

  // Headers for user table (manager)
  const headers = [
    {
      id: "name",
      label: "Name",
      isSortable: true,
      isSearchable: true,
      align: "left",
      width: "252px",
    },
    {
      id: "department",
      label: "Department",
      isSortable: true,
      isSearchable: true,
      align: "left",
      width: "208px",
    },
    {
      id: "compliant",
      label: "Compliance",
      isSortable: true,
      isSearchable: false,
      align: "left",
      width: "156px",
    },
    {
      id: "riskScore",
      label: "Risk score",
      isSortable: true,
      isSearchable: false,
      align: "left",
      width: "156px",
    },
    {
      id: "riskLevel",
      label: "Risk level",
      isSortable: false,
      isSearchable: false,
      align: "left",
      width: "164px",
    },
  ];

  function getFormattedDate() {
    const date = new Date();

    const options = { month: "long", day: "numeric", year: "numeric" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );

    // Add ordinal suffix to the day
    const day = date.getDate();
    let daySuffix;
    if (day % 10 === 1 && day !== 11) {
      daySuffix = "st";
    } else if (day % 10 === 2 && day !== 12) {
      daySuffix = "nd";
    } else if (day % 10 === 3 && day !== 13) {
      daySuffix = "rd";
    } else {
      daySuffix = "th";
    }

    const dayWithSuffix = day + daySuffix;

    return formattedDate.replace(day, dayWithSuffix);
  }

  const handleChangeSelectedDays = async (event) => {
    setSelectedDays(event.target.value);
    await onChangeSelectedDays(event.target.value);
  };

  const handleChangeDepartment = async (event) => {
    await onChangeDepartment(event);
  };

  // const handleClickShareReport = async () => {
  //   await onClickShareReport();
  // };

  useEffect(() => {
    if (riskScore) {
      if (riskScore > 75) {
        // Severe
        setColor("#EF3C1F");
        setRiskLevel("Severe");
      } else if (riskScore > 50) {
        // High
        setColor("#F59A31");
        setRiskLevel("High");
      } else if (riskScore > 25) {
        // Moderate
        setColor("#FEE442");
        setRiskLevel("Moderate");
      } else {
        // Low
        setColor("#00FF82");
        setRiskLevel("Low");
      }
    }
  }, [riskScore]);

  useEffect(() => {
    if (pageTitle === pagesPlatform.INSIGHTS.subpages.EMPLOYEES) {
      // User
      let riskInsightsCopyForUsers = "";

      // Check if they have a blast radius as well as role.
      if (
        blastRadius &&
        blastRadius.level &&
        blastRadius.function &&
        profileData &&
        profileData.role
      ) {
        let isDefault = false; // Used for any other level and function.

        if (blastRadius.level === "C-Suite") {
          switch (blastRadius.function) {
            case "Engineering/IT":
              riskInsightsCopyForUsers =
                "This C-suite technology likely has admin access and directly oversees the engineering systems/strategy, making them a high-profile target for cyber attacks.";
              break;

            case "Executive Management":
              riskInsightsCopyForUsers =
                "This C-suite executive leader likely directs corporate governance and strategy, making them a primary target for high-stakes cyber and insider threats.";
              break;

            case "Finance":
              riskInsightsCopyForUsers =
                "This C-suite finance executive likely oversees financial operations, making them a key target for financial fraud.";
              break;

            case "HR":
              riskInsightsCopyForUsers =
                "This C-suite HR executive likely shapes workforce policies and manages sensitive data, making them highly vulnerable to targeted attacks.";
              break;

            case "Legal/Compliance":
              riskInsightsCopyForUsers =
                "This C-suite executive in legal/compliance likely has admin access to everything and makes decisions on compliance strategy, increasing their exposure to cybersecurity risk.";
              break;

            case "Operations":
              riskInsightsCopyForUsers =
                "This executive overseeing operations is likely a high-profile target for sophisticated disruptions and espionage.";
              break;

            case "Sales/Marketing":
              riskInsightsCopyForUsers =
                "This C-suite executive in Sales accesses crucial market and customer data, likely making them highly vulnerable to targeted cyber espionage.";
              break;

            case "Other":
              riskInsightsCopyForUsers =
                "This C-suite executive in various capacities likely oversees diverse operations, exposing them to significant cyber and operational threats.";
              break;

            default:
              isDefault = true;
          }
        } else if (blastRadius.level === "Contractor") {
          switch (blastRadius.function) {
            case "Engineering/IT":
              riskInsightsCopyForUsers =
                "This engineering contractor likely accesses sensitive data and systems, placing them at a heightened risk for targeted cyber threats.";
              break;

            case "Executive Management":
              riskInsightsCopyForUsers =
                "This executive management contractor likely engages with confidential strategic projects, increasing their exposure to security risks and corporate espionage.";
              break;

            case "Finance":
              riskInsightsCopyForUsers =
                "This finance contractor likely accesses detailed financial data and reports, increasing their risk of involvement in financial irregularities.";
              break;

            case "HR":
              riskInsightsCopyForUsers =
                "This HR contractor is likely involved with confidential HR processes and data, creating moderately high risk for privacy and security breaches.";
              break;

            case "Legal/Compliance":
              riskInsightsCopyForUsers =
                "This legal/compliance contractor is likely involved with sensitive compliance issues, placing them at moderately high risk to the organization.";
              break;

            case "Operations":
              riskInsightsCopyForUsers =
                "This operations contractor likely accesses critical operational processes, particularly vulnerable to targeted operational and security risks.";
              break;

            case "Sales/Marketing":
              riskInsightsCopyForUsers =
                "This sales contractor likely has access to significant sales insights, facing specific risks related to data security breaches.";
              break;

            case "Other":
              riskInsightsCopyForUsers =
                "This contractor in various roles likely accesses confidential information across departments, presenting moderate risk of targeted data breaches.";
              break;

            default:
              isDefault = true;
          }
        } else if (blastRadius.level === "Entry-Level") {
          switch (blastRadius.function) {
            case "Engineering/IT":
              riskInsightsCopyForUsers =
                "This entry-level engineer likely works with end-user systems and sensitive data, posing a moderate risk of data breaches.";
              break;

            case "Executive Management":
              riskInsightsCopyForUsers =
                "This entry-level executive management trainee is likely exposed to high-level decision-making processes, introducing a moderate risk of data breach.";
              break;

            case "Finance":
              riskInsightsCopyForUsers =
                "This entry-level finance staff likely has access to financial records and transactions, which places them at moderate exposure to cybersecurity risk.";
              break;

            case "HR":
              riskInsightsCopyForUsers =
                "This entry-level HR staff likely has access to personal employee data, increasing their exposure to cybersecurity risk.";
              break;

            case "Legal/Compliance":
              riskInsightsCopyForUsers =
                "This entry-level legal/compliance officer likely handles sensitive data, posing moderate risk of cybersecurity breaches.";
              break;

            case "Operations":
              riskInsightsCopyForUsers =
                "This entry-level operations staff likely interacts with various operational processes, exposing them to low risk of data integrity issues.";
              break;

            case "Sales/Marketing":
              riskInsightsCopyForUsers =
                "This entry-level sales associate likely handles customer data, posing minimal risk of security threats.";
              break;

            case "Other":
              riskInsightsCopyForUsers =
                "This entry-level staff in various roles likely accesses department-specific data, introducing minimal risks of information misuse.";
              break;

            default:
              isDefault = true;
          }
        } else if (blastRadius.level === "Mid-Level") {
          switch (blastRadius.function) {
            case "Engineering/IT":
              riskInsightsCopyForUsers =
                "This mid-level engineer likely contributes to critical projects, their heightened exposure to sensitive data and systems increases their risk of cyber threats.";
              break;

            case "Executive Management":
              riskInsightsCopyForUsers =
                "This mid-level executive in management likely handles strategic planning and sensitive data, increasing their vulnerability to security breaches.";
              break;

            case "Finance":
              riskInsightsCopyForUsers =
                "This mid-level finance professional is likely involved in financial planning and transactions, increasing their risk of being targeted in financial fraud attacks.";
              break;

            case "HR":
              riskInsightsCopyForUsers =
                "This mid-level HR professional likely manages sensitive employee information, significantly increasing their exposure to cybersecurity risk.";
              break;

            case "Legal/Compliance":
              riskInsightsCopyForUsers =
                "This mid-level legal/compliance professional likely oversees compliance processes, slightly increasing their exposure to cybersecurity risk.";
              break;

            case "Operations":
              riskInsightsCopyForUsers =
                "This mid-level operations manager likely handles key parts of operations, slightly increasing their exposure to operational disruptions and security breaches.";
              break;

            case "Sales/Marketing":
              riskInsightsCopyForUsers =
                "This mid-level sales professional likely shapes strategy and access customer data, slightly increasing their threat of customer information breaches.";
              break;

            case "Other":
              riskInsightsCopyForUsers =
                "This mid-level professional in various departments likely accesses broader sensitive information, presenting minimal risk of data breaches.";
              break;

            default:
              isDefault = true;
          }
        } else if (blastRadius.level === "Senior Management") {
          switch (blastRadius.function) {
            case "Engineering/IT":
              riskInsightsCopyForUsers =
                "This senior engineering leader likely has extensive access to user data and core systems, significantly elevating their risk of being targeted by sophisticated cyber attacks.";
              break;

            case "Executive Management":
              riskInsightsCopyForUsers =
                "This senior executive likely oversees strategic information and decision-making processes, significantly escalating their risk of targeted corporate espionage.";
              break;

            case "Finance":
              riskInsightsCopyForUsers =
                "This senior finance leader likely controls financial strategies and assets, significantly raising their risk of being targeted in financial fraud attacks.";
              break;

            case "HR":
              riskInsightsCopyForUsers =
                "This senior HR leader likely has extensive access to employee records and strategic HR decisions, increasing their exposure to cybersecurity risk.";
              break;

            case "Legal/Compliance":
              riskInsightsCopyForUsers =
                "This senior legal/compliance leader likely has comprehensive access to sensitive legal documents and compliance data, increasing their exposure to cybersecurity risk.";
              break;

            case "Operations":
              riskInsightsCopyForUsers =
                "This senior operations leader likely oversees comprehensive operational areas, moderately increasing their risk of strategic data losses and cyber threats.";
              break;

            case "Sales/Marketing":
              riskInsightsCopyForUsers =
                "This senior sales leader likely handles strategic information, increasing their risk profile of customer information breaches.";
              break;

            case "Other":
              riskInsightsCopyForUsers =
                "This senior leader in various roles likely oversees multiple departmental functions, presenting moderate risk of data and security challenges.";
              break;

            default:
              isDefault = true;
          }
        } else {
          isDefault = true;
        }

        if (isDefault) {
          riskInsightsCopyForUsers =
            "This mid-level professional in various departments likely accesses broader sensitive information, presenting minimal risk of data breaches.";
        }
      } else {
        riskInsightsCopyForUsers =
          "This mid-level professional in various departments likely accesses broader sensitive information, presenting minimal risk of data breaches.";
      }

      setRiskInsightsCopyForUsers(riskInsightsCopyForUsers);
    } else if (pageTitle === pagesPlatformEndUser.INSIGHTS) {
      // End user
      let riskInsightsCopyForUsers = "";

      if (blastRadius && blastRadius.level && blastRadius.function) {
        let isDefault = false; // Used for any other level and function.

        if (blastRadius.level === "C-Suite") {
          switch (blastRadius.function) {
            case "Engineering/IT":
              riskInsightsCopyForUsers =
                "You likely have access and directly oversee the engineering department, elevating your security exposure.";
              break;

            case "Executive Management":
              riskInsightsCopyForUsers =
                "You likely direct corporate governance and sensitive decisions, significantly increasing your security risk exposure.";
              break;

            case "Finance":
              riskInsightsCopyForUsers =
                "You likely oversee financial operations, making you a primary target for economic threats.";
              break;

            case "HR":
              riskInsightsCopyForUsers =
                "You likely shape workforce policies and manage sensitive HR operations, elevating your security exposure.";
              break;

            case "Legal/Compliance":
              riskInsightsCopyForUsers =
                "You likely control legal and/or compliance, placing you at very high cybersecurity risk.";
              break;

            case "Operations":
              riskInsightsCopyForUsers =
                "You are likely a high-profile target for sophisticated cyber threats, significantly increasing your security risk exposure.";
              break;

            case "Sales/Marketing":
              riskInsightsCopyForUsers =
                "You likely have access to crucial market and customer data, significantly increasing your security risk exposure.";
              break;

            case "Other":
              riskInsightsCopyForUsers =
                "You may oversee diverse operations, exposing you to a high level of security risk exposure.";
              break;

            default:
              isDefault = true;
          }
        } else if (blastRadius.level === "Contractor") {
          switch (blastRadius.function) {
            case "Engineering/IT":
              riskInsightsCopyForUsers =
                "You likely have access to sensitive data and systems, elevating your security exposure.";
              break;

            case "Executive Management":
              riskInsightsCopyForUsers =
                "You likely engage with confidential strategic information, increasing your security risk exposure.";
              break;

            case "Finance":
              riskInsightsCopyForUsers =
                "You likely have access to detailed financial data and reports, elevating your risk level.";
              break;

            case "HR":
              riskInsightsCopyForUsers =
                "You are likely involved with confidential HR processes, increasing your security risk exposure.";
              break;

            case "Legal/Compliance":
              riskInsightsCopyForUsers =
                "You likely handle sensitive legal tasks, presenting a substantial risk to the organization.";
              break;

            case "Operations":
              riskInsightsCopyForUsers =
                "You likely have access to critical operational processes, posing a considerable security risk exposure.";
              break;

            case "Sales/Marketing":
              riskInsightsCopyForUsers =
                "You likely have access to significant sales insights, facing a heightened security risk exposure.";
              break;

            case "Other":
              riskInsightsCopyForUsers =
                "You may have access to confidential information across departments, elevating your security risk exposure.";
              break;

            default:
              isDefault = true;
          }
        } else if (blastRadius.level === "Entry-Level") {
          switch (blastRadius.function) {
            case "Engineering/IT":
              riskInsightsCopyForUsers =
                "You are likely to work with end-user systems and sensitive data, increasing your security risk exposure.";
              break;

            case "Executive Management":
              riskInsightsCopyForUsers =
                "You are likely exposed to high-level decision-making information, placing you at moderate risk.";
              break;

            case "Finance":
              riskInsightsCopyForUsers =
                "You likely have access to financial records and transactions, posing a moderate risk.";
              break;

            case "HR":
              riskInsightsCopyForUsers =
                "You likely have access to personal employee data, increasing your security risk exposure.";
              break;

            case "Legal/Compliance":
              riskInsightsCopyForUsers =
                "You likely handle some sensitive data, posing a low risk of cybersecurity breaches";
              break;

            case "Operations":
              riskInsightsCopyForUsers =
                "You are likely to interact with various operational data, increasing your security risk exposure.";
              break;

            case "Sales/Marketing":
              riskInsightsCopyForUsers =
                "You are likely to handle customer data, which increases your security risk exposure.";
              break;

            case "Other":
              riskInsightsCopyForUsers =
                "You may have access to department-specific data, introducing a moderate risk.";
              break;

            default:
              isDefault = true;
          }
        } else if (blastRadius.level === "Mid-Level") {
          switch (blastRadius.function) {
            case "Engineering/IT":
              riskInsightsCopyForUsers =
                "You are likely to contribute to critical projects, increasing your risk of security breaches.";
              break;

            case "Executive Management":
              riskInsightsCopyForUsers =
                "You likely handle strategic planning and sensitive executive information, increasing your risk substantially.";
              break;

            case "Finance":
              riskInsightsCopyForUsers =
                "You are likely involved in financial planning and management, which heightens your security risk exposure.";
              break;

            case "HR":
              riskInsightsCopyForUsers =
                "You likely manage sensitive employee information and decisions, raising your security risk exposure.";
              break;

            case "Legal/Compliance":
              riskInsightsCopyForUsers =
                "You are likely involved in more sensitive legal matters, moderately increasing your security risk exposure.";
              break;

            case "Operations":
              riskInsightsCopyForUsers =
                "You are likely to handle key parts of operations, increasing your security risk exposure.";
              break;

            case "Sales/Marketing":
              riskInsightsCopyForUsers =
                "You likely have access to customer data, slightly increasing your security risk exposure.";
              break;

            case "Other":
              riskInsightsCopyForUsers =
                "You may have access to broader sensitive information, presenting an elevated risk.";
              break;

            default:
              isDefault = true;
          }
        } else if (blastRadius.level === "Senior Management") {
          switch (blastRadius.function) {
            case "Engineering/IT":
              riskInsightsCopyForUsers =
                "You likely have access to user data and core systems, significantly increasing your security risk exposure.";
              break;

            case "Executive Management":
              riskInsightsCopyForUsers =
                "You likely oversee strategic information and sensitive corporate data, greatly increasing your security risk exposure.";
              break;

            case "Finance":
              riskInsightsCopyForUsers =
                "You likely control financial strategies and access sensitive financial information, significantly increasing your security risk exposure.";
              break;

            case "HR":
              riskInsightsCopyForUsers =
                "You likely have access to employee records and strategic data, which significantly heightens your security risk exposure.";
              break;

            case "Legal/Compliance":
              riskInsightsCopyForUsers =
                "You likely oversee high-stakes legal decisions, significantly elevating your security risk exposure.";
              break;

            case "Operations":
              riskInsightsCopyForUsers =
                "You likely oversee operations, moderately increasing your risk of security breaches.";
              break;

            case "Sales/Marketing":
              riskInsightsCopyForUsers =
                "You are likely to handle strategic information, increasing your security risk exposure.";
              break;

            case "Other":
              riskInsightsCopyForUsers =
                "You may oversee multiple departmental functions, significantly increasing your security risk exposure.";
              break;

            default:
              isDefault = true;
          }
        } else {
          isDefault = true;
        }

        if (isDefault) {
          riskInsightsCopyForUsers =
            "You may have access to department-specific data, introducing a moderate risk.";
        }
      } else {
        riskInsightsCopyForUsers =
          "You may have access to department-specific data, introducing a moderate risk.";
      }

      setRiskInsightsCopyForUsers(riskInsightsCopyForUsers);
    }
  }, [blastRadius, profileData, pageTitle]);

  return (
    <div className="pt-1.25">
      {/* Select a department to view section */}
      {pageTitle === pagesPlatform.INSIGHTS.subpages.DEPARTMENTS && (
        <div className="my-1.25">
          <p className="description">Select a department to view</p>
          <div className="mt-0.25 max-w-[400px]">
            <Select
              value={selectedDepartment}
              onChange={handleChangeDepartment}
            >
              {departments.map((department, index) => (
                <option value={department.name} key={`department-${index}`}>
                  {department.name}
                </option>
              ))}
            </Select>
          </div>
        </div>
      )}

      {/* Department Profile Card */}
      {pageTitle === pagesPlatform.INSIGHTS.subpages.DEPARTMENTS && (
        <div className="mb-1.25">
          <CardProfileDepartment
            totalUsers={totalUsers}
            description={
              riskLevel === "Low"
                ? "The department is at low risk due to employees consistently demonstrating strong awareness and low vulnerability."
                : riskLevel === "Moderate"
                ? "The department is at moderate risk due to inconsistent security practices and moderate exposure to threats among employees."
                : riskLevel === "High"
                ? "The department is at high risk due to employees often lacking security awareness and significant exposure to potential breaches."
                : riskLevel === "Severe"
                ? "The department is under severe risk of social engineering as many employees display critical gaps in security knowledge and are highly vulnerable to attacks."
                : ""
            }
          />
        </div>
      )}

      {/* Heads Up Card */}
      {pageTitle === pagesPlatformEndUser.INSIGHTS && showHeadsUp && (
        <div className="mb-1.25">
          <CardHeadsUp />
        </div>
      )}

      {/* User Profile Card */}
      {(pageTitle === pagesPlatform.INSIGHTS.subpages.EMPLOYEES ||
        pageTitle === pagesPlatformEndUser.INSIGHTS) && (
        <div className="mb-1.25">
          <CardProfileUser
            profileData={profileData}
            description={riskLevel ? riskInsightsCopyForUsers : ""}
          />
        </div>
      )}

      {/* Current Date and Days Filter */}
      {(pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION ||
        pageTitle === pagesPlatform.INSIGHTS.subpages.DEPARTMENTS ||
        (pageTitle === pagesPlatform.INSIGHTS.subpages.EMPLOYEES &&
          riskScore) ||
        pageTitle === pagesPlatformEndUser.INSIGHTS) && (
        <div className="flex items-center justify-between">
          <div>
            <p className="body text-[1.875rem]">{getFormattedDate()}</p>
          </div>
          <div>
            <div className="flex items-center justify-end">
              <div className="relative ml-1.25">
                <Select
                  value={selectedDays}
                  onChange={handleChangeSelectedDays}
                >
                  <option
                    value={30}
                    style={{ textTransform: "none !important" }}
                  >
                    Last 30 days
                  </option>
                  <option
                    value={60}
                    style={{ textTransform: "none !important" }}
                  >
                    Last 60 days
                  </option>
                  <option
                    value={90}
                    style={{ textTransform: "none !important" }}
                  >
                    Last 90 days
                  </option>
                  <option
                    value="All Time"
                    style={{ textTransform: "none !important" }}
                  >
                    All Time
                  </option>
                </Select>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Share Report Section */}
      {/* {(pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION ||
        pageTitle === pagesPlatform.INSIGHTS.subpages.DEPARTMENTS) && (
        <div className="mt-0.75">
          <button
            className="text-white bg-transparent"
            aria-label="Share report"
            title="Download the report."
            onClick={handleClickShareReport}
          >
            Share report
            <ArrowOutward />
          </button>
        </div>
      )} */}

      {/* Risk Insights and Trends (Risk Score, Score Change, Risk Score Over Time, Areas to Improve Cards) */}
      {(pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION ||
        pageTitle === pagesPlatform.INSIGHTS.subpages.DEPARTMENTS ||
        pageTitle === pagesPlatform.INSIGHTS.subpages.EMPLOYEES ||
        pageTitle === pagesPlatformEndUser.INSIGHTS) && (
        <div className="flex flex-col md:flex-row mt-1.25 w-full">
          <div className="flex flex-col max-w-full md:max-w-[200px] mb-4 md:mb-0 md:mr-1.25 w-full">
            <div className="flex flex-row items-center">
              <h2 className="h4">Risk Insights</h2>
              <div className="flex items-center ml-[4px]">
                <Tooltip
                  text={
                    pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION
                      ? "The risk score helps you understand the overall vulnerability of your organization to social engineering threats by aggregating individual User Risk Scores (URS). Track how your organization's risk score has fluctuated over time to identify trends and areas of concern."
                      : pageTitle ===
                        pagesPlatform.INSIGHTS.subpages.DEPARTMENTS
                      ? "See the average risk score for the department to understand its overall security vulnerability. Keep track of how the department's risk score changes over time to spot trends and respond accordingly."
                      : pageTitle === pagesPlatform.INSIGHTS.subpages.EMPLOYEES
                      ? "Understand this user's vulnerability to social engineering attacks based on contextual and behavioral data. See how this user's risk score has changed over time to assess progress in improving security practices."
                      : pageTitle === pagesPlatformEndUser.INSIGHTS
                      ? "View your overall security risk score to see how vulnerable you are to social engineering and other threats. Track how your risk score has changed over time to see if your security practices are improving."
                      : ""
                  }
                />
              </div>
            </div>
            <div className="flex flex-col justify-between flex-grow mt-0.625 w-full">
              <div className="w-full">
                <CardRiskScore
                  riskScore={riskScore}
                  riskLevel={riskLevel}
                  color={color}
                  description={
                    pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION
                      ? riskLevel === "Low"
                        ? "The organization has a low risk score, reflecting strong security overall. Keep up the training."
                        : riskLevel === "Moderate"
                        ? "The organization is doing well, but focused training across teams can help lower risk further."
                        : riskLevel === "High"
                        ? "The organization's risk is high. Targeted training is necessary to reduce vulnerabilities quickly."
                        : "The organization is at severe risk. Immediate action is needed to address these vulnerabilities."
                      : pageTitle ===
                        pagesPlatform.INSIGHTS.subpages.DEPARTMENTS
                      ? riskLevel === "Low"
                        ? "Great job! This department is showing strong security practices. Keep up the training to maintain this level."
                        : riskLevel === "Moderate"
                        ? "The department is on track. A bit more focused training can help lower their risk further."
                        : riskLevel === "High"
                        ? "This department's risk is high. Prioritizing targeted training will help reduce their vulnerabilities."
                        : "This department faces severe risks. Immediate training is needed to improve their security posture."
                      : pageTitle === pagesPlatform.INSIGHTS.subpages.EMPLOYEES
                      ? riskLevel === "Low"
                        ? "This user has a low risk score. Encourage them to maintain their good habits by continuing regular training."
                        : riskLevel === "Moderate"
                        ? "This user's risk score is moderate. Additional training will help them strengthen their security posture."
                        : riskLevel === "High"
                        ? "This user's risk score is high. Prompt them to complete targeted training to reduce risks effectively."
                        : "Immediate action is needed and recommend focused training to mitigate their vulnerabilities."
                      : pageTitle === pagesPlatformEndUser.INSIGHTS
                      ? riskLevel === "Low"
                        ? "Your score is low, reflecting strong security habits. Stay engaged with training to maintain this level."
                        : riskLevel === "Moderate"
                        ? "You're on the right track. A bit more focus on training can help reduce your risk further."
                        : riskLevel === "High"
                        ? "Your score is high. Completing targeted training will help you quickly lower your risk."
                        : "Your score is severe, but with focused training, you can significantly improve."
                      : ""
                  }
                />
              </div>
              <div className="w-full mt-0.625">
                <CardScoreChange
                  scoreChange={scoreChange}
                  selectedDays={selectedDays}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col flex-grow w-full">
            <div className="flex flex-row items-center">
              <h2 className="h4">Trends</h2>
              <div className="flex items-center ml-[4px]">
                <Tooltip
                  text={
                    pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION
                      ? "Monitor real-time notifications of user activities, including clicks, submitted credentials, document downloads, and more to stay informed on security events. Use the risk radar to identify critical areas where your organization needs to improve its security posture."
                      : pageTitle ===
                        pagesPlatform.INSIGHTS.subpages.DEPARTMENTS
                      ? "Monitor the department's security performance over time to identify improvements or declines and take necessary actions. Focus on the key areas where the department needs to improve its security behaviors."
                      : pageTitle === pagesPlatform.INSIGHTS.subpages.EMPLOYEES
                      ? "Monitor this user's security performance over time to identify areas where you have improved or need further attention. Pinpoint the specific areas where this user needs to improve their security behaviors to reduce their risk score."
                      : pageTitle === pagesPlatformEndUser.INSIGHTS
                      ? "Monitor your progress in reducing your risk score over time and see how your efforts are paying off. Identify the key areas where you need to improve to reduce your security risk."
                      : ""
                  }
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row flex-grow mt-0.625">
              <div className="w-full mb-4 md:mb-0">
                <CardRiskScoreOverTime
                  riskScoreOverTime={riskScoreOverTime}
                  color={color}
                />
              </div>
              <div className="ml-0 md:ml-0.625 md:max-w-[250px] w-full">
                <CardAreasToImprove areasToImprove={areasToImprove} />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Employee Interactions Card */}
      {pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION && (
        <div className="mt-1.25">
          <div className="flex flex-row items-center">
            <h2 className="h4">Employee Interactions</h2>
            <div className="flex items-center ml-[4px]">
              <Tooltip text="View real-time notifications of user activities, including clicks, submitted credentials, document downloads, and more." />
            </div>
          </div>
          <div className="mt-0.625">
            <CardInteractions
              interactions={interactions}
              usersInteraction={usersInteraction}
              selectedDays={selectedDays}
              onClickInteraction={onClickInteraction}
              email={email}
            />
          </div>
        </div>
      )}

      {/* Simulation Tracker Card */}
      {pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION && (
        <div className="mt-1.25">
          <div className="flex flex-row items-center">
            <h2 className="h4">Simulation Tracker</h2>
            <div className="flex items-center ml-[4px]">
              <Tooltip text="View and manage your organization's upcoming security simulations. Access key details, including assets planned for the day, asset titles, assigned personnel, asset types, and the domains from which simulations will be sent." />
            </div>
          </div>
          <div className="mt-0.625">
            <CardSimulationTracker
              simulationAssets={simulationAssets}
              testHTML={testHTMLSimulationSchedule}
              onChangeSelectedDate={onChangeSelectedDateForSimulationTracker}
              onClickViewTestAsset={onClickViewTestAsset}
            />
          </div>
        </div>
      )}

      {/* Highest Risk Employees and Lowest Risk Employees Cards */}
      {pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION && (
        <div className="flex flex-col lg:flex-row items-center mt-1.25 w-full">
          <div className="w-full pr-0 lg:pr-[10px]">
            <div className="flex flex-row items-center">
              <h2 className="h4">Highest Risk Employees</h2>
              <div className="flex items-center ml-[4px]">
                <Tooltip text="Identify employees at highest risk within your organization." />
              </div>
            </div>
            <div className="mt-0.625">
              <CardRiskEmployees
                users={highestRiskUsers}
                enableDescendingOrder
              />
            </div>
          </div>
          <div className="w-full pl-0 lg:pl-[10px] mt-1.25 lg:mt-0">
            <div className="flex flex-row items-center">
              <h2 className="h4">Lowest Risk Employees</h2>
              <div className="flex items-center ml-[4px]">
                <Tooltip text="Recognize and celebrate the employees who pose the lowest security risks in your organization." />
              </div>
            </div>
            <div className="mt-0.625">
              <CardRiskEmployees users={lowestRiskUsers} />
            </div>
          </div>
        </div>
      )}

      {/* Department Risk Scores Card */}
      {pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION && (
        <div className="flex flex-col lg:flex-row items-center mt-1.25 w-full">
          <div className="w-1/2 pr-0 lg:pr-[10px]">
            <div className="flex flex-row items-center">
              <h2 className="h4">Department Risk Scores</h2>
              <div className="flex items-center ml-[4px]">
                <Tooltip text="Compare risk scores across departments to see which areas require the most attention and improvement." />
              </div>
            </div>
            <div className="mt-0.625">
              <CardDepartmentRiskScores departments={departments} />
            </div>
          </div>
        </div>
      )}

      {/* Employees by Department Card */}
      {pageTitle === pagesPlatform.INSIGHTS.subpages.DEPARTMENTS && (
        <div className="mt-1.25">
          <div className="flex flex-row items-center">
            <h2 className="h4">Employees</h2>
            <div className="flex items-center ml-[4px]">
              <Tooltip text="Review each employee's risk score, training status, and compliance levels to manage departmental security effectively." />
            </div>
          </div>
          <div className="mt-0.625">
            <CardEmployeesByDepartment users={employeesByDepartment} />
          </div>
        </div>
      )}

      {/* Training Completion Card */}
      {(pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION ||
        pageTitle === pagesPlatform.INSIGHTS.subpages.DEPARTMENTS) &&
        trainingCompletion && (
          <div className="mt-1.25">
            <div className="flex flex-row items-center">
              <h2 className="h4">Training Completion</h2>
              <div className="flex items-center ml-[4px]">
                <Tooltip text="Track how many employees have completed their assigned security training, and identify who needs to catch up." />
              </div>
            </div>
            <div className="mt-0.625">
              <CardTrainingCompletion
                trainingCompletion={trainingCompletion}
                users={trainingCompletionUsers}
                email={email}
              />
            </div>
          </div>
        )}

      {/* Testing Section */}
      {((pageTitle === pagesPlatform.INSIGHTS.subpages.EMPLOYEES &&
        riskScore) ||
        pageTitle === pagesPlatformEndUser.INSIGHTS) && (
        <div className="mt-1.25">
          <div className="flex flex-row items-center">
            <h2 className="h4">Testing</h2>
            <div className="flex items-center ml-[4px]">
              <Tooltip
                text={
                  pageTitle === pagesPlatform.INSIGHTS.subpages.EMPLOYEES
                    ? "Review this user's interactions with simulated attacks, including clicks on malicious links, credential submissions,  and document downloads, to understand their risk areas."
                    : pageTitle === pagesPlatformEndUser.INSIGHTS
                    ? "Review your interactions with simulated attacks, including clicks on malicious links and credential submissions, to identify risk behaviors."
                    : ""
                }
              />
            </div>
          </div>
          <div className="mt-0.625">
            <CardRecentTestingActivity
              recentTestingActivity={recentTestingActivity}
              showHeadsUp={showHeadsUp}
              firstName={
                pageTitle === pagesPlatform.INSIGHTS.subpages.EMPLOYEES &&
                profileData &&
                profileData.firstName
                  ? profileData.firstName
                  : ""
              }
              onLoadTestHTML={onLoadTestHTML}
            />
          </div>
        </div>
      )}

      {/* Training Section */}
      {pageTitle === pagesPlatform.INSIGHTS.subpages.EMPLOYEES &&
        Array.isArray(learningModulesActivity) &&
        learningModulesActivity.length > 0 && (
          <div className="mt-1.25">
            <div className="flex flex-row items-center">
              <h2 className="h4">Training</h2>
              <div className="flex items-center ml-[4px]">
                <Tooltip
                  text={
                    pageTitle === pagesPlatform.INSIGHTS.subpages.EMPLOYEES
                      ? "Track this user's participation and progress in security training modules to ensure they're improving their security knowledge."
                      : pageTitle === pagesPlatformEndUser.INSIGHTS
                      ? "Keep track of your progress in completing assigned training modules and identify which ones you still need to finish."
                      : ""
                  }
                />
              </div>
            </div>
            <div className="mt-0.625">
              <CardRecentTrainingActivity
                learningModulesActivity={learningModulesActivity}
              />
            </div>
          </div>
        )}

      {/* Users (Manager) */}
      {pageTitle === pagesPlatformManager.YOUR_REPORTS && (
        <>
          <div className="mt-1.25">
            <h2 className="text-2xl font-bold">Users</h2>
          </div>
          {users && (
            <div className="mt-0.625">
              <UsersTable
                data={users}
                headers={headers}
                defaultOrderBy="name"
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Dashboard;
