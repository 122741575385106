import React, { useState } from "react";

import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import {
  ArrowBackIosNewOutlined,
  ChatOutlined,
  ExitToAppOutlined,
  Mail,
} from "@mui/icons-material";

import { postFeedback } from "../../../api";

import "./navigation-platform-top.css";

function NavigationPlatformTop({
  email,
  firstName,
  initials,
  lastName,
  msspOrganizations,
  selectedMSSPOrganization,
  onClickMSSPOrganization,
  onLogOut,
}) {
  // Popover: User
  const [anchorElPopoverUser, setAnchorElPopoverUser] = useState(null);
  const [openPopoverUser, setOpenPopoverUser] = useState(false);

  // Popover: Feedback
  const [anchorElPopoverFeedback, setAnchorElPopoverFeedback] = useState(null);
  const [isErrorFeedback, setIsErrorFeedback] = useState(false);
  const [isLoadingFeedback, setIsLoadingFeedback] = useState(false);

  const handleClosePopoverUser = () => {
    setAnchorElPopoverUser(null);
    setOpenPopoverUser(false);
  };

  const handleClickAvatarFeedback = (event) => {
    setAnchorElPopoverFeedback(event.currentTarget);
  };

  const handleClickAvatarInitials = (event) => {
    setAnchorElPopoverUser(event.currentTarget);
    setOpenPopoverUser(true);
  };

  const handleClickMSSPOrganization = (msspOrganization) => {
    onClickMSSPOrganization(msspOrganization);
    handleClosePopoverUser();
  };

  const handleClickSubmitFeedback = async (event) => {
    event.preventDefault();

    setIsLoadingFeedback(true);
    setIsErrorFeedback(false);

    const data = new FormData(event.currentTarget);
    const message = data.get("feedback");

    if (!message) {
      setIsErrorFeedback(true);
    } else {
      const requestBody = {
        message,
      };

      // const feedback = await postFeedback(requestBody);
      await postFeedback(requestBody);

      // if (Object.keys(feedback.error).length > 0) {
      //     setMessageError(constants.ERROR_DEFAULT);
      // } else {
      //     setMessageSuccess("Your message has been sent.");
      // }
    }

    setIsLoadingFeedback(false);
  };

  const handleClosePopover = () => {
    setAnchorElPopoverFeedback(null);
  };

  return (
    <>
      <Box
        className="navigation-platform-top-box"
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        height="100%"
        paddingRight="10px"
      >
        {selectedMSSPOrganization && selectedMSSPOrganization.name && (
          <Typography
            component="p"
            className="navigation-platform-top-text-viewing-organization"
            marginRight="20px"
          >
            Viewing {selectedMSSPOrganization.name}
          </Typography>
        )}
        <a
          title="Email info@dune.security."
          aria-label="Email info@dune.security."
          href="mailto:info@dune.security"
        >
          <Avatar className="navigation-platform-top-avatar-gray">
            <Mail />
          </Avatar>
        </a>
        <Avatar
          className="navigation-platform-top-avatar-gray navigation-platform-top-avatar-feedback"
          title="Ask a question or give feedback."
          onClick={handleClickAvatarFeedback}
        >
          <ChatOutlined />
        </Avatar>
        <Button
          onClick={handleClickAvatarInitials}
          endIcon={
            <ArrowBackIosNewOutlined className="navigation-platform-top-icon-arrow-back" />
          }
        >
          <Avatar className="navigation-platform-top-avatar-initials">
            {initials}
          </Avatar>
        </Button>
        <Popover
          className="navigation-platform-top-popover-user"
          open={openPopoverUser}
          anchorEl={anchorElPopoverUser}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          onClose={handleClosePopoverUser}
        >
          <Box paddingBottom="10px">
            {firstName && lastName && (
              <Typography
                component="p"
                className="navigation-platform-top-text-bold"
                textAlign="center"
              >
                {firstName} {lastName}
              </Typography>
            )}
            {email && (
              <Typography component="p" textAlign="center" marginTop="4px">
                {email}
              </Typography>
            )}
          </Box>
          {msspOrganizations &&
            Array.isArray(msspOrganizations) &&
            msspOrganizations.length > 0 && (
              <Box margin="10px 0 10px">
                <Divider className="dune-divider-gray" />
                <Box marginTop="20px" padding="0 24px">
                  <Typography
                    component="p"
                    className="navigation-platform-top-text-bold"
                  >
                    Switch company view
                  </Typography>
                  <Box marginTop="10px">
                    {msspOrganizations.map((organization, index) => (
                      <Button
                        variant="text"
                        className="dune-button-text-white navigation-platform-top-button-company-view"
                        fullWidth
                        title={`Switch company view to ${organization}.`}
                        aria-label={`Switch company view to ${organization}.`}
                        onClick={() =>
                          handleClickMSSPOrganization(organization)
                        }
                        key={`mssp-organization-${index}`}
                      >
                        {organization.name}
                      </Button>
                    ))}
                  </Box>
                </Box>
              </Box>
            )}
          <Box marginTop="10px">
            <Divider className="dune-divider-gray" />
            <Box marginTop="10px">
              <Button
                variant="text"
                className="dune-button-text-white navigation-platform-top-button-log-out"
                fullWidth
                title="Log out the platform."
                aria-label="Log out the platform."
                startIcon={
                  <ExitToAppOutlined className="navigation-platform-top-icon-log-out" />
                }
                onClick={onLogOut}
              >
                Log out
              </Button>
            </Box>
          </Box>
        </Popover>
      </Box>
      <Popover
        className="navigation-platform-top-popover-feedback"
        open={Boolean(anchorElPopoverFeedback)}
        anchorEl={anchorElPopoverFeedback}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        {isLoadingFeedback ? (
          <Box width="100%" textAlign="center">
            <CircularProgress
              className="loading-spinner-circular-progress"
              size="5vh"
            />
          </Box>
        ) : (
          <Box component="form" noValidate onSubmit={handleClickSubmitFeedback}>
            <Typography component="p">Feedback/Question</Typography>
            <Box marginTop="20px">
              <TextField
                variant="filled"
                className="dune-text-field-filled dune-text-field-filled-multiline navigation-platform-top-text-field-feedback"
                name="feedback"
                placeholder="Enter your feedback or question."
                type="text"
                error={isErrorFeedback}
                multiline
                rows={6}
                required
                fullWidth
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Box>
            <Box marginTop="20px">
              <Button
                variant="outlined"
                className="dune-button-outlined-white"
                type="submit"
                fullWidth
              >
                Submit
              </Button>
            </Box>
          </Box>
        )}
      </Popover>
    </>
  );
}

export default NavigationPlatformTop;
