import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  CardMedia,
  Card,
  CardContent,
} from "@mui/material";
import { createClient } from "contentful";
import { DuneButton, FadeInSection } from "../../../components"; // Assuming ResourcesGroup isn't used anymore
import { Translate } from "react-auto-translate";
import { useNavigate } from "react-router-dom";

import { postEmailPaywall } from "../../../api/database/post-email-paywall";

import "./resource-whitepapers.css";

const ResourceWhitepapers = () => {
  const [whitepapers, setWhitepapers] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedWhitepaper, setSelectedWhitepaper] = useState(null); // To track the clicked whitepaper
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    title: "",
  });

  const navigate = useNavigate();

  const formatWhitepaperDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  // Function to read cookie
  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
    return null;
  };

  const handleModal = (whitepaper) => {
    const emailPaywallCookie = getCookie("emailPayWall");
    if (emailPaywallCookie !== "true") {
      setIsOpen(true); // Open modal if emailPayWall is not true
    } else {
      navigate(`/resources/whitepaper/${whitepaper.referenceId}`);
    }
    setSelectedWhitepaper(whitepaper); // Store the whitepaper referenceId
  };

  const setCookie = (name, value, days) => {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
  };

  const handleFormSubmission = async (e) => {
    e.preventDefault();

    if (
      form.firstName &&
      form.lastName &&
      form.email &&
      form.company &&
      form.title
    ) {
      const requestBody = {
        first_name: form.firstName,
        last_name: form.lastName,
        email: form.email,
        company: form.company,
        title: form.title,
      };

      const payWallMessage = await postEmailPaywall(requestBody);

      console.log(payWallMessage);

      // console.log("Form submitted:", form);
      // console.log("Whitepaper Reference ID:", selectedWhitepaper.referenceId);

      setCookie("emailPayWall", "true", 999);

      if (selectedWhitepaper?.referenceId) {
        navigate(`/resources/whitepaper/${selectedWhitepaper.referenceId}`);
      }
    } else {
    }
  };

  useEffect(() => {
    const fetchWhitepapers = async () => {
      if (process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN) {
        const client = createClient({
          space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
          accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
        });

        client
          .getEntries({
            content_type: "whitepaper",
          })
          .then((response) => {
            const fetchedWhitepapers = response.items.map((item) => {
              const fields = item.fields;
              const referenceId = fields.referenceId || "";
              const title = fields.title || "Untitled";
              const date = fields.date || "";
              const introduction =
                fields.introduction || "No introduction available";
              const tableOfContents =
                fields.tableOfContents || "No table of contents available";
              const content = fields.content || "No content available";

              const thumbnailURL = fields.coverImage?.fields?.file?.url
                ? fields.coverImage.fields.file.url.startsWith("//")
                  ? `https:${fields.coverImage.fields.file.url}`
                  : fields.coverImage.fields.file.url
                : "";

              const coverImage = fields.coverImage?.fields?.file?.url
                ? fields.coverImage.fields.file.url.startsWith("//")
                  ? `https:${fields.coverImage.fields.file.url}`
                  : fields.coverImage.fields.file.url
                : "";

              return {
                referenceId,
                title,
                date,
                introduction,
                tableOfContents,
                content,
                href: `/resources/whitepaper/${referenceId}`,
                thumbnailURL,
                coverImage,
              };
            });

            setWhitepapers(fetchedWhitepapers);
          })
          .catch(console.error);
      }
    };

    fetchWhitepapers();
  }, []);

  return (
    <>
      <FadeInSection>
        <Box
          display="flex"
          justifyContent="center"
          marginTop={{
            xs: "40px",
            md: "80px",
          }}
          padding="0 28px"
          sx={{
            color: "white",
          }}
        >
          <Box textAlign="center" maxWidth="800px" width="100%">
            <Typography component="h1" className="dune-text-header-uppercase">
              <Translate>Resource Hub</Translate>
            </Typography>
            <Typography
              component="h2"
              className="dune-text-header-main"
              marginTop="40px"
            >
              <Translate>Whitepapers</Translate>
            </Typography>
          </Box>
        </Box>
      </FadeInSection>

      <FadeInSection>
        <div className="cursor-pointer">
          <Box
            display="flex"
            justifyContent="center"
            marginTop={{
              xs: "80px",
              md: "120px",
            }}
            marginBottom={{
              xs: "80px",
              md: "120px",
            }}
            padding="0 28px"
          >
            <Box maxWidth="1152px" width="100%">
              <Grid container>
                {whitepapers.map((resource, index) => (
                  <Grid
                    key={resource.referenceId}
                    item
                    xs={12}
                    sm={6}
                    lg={4}
                    marginTop={{
                      xs: index !== 0 ? "80px" : 0,
                      sm: index !== 0 && index !== 1 ? "80px" : 0,
                      lg: index > 2 ? "80px" : 0,
                    }}
                    paddingLeft={{
                      xs: 0,
                      sm: index % 2 === 1 ? "20px" : 0,
                      lg:
                        index % 3 === 2
                          ? `${(60 / 3) * 2}px`
                          : index % 3 === 1
                            ? `${60 / 3}px`
                            : 0,
                    }}
                    paddingRight={{
                      xs: 0,
                      sm: index % 2 === 0 ? "20px" : 0,
                      lg:
                        index % 3 === 0
                          ? `${(60 / 3) * 2}px`
                          : index % 3 === 1
                            ? `${60 / 3}px`
                            : 0,
                    }}
                  >
                    <Card
                      className={"resources-group-card"}
                      onClick={() => handleModal(resource)}
                    >
                      <Box
                        className="resources-group-box-image"
                        overflow="hidden"
                      >
                        <CardMedia
                          component="img"
                          className="resources-group-card-media"
                          alt={resource.title}
                          image={resource.thumbnailURL}
                        />
                      </Box>
                      <CardContent className="resources-group-card-content">
                        <Typography
                          component="p"
                          className="resources-group-text-title"
                        >
                          {<Translate>{resource.title}</Translate>}
                        </Typography>
                        <Typography
                          component="p"
                          marginTop="20px"
                          className="resources-group-text-date"
                        >
                          {
                            <Translate>
                              {formatWhitepaperDate(resource.date)}
                            </Translate>
                          }
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </div>
      </FadeInSection>

      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center text-white bg-black bg-opacity-70">
          <div className="bg-black py-2 px-2 md:px-6.5 rounded-lg max-w-40.625 w-full">
            <div className="flex items-center justify-between mb-1">
              <div className="text-h3">Enter Contact Info</div>
              <button
                onClick={() => setIsOpen(false)}
                className="font-bold text-white text-h3"
              >
                &times;
              </button>
            </div>
            <form onSubmit={handleFormSubmission}>
              <div className="flex flex-col items-center justify-center md:flex-row">
                <div className="p-1">
                  <label className="block mb-0.25">First Name</label>
                  <input
                    type="text"
                    className="bg-transparent text-white border rounded-small px-1 py-0.25 border-gray border-opacity-35"
                    value={form.firstName}
                    onChange={(e) =>
                      setForm({ ...form, firstName: e.target.value })
                    }
                    required
                  />
                </div>
                <div className="p-1">
                  <label className="block mb-0.25">Last Name</label>
                  <input
                    type="text"
                    className="bg-transparent text-white border rounded-small px-1 py-0.25 border-gray border-opacity-35"
                    value={form.lastName}
                    onChange={(e) =>
                      setForm({ ...form, lastName: e.target.value })
                    }
                    required
                  />
                </div>
              </div>
              <div className="flex flex-col items-center justify-center md:flex-row">
                <div className="p-1 mb-0.25">
                  <label className="block">Email</label>
                  <input
                    type="email"
                    className="bg-transparent text-white border rounded-small px-1 py-0.25 border-gray border-opacity-35"
                    value={form.email}
                    onChange={(e) =>
                      setForm({ ...form, email: e.target.value })
                    }
                    required
                  />
                </div>
                <div className="p-1">
                  <label className="block mb-0.25">Company</label>
                  <input
                    type="text"
                    className="bg-transparent text-white border rounded-small px-1 py-0.25 border-gray border-opacity-35"
                    value={form.company}
                    onChange={(e) =>
                      setForm({ ...form, company: e.target.value })
                    }
                    required
                  />
                </div>
              </div>
              <div className="flex flex-col items-center justify-center md:flex-row">
                <div className="p-1">
                  <label className="block">Title</label>
                  <input
                    type="text"
                    className="bg-transparent text-white border rounded-small px-1 py-0.25 border-gray border-opacity-35"
                    value={form.title}
                    onChange={(e) =>
                      setForm({ ...form, title: e.target.value })
                    }
                    required
                  />
                </div>
              </div>
              <div className="flex flex-wrap items-center justify-center mt-2 space-x-1">
                <DuneButton
                  variant="dune-outlined"
                  text="Cancel"
                  className={`w-fit`}
                  onMouseDown={() => setIsOpen(false)}
                />
                <DuneButton variant="dune-glow" text="Submit" type="submit" />
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default ResourceWhitepapers;
