import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { NavigationList } from "./utils";

import { LogoItem } from "../../common";

import {
  pagesPlatform,
  pagesPlatformEndUser,
  pagesPlatformOnboarding,
} from "../../../constants";

function NavigationPlatformSide({
  email,
  isInitialLoading,
  isBRACompleted,
  isAdmin,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location.pathname;

  const [open, setOpen] = useState({
    Insights: location.pathname.startsWith("/insights/"),
  });

  const getUpdatedOpen = useCallback(() => {
    const insights = location.pathname.startsWith("/insights/");

    return {
      Insights: insights,
    };
  }, [location.pathname]);

  const handleClickExpand = (key) => {
    const updatedOpen = { ...open };
    updatedOpen[key] = !updatedOpen[key];
    setOpen(updatedOpen);
  };

  const handleClickPageWithSubpages = (path) => {
    navigate(path);
  };

  useEffect(() => {
    setOpen(getUpdatedOpen());
  }, [getUpdatedOpen]);

  return (
    <div className="sticky top-0 flex flex-col w-full h-screen py-1.25 px-0.625">
      <div className="flex justify-center">
        <LogoItem height="39px" />
      </div>
      <div className="flex flex-col justify-between h-full">
        <div className="flex-grow py-1.875">
          <nav>
            {!isInitialLoading && (
              <>
                {isAdmin && !isBRACompleted && (
                  <NavigationList
                    header="Onboarding"
                    pages={pagesPlatformOnboarding}
                    open={open}
                    pathname={pathname}
                    onClickExpand={handleClickExpand}
                    onClickPageWithSubpages={handleClickPageWithSubpages}
                  />
                )}
                {isAdmin && isBRACompleted && (
                  <NavigationList
                    header="Company Metrics"
                    pages={pagesPlatform}
                    open={open}
                    pathname={pathname}
                    onClickExpand={handleClickExpand}
                    onClickPageWithSubpages={handleClickPageWithSubpages}
                  />
                )}
                {email !== "david@dune.demo" && isBRACompleted && (
                  <NavigationList
                    header="My Metrics"
                    pages={pagesPlatformEndUser}
                    open={open}
                    pathname={pathname}
                    onClickExpand={handleClickExpand}
                    onClickPageWithSubpages={handleClickPageWithSubpages}
                    padding={isAdmin ? "pt-4.375" : ""}
                  />
                )}
              </>
            )}
          </nav>
        </div>
        <div>
          <p className="copyright">Copyright © 2024 Dune Security</p>
        </div>
      </div>
    </div>
  );
}

export default NavigationPlatformSide;
