import React from "react";

import { UsersTable } from "../../..";

const headers = [
  {
    id: "name",
    label: "Name",
    isSortable: true,
    isSearchable: true,
    align: "left",
  },
  {
    id: "email",
    label: "Email Address",
    isSortable: true,
    isSearchable: true,
    align: "left",
  },
  {
    id: "riskScore",
    label: "Risk Score",
    isSortable: true,
    isSearchable: false,
    align: "center",
  },
  {
    id: "riskLevel",
    label: "Risk Level",
    isSortable: true,
    isSearchable: false,
    align: "center",
  },
];

function CardEmployeesByDepartment({ users }) {
  return (
    <div>
      {users && (
        <UsersTable
          data={users}
          headers={headers}
          defaultOrderBy="name"
          disableSearchBar
        />
      )}
    </div>
  );
}

export default CardEmployeesByDepartment;
