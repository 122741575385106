import React from "react";

const TitleWithBadge = ({ title, avatarContent, badgeText, badgeIcon }) => {
  return (
    <>
      {avatarContent && (
        <div className="flex items-center justify-center w-[40px] h-[40px] bg-[#4f4e4a] h4 rounded-full">
          {avatarContent}
        </div>
      )}
      <h1 className={`h2 ${avatarContent ? "ml-0.25" : ""}`}>{title}</h1>
      <div className="flex flex-row items-center bg-gray-badge-dark py-[2px] px-[8px] rounded-[4px] ml-[10px]">
        <div className="w-[12px] h-[12px]">{badgeIcon}</div>
        <div className="ml-[4px]">
          <p className="detailed-emphasized">{badgeText}</p>
        </div>
      </div>
    </>
  );
};

export default TitleWithBadge;
