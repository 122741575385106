import React, { useState, useEffect } from "react";
import { DuneButton } from "../../../components";
import { createClient } from "contentful";
import { Link } from "react-router-dom";

const BootcampCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [bootCampData, setBootCampData] = useState([]);

  const cardWidth = 384; // width of each card in pixels
  const visibleCards = 3; // number of visible cards

  const scrollLeft = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const scrollRight = () => {
    if (currentIndex < bootCampData.length - visibleCards) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const formatDate = (date) => {
    // Replace dashes to slashes to avoid having the date being a day off.
    const givenDate = new Date(date.replace(/-/g, "/"));
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return givenDate.toLocaleDateString("en-US", options);
  };

  useEffect(() => {
    const fetchedBootcamp = async () => {
      if (process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN) {
        const client = createClient({
          space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
          accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
        });

        await client
          .getEntries({
            content_type: "resourcesBootcamp",
            order: "-fields.date,fields.title",
          })
          .then((response) => {
            const data = response.items.map((item) => {
              const { fields, sys } = item;
              return {
                id: sys.id,
                title: fields.title,
                href: `/resources/bootcamp/${fields.resourceID}`,
                thumbnailURL: fields.thumbnail.fields.file.url,
                date: fields.date,
              };
            });
            setBootCampData(data);
          })
          .catch(console.error);
      }
    };

    fetchedBootcamp();
  }, []);

  return (
    <div className="my-8 max-w-78.75 mx-auto text-white">
      <div className="mx-1.25">
        <p className="text-h1.5">Resources</p>
        <div className="flex flex-wrap justify-between items-center">
          <p className="text-h4 mt-0.625 mb-1 md:mb-0">
            Access expert insights, whitepapers, and tools to boost your
            cybersecurity.
          </p>
          <div className="hidden md:flex justify-end md:justify-center items-center space-x-1">
            <DuneButton
              variant="dune-outlined"
              text="←"
              onMouseDown={scrollLeft}
              disabled={currentIndex === 0}
            />
            <DuneButton
              variant="dune-outlined"
              text="→"
              onMouseDown={scrollRight}
              disabled={currentIndex >= bootCampData.length - visibleCards}
            />
            <Link to="/resources/">
              <DuneButton variant="dune-outlined" text="View All" />
            </Link>
          </div>
        </div>
      </div>

      <div className="my-2.5 ml-1.25">
        <div className="overflow-x-auto no-scrollbar">
          <div
            className="flex space-x-2 transition-transform duration-300"
            style={{
              transform: `translateX(-${currentIndex * cardWidth}px)`,
              width: `${bootCampData.length * cardWidth}px`,
            }}
          >
            {bootCampData.map((item, index) => (
              <div
                key={index}
                className="border border-gray border-opacity-35 rounded-medium cursor-pointer"
                style={{ width: `${cardWidth}px` }}
              >
                <img
                  src={item.thumbnailURL}
                  alt={item.title}
                  className="h-13.438 rounded-medium w-full"
                />
                <p className="text-gray mt-0.75 mb-0.625 mx-1.25">
                  {formatDate(item.date)} • 5 min read
                </p>
                <p className="text-h4 mb-1.25 mx-1.25">{item.title}</p>
                <Link to={item.href}>
                  <DuneButton
                    variant="dune-outlined"
                    text="Read more"
                    className="w-[90%] ml-1.25"
                  />
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BootcampCarousel;
