import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

import {
  AppBar,
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { MenuOutlined } from "@mui/icons-material";

import LanguageSelector from "../../../components/utils/LanguageSelector";

import { DuneButton, LogoItem } from "../../common";

import { pagesWebsiteLeft, pagesWebsiteRight } from "../../../constants";

import { spacing } from "../../../styles";

import "./navigation-website.css";
import { Translate } from "react-auto-translate";

function NavigationWebsite({ onLanguageChange }) {
  // Desktop
  const [selectedPage, setSelectedPage] = useState({});
  const [popoverPosition, setPopoverPosition] = useState({
    top: 0,
    left: 0,
  });

  // Mobile
  const [anchorElNav, setAnchorElNav] = useState(null);

  let popoverTimeout = null;

  const handleClickIconMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElNav(null);
  };

  const handleClosePopover = () => {
    setSelectedPage({});
  };

  const handleMouseEnterPage = (event, page) => {
    const anchorPosition = event.currentTarget.getBoundingClientRect();

    setPopoverPosition({
      top: anchorPosition.bottom,
      left: anchorPosition.left,
    });
    setSelectedPage(page);
  };

  const handleMouseEnterPopover = () => {
    clearTimeout(popoverTimeout);
  };

  const handleMouseLeavePage = () => {
    popoverTimeout = setTimeout(() => {
      setSelectedPage({});
    }, 300);
  };

  const renderNavigationLinks = (pages, isMobile = false) => {
    if (isMobile) {
      return pages.map((page, index) =>
        page.isCTA ? (
          <Box
            marginLeft={{
              xs: 0,
              md: "40px",
            }}
            padding="0 28px"
          >
            <Button
              variant="contained"
              component={Link}
              className="dune-button-contained-green navigation-website-button-cta"
              fullWidth
              to={page.path}
              title={<Translate>{page.tooltip}</Translate>}
            >
              {<Translate>{page.name}</Translate>}
            </Button>
          </Box>
        ) : (
          <NavLink
            className={
              isMobile
                ? "navigation-website-link-mobile"
                : "navigation-website-link"
            }
            to={page.path}
            title={<Translate>{page.tooltip}</Translate>}
            key={isMobile ? `mobile-${index}` : `desktop-${index}`}
          >
            {isMobile ? (
              <MenuItem
                className="navigation-website-menu-item"
                onClick={handleCloseMenu}
              >
                <Typography component="p">
                  <Translate>{page.name}</Translate>
                </Typography>
              </MenuItem>
            ) : (
              <Translate>{page.name}</Translate>
            )}
          </NavLink>
        ),
      );
    }

    return pages.map((page, index) => (
      <Box
        paddingLeft="25px"
        paddingRight="25px"
        aria-owns={
          selectedPage === page.name ? "mouse-over-popover" : undefined
        }
        aria-haspopup="true"
        onMouseEnter={(event) => handleMouseEnterPage(event, page)}
        onMouseLeave={handleMouseLeavePage}
        sx={{ cursor: "pointer" }}
        key={`page-${index}`}
      >
        <NavLink
          className="navigation-website-link"
          to={page.path}
          title={<Translate>{page.tooltip}</Translate>}
        >
          <Translate>{page.name}</Translate>
        </NavLink>
      </Box>
    ));
  };

  return (
    <>
      <AppBar
        className="navigation-website-app-bar"
        position="sticky"
        role="banner"
      >
        <Box
          display="flex"
          justifyContent="center"
          height="100%"
          padding="0 30px"
        >
          <Box
            className="navigation-website-box-main"
            maxWidth={spacing.MAX_WIDTH_WEBSITE}
            width="100%"
            padding="0 30px"
          >
            <Toolbar className="navigation-website-toolbar" disableGutters>
              <Grid
                container
                alignItems="center"
                role="navigation"
                display={{
                  xs: "none",
                  md: "flex",
                }}
                height="100%"
              >
                <Grid item md={7}>
                  <Stack direction="row" alignItems="center">
                    <Box marginRight="20px">
                      <LogoItem height="28px" />
                    </Box>
                    {renderNavigationLinks(pagesWebsiteLeft)}
                  </Stack>
                </Grid>
                <Grid item container md={5} justifyContent="flex-end">
                  <Box
                    sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Box>
                      <Link to="/login/">
                        <DuneButton variant="dune-outlined" text="Log in" />
                      </Link>
                    </Box>
                    <Box
                      marginLeft={{
                        xs: 0,
                        md: "10px",
                      }}
                    >
                      <Link to="/demo/">
                        <DuneButton variant="dune-glow" text="Book a demo" />
                      </Link>
                    </Box>
                    <div className="text-black pl-0 md:pl-0.5">
                      <LanguageSelector onChange={onLanguageChange} />
                    </div>
                  </Box>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                display={{ xs: "flex", md: "none" }}
              >
                <Grid item container xs={9}>
                  <LogoItem height="24px" />
                </Grid>
                <Grid item container xs={3} justifyContent="flex-end">
                  <IconButton
                    className="navigation-website-icon-button-menu"
                    aria-label="Open navigation menu"
                    aria-haspopup={true}
                    onClick={handleClickIconMenu}
                  >
                    <MenuOutlined className="navigation-website-icon-menu" />
                  </IconButton>
                  <Menu
                    className="navigation-website-menu"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseMenu}
                    role="navigation"
                    sx={{
                      display: {
                        xs: "block",
                        md: "none",
                      },
                    }}
                  >
                    {renderNavigationLinks(pagesWebsiteLeft, true)}
                    {renderNavigationLinks(pagesWebsiteRight, true)}
                  </Menu>
                </Grid>
              </Grid>
            </Toolbar>
          </Box>
        </Box>
      </AppBar>
      {selectedPage &&
        selectedPage.name &&
        selectedPage.name !== "Product" &&
        selectedPage.subpages && (
          <Box
            className="navigation-website-box-desktop-popover"
            display={selectedPage.name ? "flex" : "none"}
            justifyContent="center"
            onMouseEnter={handleMouseEnterPopover}
            onMouseLeave={handleClosePopover}
            sx={{
              top: `calc(${popoverPosition.top}px + 20px)`,
              left: `calc(${popoverPosition.left}px)`,
            }}
          >
            <Box
              display="flex"
              maxWidth={spacing.MAX_WIDTH_WEBSITE}
              width="100%"
            >
              <List disablePadding>
                {selectedPage.subpages &&
                  selectedPage.subpages.map((subpage, index) => (
                    <NavLink
                      className="navigation-website-link-subpage"
                      to={subpage.path}
                      title={subpage.tooltip}
                    >
                      <ListItem
                        disablePadding
                        sx={{
                          paddingTop: index > 0 ? "10px" : "20px",
                          paddingBottom:
                            index < selectedPage.subpages.length - 1
                              ? "10px"
                              : "20px",
                          paddingLeft: "20px",
                          paddingRight: "20px",
                        }}
                      >
                        {subpage.name}
                      </ListItem>
                    </NavLink>
                  ))}
              </List>
            </Box>
          </Box>
        )}
    </>
  );
}

export default NavigationWebsite;
