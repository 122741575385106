import React, { useEffect, useState } from "react";
import { createClient } from "contentful";
import ArrowBackIosNewOutlined from "@mui/icons-material/ArrowBackIosNewOutlined";
import CalendarTodayOutlined from "@mui/icons-material/CalendarTodayOutlined";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { FadeInSection } from "../../../components";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { BLOCKS } from "@contentful/rich-text-types";

const WhitepaperDetails = () => {
  const [whitepaper, setWhitepaper] = useState(null);
  const [allWhitepapers, setAllWhitepapers] = useState([]);

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { file, title } = node.data.target.fields;
        const imageUrl = file.url.startsWith("//")
          ? `https:${file.url}`
          : file.url;

        return (
          <Box display="flex" justifyContent="center" marginTop="28px">
            <Box
              component="img"
              src={imageUrl}
              alt={title ? title : "Embedded asset"}
              style={{
                maxWidth: "500px",
                height: "auto",
              }}
            />
          </Box>
        );
      },
    },
  };

  useEffect(() => {
    const url = window.location.href;
    const lastPart = url.substring(url.lastIndexOf("/whitepaper/") + 12);

    const client = createClient({
      space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
      accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
    });

    if (lastPart) {
      client
        .getEntries({
          content_type: "whitepaper",
          "fields.referenceId": lastPart,
        })
        .then((response) => {
          if (response.items.length > 0) {
            setWhitepaper(response.items[0].fields);
          }
        })
        .catch((error) => {
          console.error("Error fetching whitepaper:", error);
        });
    }
  }, []);

  useEffect(() => {
    const client = createClient({
      space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
      accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
    });

    const fetchWhitepaperResources = async () => {
      client
        .getEntries({
          content_type: "whitepaper",
          limit: 3,
          order: "-fields.date,fields.title",
        })
        .then((response) => {
          const fetchedWhitepapers = response.items.map((item) => {
            const { fields, sys } = item;

            return {
              id: sys.id,
              title: fields.title,
              introduction: fields.introduction || "No introduction available",
              href: `/resources/whitepaper/${fields.referenceId}`,
              thumbnailURL: fields.thumbnail?.fields?.file?.url || "",
              coverImage: fields.coverImage?.fields?.file?.url || "",
              date: fields.date,
            };
          });

          setAllWhitepapers([...fetchedWhitepapers]);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    fetchWhitepaperResources();
  }, []);

  if (!whitepaper) {
    return <div>Loading...</div>;
  }

  const formatDate = (isoDateString) => {
    const date = new Date(isoDateString);

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    return `${month} ${day}, ${year}`;
  };

  return (
    <div className="bg-white">
      <FadeInSection>
        <Box
          display="flex"
          justifyContent="center"
          marginTop={{ xs: "60px", md: "-80px" }}
          padding="0 28px"
        >
          <div className="w-full md:max-w-78.75 mx-auto md:pt-10 md:pl-3">
            <Link to="/resources/whitepaper">
              <div className="duration-200 ease-in transform hover:text-gray">
                <span className="pr-2.5">
                  <ArrowBackIosNewOutlined className="resource-icon-resource-hub" />
                </span>
                Resource Hub
              </div>
            </Link>
            <div className="text-h2 my-2.5">{whitepaper.title}</div>

            <div>
              {whitepaper.coverImage && (
                <figure className="flex justify-center my-2.5">
                  <img
                    src={whitepaper.coverImage.fields.file.url}
                    alt={whitepaper.title}
                  />
                </figure>
              )}
            </div>

            <div className="flex items-center text-h5">
              <span className="pr-1">
                <CalendarTodayOutlined className="resource-icon-info" />
              </span>
              {formatDate(whitepaper.date)}
            </div>

            {/* Introduction Section */}
            <div className="my-2.5">
              <div className="text-h2 mb-1.5">Executive Summary</div>
              <Box
                className="resource-box-body-content"
                marginTop={{ xs: "10px", md: "20px" }}
              >
                {whitepaper.introduction.content.map((contentItem) =>
                  documentToReactComponents(contentItem, options),
                )}
              </Box>
            </div>

            {/* Table of Contents Section */}
            <div className="my-2.5">
              <div className="text-h2 mb-1.5">Table of Contents</div>
              <div className="text-h4 markdown-content">
                <ReactMarkdown>{whitepaper.tableOfContents}</ReactMarkdown>
              </div>
            </div>

            {/* Content Section */}
            <div className="my-2.5">
              <Box
                className="resource-box-body-content"
                marginTop={{ xs: "10px", md: "20px" }}
              >
                {whitepaper.content.content.map((contentItem) =>
                  documentToReactComponents(contentItem, options),
                )}
              </Box>
            </div>
          </div>
        </Box>
      </FadeInSection>

      <div className="max-w-78.75 w-full mx-auto my-2.5">
        <FadeInSection>
          <div className="mb-5 text-h2">Whitepapers</div>
          <div className="max-w-78.75 mx-auto grid grid-cols-1 md:grid-cols-3 gap-4">
            {allWhitepapers.map((resource) => (
              <a
                key={resource.id}
                href={resource.href}
                target="_blank"
                rel="noopener noreferrer"
                className="cursor-pointer group"
              >
                <div className="w-full overflow-hidden rounded-lg image-container">
                  <img
                    src={resource.coverImage}
                    alt={resource.title}
                    className="object-cover w-full h-full transition-transform duration-300 ease-in-out group-hover:scale-110"
                  />
                </div>
                <div className="mt-2 ml-0 md:ml-1">
                  <h3 className="text-leading-tight">{resource.title}</h3>
                  <p className="mt-2 text-gray-400">
                    {formatDate(resource.date)}
                  </p>
                </div>
              </a>
            ))}
          </div>
          <div className="flex justify-end max-w-78.75 mt-4 pb-4">
            <Link
              to="/resources/whitepaper/"
              className="inline-flex items-center text-lg duration-100 ease-in-out transform hover:text-gray"
            >
              View all <span className="ml-2">&rarr;</span>
            </Link>
          </div>
        </FadeInSection>
      </div>
    </div>
  );
};

export default WhitepaperDetails;
