import React from "react";

const AccessPermissionLogo = () => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 14.3955V9.39551C22 7.80421 21.3679 6.27809 20.2426 5.15287C19.1174 4.02765 17.5913 3.39551 16 3.39551C14.4087 3.39551 12.8826 4.02765 11.7574 5.15287C10.6321 6.27809 10 7.80421 10 9.39551V14.3955M9 29.3955H23C23.7956 29.3955 24.5587 29.0794 25.1213 28.5168C25.6839 27.9542 26 27.1912 26 26.3955V17.3955C26 16.5999 25.6839 15.8368 25.1213 15.2742C24.5587 14.7116 23.7956 14.3955 23 14.3955H9C8.20435 14.3955 7.44129 14.7116 6.87868 15.2742C6.31607 15.8368 6 16.5999 6 17.3955V26.3955C6 27.1912 6.31607 27.9542 6.87868 28.5168C7.44129 29.0794 8.20435 29.3955 9 29.3955Z"
        stroke="#00FF82"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AccessPermissionLogo;
