import React from "react";

const OrganizationIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 64 65"
      fill="none"
    >
      <g clip-path="url(#clip0_17844_10653)">
        <path
          d="M32.9951 46.3911L32.9951 27.2002L30.995 27.2002L30.995 46.3911L32.9951 46.3911Z"
          fill="#CCCCCC"
        />
        <path
          d="M8.31247 46.393L8.31247 35.8115L55.6797 35.8115L55.6797 46.393L53.6796 46.393L53.6796 37.8116L10.3126 37.8116L10.3126 46.393L8.31247 46.393Z"
          fill="#CCCCCC"
        />
        <path
          d="M39.2842 64.8079L39.2842 50.2402L24.7165 50.2402L24.7165 64.8079L39.2842 64.8079Z"
          fill="#BBBBBB"
        />
        <path
          d="M64 64.8079L64 50.2402L49.4323 50.2402L49.4323 64.8079L64 64.8079Z"
          fill="#5D5D5D"
        />
        <path
          d="M14.5732 64.8079L14.5732 50.2402L0.00555801 50.2402L0.00555738 64.8079L14.5732 64.8079Z"
          fill="white"
        />
        <path
          d="M43.2695 23.3487L43.2695 0.807617L20.7285 0.807616L20.7285 23.3487L43.2695 23.3487Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_17844_10653">
          <rect
            width="64"
            height="64"
            fill="white"
            transform="translate(64 0.808594) rotate(90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OrganizationIcon;
