import React from "react";

const BasicUserDataLogo = () => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0004 25.8995C21.1377 26.2297 22.3161 26.3967 23.5004 26.3955C25.4049 26.3983 27.2847 25.964 28.995 25.1262C29.0456 23.9311 28.7052 22.7521 28.0254 21.7679C27.3456 20.7837 26.3635 20.048 25.228 19.6722C24.0924 19.2964 22.8653 19.301 21.7326 19.6854C20.5999 20.0697 19.6234 20.8129 18.951 21.8022M20.0004 25.8995V25.8955C20.0004 24.4115 19.619 23.0155 18.951 21.8022M20.0004 25.8995V26.0408C17.4343 27.5863 14.4945 28.4006 11.499 28.3955C8.39102 28.3955 5.48302 27.5355 3.00036 26.0408L2.99902 25.8955C2.998 24.0081 3.62518 22.1741 4.78167 20.6825C5.93816 19.191 7.55819 18.1268 9.38634 17.6576C11.2145 17.1885 13.1468 17.3412 14.8786 18.0916C16.6104 18.842 18.0431 20.1475 18.951 21.8022M16.0004 8.89551C16.0004 10.089 15.5263 11.2336 14.6823 12.0775C13.8384 12.9214 12.6938 13.3955 11.5004 13.3955C10.3069 13.3955 9.16229 12.9214 8.31838 12.0775C7.47446 11.2336 7.00036 10.089 7.00036 8.89551C7.00036 7.70203 7.47446 6.55744 8.31838 5.71353C9.16229 4.86961 10.3069 4.39551 11.5004 4.39551C12.6938 4.39551 13.8384 4.86961 14.6823 5.71353C15.5263 6.55744 16.0004 7.70203 16.0004 8.89551ZM27.0004 11.8955C27.0004 12.8238 26.6316 13.714 25.9752 14.3704C25.3189 15.0268 24.4286 15.3955 23.5004 15.3955C22.5721 15.3955 21.6819 15.0268 21.0255 14.3704C20.3691 13.714 20.0004 12.8238 20.0004 11.8955C20.0004 10.9673 20.3691 10.077 21.0255 9.42063C21.6819 8.76426 22.5721 8.39551 23.5004 8.39551C24.4286 8.39551 25.3189 8.76426 25.9752 9.42063C26.6316 10.077 27.0004 10.9673 27.0004 11.8955Z"
        stroke="#00FF82"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BasicUserDataLogo;
