import React from "react";

const ResourcesIllustration = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 408 360" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M341.859 360L408 321.871L341.859 283.741L275.719 321.871L341.859 360Z" fill="url(#paint0_linear_9346_38415)" />
            <path d="M341.859 291.573L401.206 325.782L408 321.871L341.859 283.741L275.719 321.871L282.513 325.782L341.859 291.573Z" fill="#BEE7D3" />
            <path d="M341.86 299.449L394.377 329.729L401.206 325.782L341.86 291.573L282.513 325.782L289.342 329.729L341.86 299.449Z" fill="#085016" />
            <path d="M341.859 283.741V291.573L282.513 325.782L275.719 321.871L341.859 283.741Z" fill="#085016" />
            <path d="M341.86 291.573L282.513 325.782" stroke="#15C237" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M338.651 341.28H331.673V348.248H338.651V341.28Z" fill="#085016" />
            <path d="M359.593 341.28V348.248H362.242L366.571 345.759V341.28H359.593Z" fill="#085016" />
            <path d="M345.628 334.312H338.65V341.28H345.628V334.312Z" fill="#085016" />
            <path d="M331.673 313.41H324.695V320.377H331.673V313.41Z" fill="#085016" />
            <path d="M331.673 320.377H324.695V327.345H331.673V320.377Z" fill="#085016" />
            <path d="M387.644 297.764L334.445 267.091C332.346 265.886 328.918 265.886 326.828 267.091L289.229 288.762L287.051 288.561V291.302C287.051 292.097 287.576 292.892 288.625 293.503L343.259 324.996C345.357 326.201 348.785 326.201 350.875 324.996L389.078 302.976C390.127 302.374 390.652 301.579 390.652 300.785V298.043L387.653 297.772L387.644 297.764Z" fill="#15C237" />
            <path d="M334.436 264.349C332.337 263.144 328.909 263.144 326.82 264.349L288.616 286.369C287.567 286.972 287.042 287.766 287.042 288.57C287.042 289.373 287.567 290.159 288.616 290.77L343.25 322.264C345.348 323.469 348.776 323.469 350.866 322.264L389.069 300.243C390.118 299.641 390.643 298.846 390.643 298.043C390.643 297.24 390.118 296.454 389.069 295.843L334.436 264.349Z" fill="url(#paint1_linear_9346_38415)" />
            <path d="M403.427 242.547C405.526 243.752 406.724 246.651 406.094 248.982L394.018 293.8C393.389 296.131 391.159 297.056 389.069 295.843L334.436 264.349C332.337 263.144 331.139 260.245 331.769 257.914L343.844 213.097C344.474 210.765 346.704 209.84 348.794 211.054L403.427 242.547Z" fill="url(#paint2_linear_9346_38415)" />
            <path d="M365.994 242.407L358.701 269.466L327.108 251.252L334.401 224.194L365.994 242.407Z" stroke="#00FF82" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M376.644 274.643L375.411 279.245L365.722 273.665L366.964 269.064L376.644 274.643Z" fill="#00FF82" />
            <path d="M372.158 246.145L390.136 256.509" stroke="#00FF82" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M371.415 249.873L389.393 260.228" stroke="#00FF82" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M370.663 253.592L378.83 258.298" stroke="#00FF82" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M315.819 280.886L335.634 269.466L380.194 295.153L360.38 306.573L315.819 280.886Z" fill="#BCD8BE" />
            <path d="M308.37 298.436L329.933 310.869" stroke="#BCD8BE" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M346.555 217.061L402.045 249.052" stroke="#15C237" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M366.833 345.602V342.791C366.833 333.509 378.821 335.177 378.821 327.345V318.972C378.821 317.505 377.78 315.698 376.504 314.964L372.394 312.598" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M372.394 312.589L370.855 313.48L373.968 311.681" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M284.282 43.2249L260.773 56.8276L284.282 70.4179L307.791 56.8276L284.282 43.2249Z" fill="#00FF82" />
            <path d="M260.773 109.981V56.8276L284.282 70.4179V123.583L260.773 109.981Z" fill="#15C237" />
            <path d="M307.791 109.981V56.8276L284.282 70.4179V123.583L307.791 109.981Z" fill="#011906" />
            <path d="M199.882 291.077L293.918 236.678L199.882 182.28L105.846 236.678L199.882 291.077Z" fill="url(#paint3_linear_9346_38415)" />
            <path d="M199.882 193.453L284.258 242.259L293.918 236.678L199.882 182.28L105.846 236.678L115.506 242.259L199.882 193.453Z" fill="#15C237" />
            <path d="M199.882 204.689L274.548 247.877L284.258 242.259L199.882 193.453L115.506 242.259L125.215 247.877L199.882 204.689Z" fill="#011906" />
            <path d="M199.882 182.28V193.453L115.506 242.259L105.846 236.678L199.882 182.28Z" fill="#011906" />
            <path d="M199.882 193.453L115.506 242.259" stroke="#15C237" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M200.068 248.338H190.147V258.278H200.068V248.338Z" fill="#011906" />
            <path d="M200.068 258.291H190.147V268.231H200.068V258.291Z" fill="#011906" />
            <path d="M200.068 228.457H190.147V238.397H200.068V228.457Z" fill="#011906" />
            <path d="M225.094 264.37V274.31H228.861L235.014 270.748V264.37H225.094Z" fill="#011906" />
            <path d="M190.147 258.291H180.227V268.231H190.147V258.291Z" fill="#011906" />
            <path d="M170.306 258.291H160.385V268.231H170.306" fill="#011906" />
            <path d="M170.306 268.231H160.385L170.306 273.961" fill="#011906" />
            <path d="M115.108 282.282L0 343.233L34.9339 359.601L145.579 297.031L115.108 282.282Z" fill="#011906" />
            <path d="M114.511 279.941L115.754 292.721C117.172 293.033 118.738 293.606 119.832 294.664C122.319 297.081 123.786 294.814 125.091 291.687C126.396 288.561 128.261 284.749 128.261 284.749H128.286C127.204 283.291 126.558 281.523 126.558 279.604" fill="#BEE7D3" />
            <path d="M115.469 298.414C115.071 303.147 120.168 303.172 125.315 301.927L145.591 297.031C149.57 296.072 154.157 291.999 147.941 290.828L133.607 288.1C131.406 287.676 129.554 286.443 128.298 284.736H128.274C128.274 284.736 126.396 288.561 125.103 291.675C123.798 294.801 122.331 297.056 119.845 294.652C118.751 293.593 117.184 293.02 115.767 292.709L115.904 293.344L115.481 298.401L115.469 298.414Z" fill="#011906" />
            <path d="M101.122 286.281C101.122 286.281 101.135 287.215 101.222 293.12C101.309 299.024 109.576 298.514 110.993 294.365L115.121 282.282C115.593 280.887 114.984 279.069 113.765 278.234L110.197 275.817L107.301 283.428L101.122 286.281Z" fill="#011906" />
            <path d="M112.162 275.817C112.162 275.817 110.521 283.042 110.098 286.48C110.098 286.48 104.367 289.869 101.122 286.281L100.774 272.491L112.174 275.817H112.162Z" fill="#BEE7D3" />
            <path d="M151.782 97.7231L157.19 98.8567C159.764 99.3923 162.897 97.9473 164.152 95.6304L166.651 90.9965C170.269 84.4193 180.55 82.7999 186.542 87.2968C196.873 95.0449 196.326 105.421 186.455 113.755L178.549 120.432C175.515 122.998 171.587 122.176 169.809 118.626L167.521 114.054L160.05 117.056L151.77 97.7356L151.782 97.7231Z" fill="#15C237" />
            <path d="M124.556 170.209L128.174 167.207L173.029 129.999L171.263 114.577L153.61 98.1093L136.64 94.5466L111.726 104.724L78.2345 145.594C70.9743 154.464 69.1219 170.147 74.132 180.461L92.2951 217.844L98.0885 224.882L95.975 231.608L98.909 275.618C108.954 281.597 116.065 275.618 116.065 275.618L105.734 180.76L124.544 170.196L124.556 170.209Z" fill="#011906" />
            <path d="M78.2345 145.594C70.9743 154.464 69.1219 170.147 74.132 180.461L92.2951 217.844L98.0885 224.882L95.975 231.608L98.909 275.618" stroke="#15C237" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M124.556 170.209L128.174 167.207L173.041 129.986" stroke="#15C237" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M91.6113 147.401L112.795 159.658C116.301 161.689 119.807 166.596 120.603 170.57L121.1 173.086" stroke="#15C237" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M136.64 94.5468C132.948 92.4167 127.005 92.5786 123.425 94.908L91.4746 115.673L78.7939 139.453L89.9579 143.004L100.376 121.192C102.029 117.716 106.07 113.244 109.352 111.239L136.64 94.5343V94.5468Z" fill="white" />
            <path d="M171.263 114.577C154.704 115.088 149.545 125.29 149.196 133.474L138.952 142.256L103.397 149.095V158.624L144.733 156.345L170.418 139.615C180.239 133.225 179.543 124.543 174.135 118.04L171.263 114.59V114.577Z" fill="white" />
            <path d="M144.746 156.332L170.43 139.603C176.945 135.355 178.834 130.111 177.777 125.153" stroke="#00FF82" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M91.6113 183.152L105.995 222.154L110.234 227.311L108.071 234.997L112.621 282.432C121.162 288.374 129.778 282.432 129.778 282.432L124.556 170.197" fill="#011906" />
            <path d="M91.6113 183.152L105.995 222.166L110.234 227.311L108.071 235.009L112.609 282.444" stroke="#15C237" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M103.397 149.082H98.0014L95.3161 145.046H88.5034L80.2361 153.941L81.6782 160.343L96.7706 162.1L103.397 158.612V149.082Z" fill="#15C237" />
            <path d="M79.4778 142.007L82.6853 136.588C82.6853 136.588 88.2673 134.657 89.6099 138.544L88.3419 142.493L92.1336 146.628L90.5299 151.237L82.5983 155.934L77.3022 151.499L79.4778 142.019V142.007Z" fill="#15C237" />
            <path d="M92.1336 146.616L91.6115 153.579L82.5983 155.921L77.3022 151.487" stroke="#00FF82" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M185.361 109.42C187.226 109.756 189.364 108.585 190.098 106.829L191.888 102.556L193.927 102.893C195.183 97.3246 192.895 91.7066 186.654 87.0353C180.575 82.4761 170.169 84.1204 166.514 90.7848L163.481 96.3031L172.544 107.14L185.349 109.42H185.361Z" fill="#00FF82" />
            <path d="M179.133 112.136C181.302 112.136 183.061 110.373 183.061 108.199C183.061 106.025 181.302 104.263 179.133 104.263C176.963 104.263 175.204 106.025 175.204 108.199C175.204 110.373 176.963 112.136 179.133 112.136Z" fill="#15C237" />
            <path d="M153.61 98.0969L157.464 98.8319C159.329 99.1807 161.579 100.85 162.474 102.519L166.564 110.18L153.61 98.0969Z" fill="white" />
            <path d="M156.73 127.233L138.953 142.244" stroke="#00FF82" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M97.4546 115.81L100.6 114.727C101.793 114.316 103.385 114.801 104.143 115.81" stroke="#00FF82" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M83.8167 133.449C83.8167 133.449 89.0505 130.41 92.5688 135.38" stroke="#00FF82" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M111.689 147.326L112.771 155.161" stroke="#00FF82" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M112.298 228.756L120.354 235.831C120.354 235.831 118.626 238.285 116.016 237.251C113.405 236.217 112.298 239.593 112.298 239.593" stroke="#15C237" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M98.1008 224.894L102.116 229.067" stroke="#15C237" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M260.774 91.146L237.265 104.736L260.774 118.339L284.283 104.736L260.774 91.146Z" fill="#00FF82" />
            <path d="M237.265 157.902V104.736L260.774 118.339V171.504L237.265 157.902Z" fill="#15C237" />
            <path d="M284.282 157.902V104.736L260.773 118.339V171.504L284.282 157.902Z" fill="#011906" />
            <path d="M215.211 0L191.702 13.6028L215.211 27.2055L238.719 13.6028L215.211 0Z" fill="#00FF82" />
            <path d="M191.702 66.7682V13.6028L215.211 27.2055V80.3585L191.702 66.7682Z" fill="#15C237" />
            <path d="M238.719 66.7682V13.6028L215.21 27.2055V80.3585L238.719 66.7682Z" fill="#011906" />
            <path d="M215.21 80.3584V160.904C215.21 164.541 217.784 169.001 220.929 170.819L243.493 183.874C245.88 185.257 245.88 187.524 243.493 188.894L225.927 199.059C223.54 200.441 221.588 203.83 221.588 206.595" stroke="#00FF82" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M260.773 171.504V199.345C260.773 202.111 258.822 205.499 256.435 206.882L246.601 212.574C244.214 213.957 242.262 217.345 242.262 220.111" stroke="#00FF82" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M221.576 206.608V224.221" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M242.25 220.098V234.548" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <defs>
                <linearGradient id="paint0_linear_9346_38415" x1="375" y1="321.871" x2="363.921" y2="321.871" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#011906" />
                    <stop offset="0.05" stopColor="#001E06" />
                    <stop offset="0.24" stopColor="#074F15" />
                    <stop offset="0.42" stopColor="#088D22" />
                    <stop offset="0.59" stopColor="#11B532" />
                    <stop offset="0.75" stopColor="#15C237" />
                    <stop offset="0.89" stopColor="#15C237" />
                    <stop offset="1" stopColor="#15C237" />
                </linearGradient>
                <linearGradient id="paint1_linear_9346_38415" x1="303.001" y1="293.302" x2="326.382" y2="293.302" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#BEE7D3" />
                    <stop offset="0.17" stopColor="#D2F2E2" />
                    <stop offset="0.32" stopColor="#F9F9FD" />
                    <stop offset="0.45" stopColor="white" />
                </linearGradient>
                <linearGradient id="paint2_linear_9346_38415" x1="362.854" y1="242.905" x2="367.278" y2="250.603" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#085016" />
                    <stop offset="0.22" stopColor="#085016" />
                    <stop offset="0.42" stopColor="#085016" />
                    <stop offset="0.59" stopColor="#077D1E" />
                    <stop offset="0.75" stopColor="#06B428" />
                    <stop offset="0.89" stopColor="#15C237" />
                    <stop offset="1" stopColor="#15C237" />
                </linearGradient>
                <linearGradient id="paint3_linear_9346_38415" x1="144.584" y1="236.678" x2="158.396" y2="236.678" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#011906" />
                    <stop offset="0.11" stopColor="#04270B" />
                    <stop offset="0.37" stopColor="#0B641D" />
                    <stop offset="0.62" stopColor="#15C237" />
                    <stop offset="0.83" stopColor="#15C237" />
                    <stop offset="1" stopColor="#15C237" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default ResourcesIllustration;