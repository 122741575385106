import React, { useEffect, useState } from "react";
import {
  FadeInSection,
  GreenItalicText,
  LoadingSpinner,
  DuneButton,
} from "../../../components";
import { createClient } from "contentful";
import { Link } from "react-router-dom";
import {
  Box,
  Typography,
  Stack,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import { constants } from "../../../constants";
import isEmail from "validator/lib/isEmail";
import { postContactMessage } from "../../../constants/apis";
import { ResourcesIllustration } from "../../../images";
import SearchIcon from "../../../components/common/search-icon/search-icon";
import { Translate } from "react-auto-translate";
import { saveAs } from "file-saver";
import DownloadIcon from "../../../components/common/download-icon/download-icon";
import { useNavigate } from "react-router-dom";

const Resources = () => {
  const [fetchedBootcamp, setFetchedBootcamp] = useState([]);
  const [fetchedTeam, setFetchedTeam] = useState([]);
  const [fetchedWebinar, setFetchedWebinar] = useState([]);
  const [fetchedWhitepaper, setFetchedWhitepaper] = useState([]);
  const [mostRecentResources, setMostRecentResources] = useState([]);
  const [messageSuccess, setMessageSuccess] = useState("");
  const [isErrorEmail, setIsErrorEmail] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedInfographic, setSelectedInfographic] = useState(null);
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    title: "",
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [currentId, setCurrentId] = useState(null);

  const navigate = useNavigate();

  const handleClickSubmitSubscribe = async (event) => {
    event.preventDefault();

    setIsLoading(true);
    setMessageError("");
    setMessageSuccess("");
    setIsErrorEmail(false);

    let isError = false;

    const data = new FormData(event.currentTarget);
    const email = data.get("email");

    if (!email) {
      setIsErrorEmail(true);
      isError = true;
    }

    if (isError) {
      setMessageError(constants.ERROR_EMPTY_FIELDS);
    } else if (!isEmail(email)) {
      setIsErrorEmail(true);
      setMessageError("Please enter a valid email.");
    } else {
      const requestBody = {
        first_name: "",
        last_name: "",
        email,
        subject: "Subscription request",
        message: `${email} signed up for a monthly newsletter highlighting the newest social engineering hacks to watch out for and how to best prepare for them.`,
      };

      const contactMessage = await postContactMessage(requestBody);

      if (Object.keys(contactMessage.error).length > 0) {
        setMessageError(constants.ERROR_DEFAULT);
      } else {
        setMessageSuccess("Your message has been sent.");
      }
    }

    setIsLoading(false);
  };

  const handleCloseSnackbarSuccess = () => {
    setMessageSuccess("");
  };

  const handleCloseSnackbarError = () => {
    setMessageError("");
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  useEffect(() => {
    if (process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN) {
      const client = createClient({
        space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
        accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
      });

      const fetchResources = async () => {
        try {
          const bootcampResponse = await client.getEntries({
            content_type: "resourcesBootcamp",
            limit: 3,
            order: "-fields.date,fields.title",
          });
          const teamResponse = await client.getEntries({
            content_type: "resourcesTeam",
            limit: 3,
            order: "-fields.date,fields.title",
          });
          const webinarResponse = await client.getEntries({
            content_type: "webinarRecapBlog",
            limit: 3,
            order: "-fields.date,fields.title",
          });
          const whitepaperResponse = await client.getEntries({
            content_type: "whitepaper",
            limit: 3,
            order: "-fields.date,fields.title",
          });

          // Map and extract relevant data for each type of resource
          const bootcampResources = bootcampResponse.items.map((item) => ({
            id: item.sys.id,
            title: item.fields.title,
            date: item.fields.date,
            thumbnailURL: item.fields.thumbnail?.fields?.file?.url
              ? item.fields.thumbnail.fields.file.url.startsWith("//")
                ? `https:${item.fields.thumbnail.fields.file.url}`
                : item.fields.thumbnail.fields.file.url
              : "",
            href: `/resources/bootcamp/${item.fields.resourceID}`,
          }));

          const teamResources = teamResponse.items.map((item) => ({
            id: item.sys.id,
            title: item.fields.title,
            date: item.fields.date,
            thumbnailURL: item.fields.thumbnail?.fields?.file?.url
              ? item.fields.thumbnail.fields.file.url.startsWith("//")
                ? `https:${item.fields.thumbnail.fields.file.url}`
                : item.fields.thumbnail.fields.file.url
              : "",
            href: item.fields.resourceURL,
          }));

          const webinarResources = webinarResponse.items.map((item) => ({
            id: item.sys.id,
            title: item.fields.title,
            date: item.fields.date,
            thumbnailURL: item.fields.thumbnail?.fields?.file?.url
              ? item.fields.thumbnail.fields.file.url.startsWith("//")
                ? `https:${item.fields.thumbnail.fields.file.url}`
                : item.fields.thumbnail.fields.file.url
              : "",
            href: `/resources/webinar-recap-blog/${item.fields.resourceID}`,
          }));

          const whitepaperResources = whitepaperResponse.items.map((item) => ({
            id: item.sys.id,
            title: item.fields.title,
            date: item.fields.date,
            thumbnailURL: item.fields.thumbnail?.fields?.file?.url
              ? item.fields.thumbnail.fields.file.url.startsWith("//")
                ? `https:${item.fields.thumbnail.fields.file.url}`
                : item.fields.thumbnail.fields.file.url
              : "",
            coverImage: item.fields.coverImage?.fields?.file?.url
              ? item.fields.coverImage.fields.file.url.startsWith("//")
                ? `https:${item.fields.coverImage.fields.file.url}`
                : item.fields.coverImage.fields.file.url
              : "",
            href: `/resources/whitepaper/${item.fields.referenceId}`,
          }));

          setFetchedBootcamp(bootcampResources);
          setFetchedTeam(teamResources);
          setFetchedWebinar(webinarResources);
          setFetchedWhitepaper(whitepaperResources);

          // Combine all fetched resources into one array
          const allResources = [
            ...bootcampResources,
            ...teamResources,
            ...webinarResources,
            ...whitepaperResources,
          ];

          // Sort by date (descending)
          const sortedResources = allResources.sort(
            (a, b) => new Date(b.date) - new Date(a.date),
          );

          // Set the top 3 most recent resources
          setMostRecentResources(sortedResources.slice(0, 3));
        } catch (error) {
          console.error(error);
        }
      };

      fetchResources();
    }
  }, []);

  const downloadImage = () => {
    if (selectedInfographic) {
      saveAs(
        `${baseURL}${selectedInfographic.imgUrl}`,
        `${selectedInfographic.title}.jpg`,
      );
    }
  };

  const infographics = [
    {
      sr: 1,
      title: "QR Codes",
      imgUrl: "15.+QR+Codes.png",
    },
    {
      sr: 2,
      title: "AI Chatbots",
      imgUrl: "1.+AI+Chatbots.png",
    },
    {
      sr: 3,
      title: "MFA Attacks",
      imgUrl: "17.+MFA+Attacks.png",
    },
    {
      sr: 4,
      title: "Web Conference Phishing",
      imgUrl: "14.+Web+Conference+Phishing-2.png",
    },
  ];

  const openModal = (infographic) => {
    setSelectedInfographic(infographic);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedInfographic(null);
  };

  const filteredInfographics = infographics.filter((infographic) =>
    infographic.title.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const filteredMostRecent = mostRecentResources.filter((resource) =>
    resource.title.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const filteredWebinar = fetchedWebinar.filter((resource) =>
    resource.title.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const filteredBootcamp = fetchedBootcamp.filter((resource) =>
    resource.title.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const filteredTeam = fetchedTeam.filter((resource) =>
    resource.title.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const filteredWhitepaper = fetchedWhitepaper.filter((resource) =>
    resource.title.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const baseURL =
    "https://d3oo9a669kwmx7.cloudfront.net/resources-infographics/";

  if (isLoading) {
    return <LoadingSpinner isLoading={isLoading} />;
  }

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
    return null;
  };

  const handleModal = () => {
    const emailPaywallCookie = getCookie("emailPayWall");
    if (emailPaywallCookie !== "true") {
      setIsOpen(true);
    } else {
      navigate(`/resources/whitepaper/${currentId}`);
    }
  };

  const setCookie = (name, value, days) => {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
  };

  const handleFormSubmission = (e) => {
    e.preventDefault();

    setIsFormSubmitted(true);

    setCookie("emailPayWall", "true", 999);

    if (currentId !== null && isFormSubmitted) {
      navigate(`/resources/whitepaper/${currentId}`);
    } else {
      console.log("Error");
    }
  };

  return (
    <div className="text-white max-w-78.75 mx-auto px-4">
      <FadeInSection>
        <div className="my-5 mb-2.5 mx-auto text-center">
          <div className="text-green text-h4 uppercase flex justify-center text-2xl tracking-custom-1.6">
            Resource Hub
          </div>
          <div className="mt-2.5 text-h3 font-medium md:w-8/12 w-full text-center mx-auto">
            Everything you <GreenItalicText text=" need to know " /> to combat
            social engineering at your enterprise
          </div>
        </div>
      </FadeInSection>
      <FadeInSection>
        <div className="relative mb-2 md:w-1/2 mx-auto">
          <span className="absolute inset-y-0 -left-1 flex items-center pl-3">
            <SearchIcon />
          </span>
          <input
            type="text"
            className="w-full pl-5 bg-transparent border border-gray border-opacity-15 focus:border-green focus:outline-none rounded-full p-1.25"
            placeholder="Search for security tips, cyber news, and more"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </FadeInSection>

      <FadeInSection>
        <div className="mb-5 mt-10 text-h2">Most Recent</div>
        {filteredMostRecent.length > 0 ? (
          <div className="max-w-78.75 mx-auto grid grid-cols-1 md:grid-cols-3 items-center gap-4">
            {filteredMostRecent.map((resource) => (
              <a
                key={resource.id}
                href={resource.href}
                target="_blank"
                rel="noopener noreferrer"
                className="group cursor-pointer"
              >
                <div className="image-container w-full overflow-hidden rounded-lg">
                  <img
                    src={resource.thumbnailURL || resource.coverImage}
                    alt={resource.title}
                    className="w-full h-14.375 object-cover transition-transform duration-300 ease-in-out group-hover:scale-110 rounded-medium"
                  />
                </div>
                <div className="mt-2">
                  <h3 className="text-leading-tight">{resource.title}</h3>
                  <p className="text-gray-400 mt-2">
                    {formatDate(resource.date)}
                  </p>
                </div>
              </a>
            ))}
          </div>
        ) : (
          <div>No resources found</div>
        )}
      </FadeInSection>
      {/* Other sections: Bootcamp, Team, Webinar, Whitepaper */}

      <FadeInSection>
        <div className="mb-5 mt-10 text-h2">Dune Security Bootcamp</div>
        {filteredBootcamp.length > 0 ? (
          <div className="max-w-78.75 mx-auto grid grid-cols-1 md:grid-cols-3 gap-4">
            {filteredBootcamp.map((resource) => (
              <a
                key={resource.id}
                href={resource.href}
                target="_blank"
                rel="noopener noreferrer"
                className="group cursor-pointer"
              >
                <div className="image-container w-full overflow-hidden rounded-lg">
                  <img
                    src={resource.thumbnailURL}
                    alt={resource.title}
                    className="w-full h-full object-cover transition-transform duration-300 ease-in-out group-hover:scale-110"
                  />
                </div>
                <div className="mt-2">
                  <h3 className="text-leading-tight">{resource.title}</h3>
                  <p className="text-gray-400 mt-2">
                    {formatDate(resource.date)}
                  </p>
                </div>
              </a>
            ))}
          </div>
        ) : (
          <div>No resources found</div>
        )}
        <div className="flex justify-end max-w-78.75 mt-4">
          <Link
            to="/resources/bootcamp"
            className="inline-flex items-center hover:text-gray transform ease-in-out duration-100 text-lg"
          >
            View all <span className="ml-2">&rarr;</span>
          </Link>
        </div>
      </FadeInSection>

      <FadeInSection>
        <div className="mb-5 mt-10 text-h2">Whitepapers</div>
        {filteredWhitepaper.length > 0 ? (
          <div className="max-w-78.75 mx-auto grid grid-cols-1 md:grid-cols-3 gap-4">
            {filteredWhitepaper.map((resource) => (
              <div
                key={resource.id}
                target="_blank"
                rel="noopener noreferrer"
                className="group cursor-pointer"
                onClick={() => {
                  if (getCookie("emailPayWall") !== "true") {
                    navigate(
                      `/resources/whitepaper/${resource.href.split("/")[3]}`,
                    );
                  } else {
                    const id = resource.href.split("/")[3];
                    setCurrentId(id);
                    handleModal(id);
                  }
                }}
              >
                <div className="image-container w-full overflow-hidden rounded-lg">
                  <img
                    src={resource.coverImage}
                    alt={resource.title}
                    className="w-full h-full object-cover transition-transform duration-300 ease-in-out group-hover:scale-110"
                  />
                </div>
                <div className="mt-2">
                  <h3 className="text-leading-tight">{resource.title}</h3>
                  <p className="text-gray-400 mt-2">
                    {formatDate(resource.date)}
                  </p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div>No resources found</div>
        )}
        <div className="flex justify-end max-w-78.75 mt-4">
          <Link
            to="/resources/whitepaper"
            className="inline-flex items-center hover:text-gray transform ease-in-out duration-100 text-lg"
          >
            View all <span className="ml-2">&rarr;</span>
          </Link>
        </div>

        {isOpen && (
          <div className="fixed inset-0 z-50 bg-black text-white bg-opacity-70 flex items-center justify-center">
            <div className="bg-black py-2 px-2 md:px-6.5 rounded-lg max-w-40.625 w-full">
              <div className="flex justify-between items-center mb-1">
                <div className="text-h3">Enter Contact Info</div>
                <button
                  onClick={() => setIsOpen(false)}
                  className="text-white font-bold text-h3"
                >
                  &times;
                </button>
              </div>
              <form>
                <div className="flex flex-col md:flex-row justify-center items-center">
                  <div className="p-1">
                    <label className="block mb-0.25">First Name</label>
                    <input
                      type="text"
                      className="bg-transparent text-white border rounded-small px-1 py-0.25 border-gray border-opacity-35"
                      value={form.firstName}
                      onChange={(e) =>
                        setForm({ ...form, firstName: e.target.value })
                      }
                      required
                    />
                  </div>
                  <div className="p-1">
                    <label className="block mb-0.25">Last Name</label>
                    <input
                      type="text"
                      className="bg-transparent text-white border rounded-small px-1 py-0.25 border-gray border-opacity-35"
                      value={form.lastName}
                      onChange={(e) =>
                        setForm({ ...form, lastName: e.target.value })
                      }
                      required
                    />
                  </div>
                </div>
                <div className="flex flex-col md:flex-row justify-center items-center">
                  <div className="p-1 mb-0.25">
                    <label className="block">Email</label>
                    <input
                      type="email"
                      className="bg-transparent text-white border rounded-small px-1 py-0.25 border-gray border-opacity-35"
                      value={form.email}
                      onChange={(e) =>
                        setForm({ ...form, email: e.target.value })
                      }
                      required
                    />
                  </div>
                  <div className="p-1">
                    <label className="block mb-0.25">Company</label>
                    <input
                      type="text"
                      className="bg-transparent text-white border rounded-small px-1 py-0.25 border-gray border-opacity-35"
                      value={form.company}
                      onChange={(e) =>
                        setForm({ ...form, company: e.target.value })
                      }
                      required
                    />
                  </div>
                </div>
                <div className="flex flex-col md:flex-row justify-center items-center">
                  <div className="p-1">
                    <label className="block">Title</label>
                    <input
                      type="text"
                      className="bg-transparent text-white border rounded-small px-1 py-0.25 border-gray border-opacity-35"
                      value={form.title}
                      onChange={(e) =>
                        setForm({ ...form, title: e.target.value })
                      }
                      required
                    />
                  </div>
                </div>
                <div className="flex flex-wrap justify-center items-center space-x-1 mt-2">
                  <DuneButton
                    variant="dune-outlined"
                    text="Cancel"
                    className={`w-fit`}
                    onMouseDown={() => setIsOpen(false)}
                  />
                  <DuneButton
                    variant="dune-glow"
                    text="Submit"
                    type="submit"
                    onMouseDown={handleFormSubmission}
                  />
                </div>
              </form>
            </div>
          </div>
        )}
      </FadeInSection>

      <FadeInSection>
        <div className="mt-10 text-h2">Infographics</div>
        {filteredInfographics.length > 0 ? (
          <div className="flex justify-start items-center h-full pt-5 text-white">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2">
              {filteredInfographics.map((infographic) => (
                <div
                  key={infographic.sr}
                  className="cursor-pointer"
                  onClick={() => openModal(infographic)}
                >
                  <div className="overflow-hidden flex justify-center">
                    <img
                      src={`${baseURL}${infographic.imgUrl}`}
                      alt={infographic.title}
                      className="w-78.75 md:w-60 max-h-screen object-contain pt-2 transform transition-transform duration-300 ease-in-out hover:scale-110"
                    />
                  </div>
                  <div className="w-fit text-center mx-auto flex justify-center mt-1 text-h4 min-w-10 underline underline-offset-4 hover:text-gray transform ease-in duration-300 cursor-pointer">
                    {infographic.title}
                  </div>
                </div>
              ))}
            </div>

            {isOpen && selectedInfographic && (
              <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50">
                <div className="bg-black rounded-small mt-6 relative py-4 md:py-0">
                  <button
                    className="absolute top-0 md:top-2 right-2 md:right-10 text-white text-3xl hover:text-gray-400"
                    onClick={closeModal}
                  >
                    &times;
                  </button>

                  <div className="overflow-hidden">
                    <img
                      src={`${baseURL}${selectedInfographic.imgUrl}`}
                      alt={selectedInfographic.title}
                      className="w-78.75 md:w-60 max-h-screen object-contain pt-2"
                    />
                  </div>

                  <div className="flex justify-center">
                    <button
                      onClick={downloadImage}
                      className="flex items-center justify-center bg-dune-green-100 text-white text-h4 mt-1 mb-2 font-semibold rounded-lg hover:bg-dune-green transition-all duration-300 ease-in-out"
                    >
                      <span className="pr-1">
                        <DownloadIcon />
                      </span>
                      Download Image
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div>No resources found</div>
        )}
        <div className="flex justify-end max-w-78.75 mt-4">
          <Link
            to="/resources/infographics"
            className="inline-flex items-center hover:text-gray transform ease-in-out duration-100 text-lg"
          >
            View all <span className="ml-2">&rarr;</span>
          </Link>
        </div>
      </FadeInSection>

      <FadeInSection>
        <div className="mb-5 mt-10 text-h2">Events</div>
        {filteredWebinar.length > 0 ? (
          <div className="max-w-78.75 mx-auto grid grid-cols-1 md:grid-cols-3 gap-4">
            {filteredWebinar.map((resource) => (
              <a
                key={resource.id}
                href={resource.href}
                target="_blank"
                rel="noopener noreferrer"
                className="group cursor-pointer"
              >
                <div className="image-container w-full overflow-hidden rounded-lg">
                  <img
                    src={resource.thumbnailURL}
                    alt={resource.title}
                    className="w-full h-full object-cover transition-transform duration-300 ease-in-out group-hover:scale-110"
                  />
                </div>
                <div className="mt-2">
                  <h3 className="text-leading-tight">{resource.title}</h3>
                  <p className="text-gray-400 mt-2">
                    {formatDate(resource.date)}
                  </p>
                </div>
              </a>
            ))}
          </div>
        ) : (
          <div>No resources found</div>
        )}
        <div className="flex justify-end max-w-78.75 mt-4">
          <Link
            to="/resources/team"
            className="inline-flex items-center hover:text-gray transform ease-in-out duration-100 text-lg"
          >
            View all <span className="ml-2">&rarr;</span>
          </Link>
        </div>
      </FadeInSection>

      <FadeInSection>
        <div className="mb-5 mt-10 text-h2">From the team</div>
        {filteredTeam.length > 0 ? (
          <div className="max-w-78.75 mx-auto grid grid-cols-1 md:grid-cols-3 gap-4">
            {filteredTeam.map((resource) => (
              <a
                key={resource.id}
                href={resource.href}
                target="_blank"
                rel="noopener noreferrer"
                className="group cursor-pointer"
              >
                <div className="image-container w-full overflow-hidden rounded-lg">
                  <img
                    src={resource.thumbnailURL}
                    alt={resource.title}
                    className="w-full h-full object-cover transition-transform duration-300 ease-in-out group-hover:scale-110"
                  />
                </div>
                <div className="mt-2">
                  <h3 className="text-leading-tight">{resource.title}</h3>
                  <p className="text-gray-400 mt-2">
                    {formatDate(resource.date)}
                  </p>
                </div>
              </a>
            ))}
          </div>
        ) : (
          <div>No resources found</div>
        )}
        <div className="flex justify-end max-w-78.75 mt-4">
          <Link
            to="/resources/team"
            className="inline-flex items-center hover:text-gray transform ease-in-out duration-100 text-lg"
          >
            View all <span className="ml-2">&rarr;</span>
          </Link>
        </div>
      </FadeInSection>

      <FadeInSection>
        <Box
          className="resources-box-subscribe"
          display="flex"
          justifyContent="center"
          marginTop={{
            xs: "80px",
            md: "120px",
          }}
          padding="0 28px"
        >
          <Box maxWidth="1152px" width="100%" padding="40px 0 144px">
            <Stack
              direction={{
                xs: "column",
                md: "row",
              }}
              alignItems="flex-end"
            >
              <Box
                maxWidth={{
                  xs: "100%",
                  md: "800px",
                }}
                width="100%"
              >
                <Typography component="h2" className="dune-text-header-main">
                  <Translate>Get our</Translate>{" "}
                  <GreenItalicText text="best content " />
                  <Translate>delivered in your inbox</Translate>
                </Typography>
                <Typography variant="body" component="p" marginTop="40px">
                  <Translate>
                    Sign up for a monthly newsletter highlighting the newest
                    social engineering hacks to watch out for and how to best
                    prepare for them.
                  </Translate>
                </Typography>
                <Box
                  component="form"
                  noValidate
                  marginTop="40px"
                  onSubmit={handleClickSubmitSubscribe}
                >
                  <Stack direction="row" alignItems="end">
                    <Box maxWidth="520px" width="100%">
                      <TextField
                        variant="filled"
                        className="resources-text-field-email"
                        name="email"
                        placeholder={"Email"}
                        type="text"
                        error={isErrorEmail}
                        required
                        fullWidth
                        InputProps={{
                          disableUnderline: true,
                        }}
                      />
                    </Box>
                    <Box marginLeft="20px">
                      <DuneButton variant="dune-glow" text="Subscribe" />
                    </Box>
                  </Stack>
                </Box>
                <Box maxWidth="720px" width="100%" marginTop="20px">
                  <Typography component="p" className="resources-text-privacy">
                    <Translate>
                      We value your privacy and we'll only send you relevant
                      info. For more details, check out our
                    </Translate>{" "}
                    <Link
                      className="resources-link-privacy-policy"
                      to="/privacy-policy/"
                      title="Go to the Privacy Policy page."
                    >
                      <Translate>privacy policy</Translate>
                    </Link>
                    .
                  </Typography>
                </Box>
              </Box>
              <Box
                maxWidth="400px"
                width="100%"
                marginLeft={{
                  xs: 0,
                  md: "80px",
                }}
                marginTop={{
                  xs: "40px",
                  md: 0,
                }}
              >
                <ResourcesIllustration />
              </Box>
            </Stack>
          </Box>
        </Box>
      </FadeInSection>

      <Snackbar
        open={messageSuccess !== ""}
        onClose={handleCloseSnackbarSuccess}
      >
        <Alert className="dune-alert-success" severity="success">
          {messageSuccess}
        </Alert>
      </Snackbar>
      <Snackbar open={messageError !== ""} onClose={handleCloseSnackbarError}>
        <Alert className="dune-alert-error" severity="error">
          {messageError}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Resources;
