import React from "react";

const PhoneIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 21 22"
      fill="white"
    >
      <path
        d="M17.42 10.3343H19.1451C19.1451 5.90947 15.8044 2.57227 11.3735 2.57227V4.29736C14.8772 4.29736 17.42 6.83584 17.42 10.3343Z"
        fill="#FFFFFF"
      />
      <path
        d="M11.3821 7.74763C13.196 7.74763 13.9697 8.52134 13.9697 10.3353H15.6948C15.6948 7.55356 14.1638 6.02254 11.3821 6.02254V7.74763ZM14.3337 12.4425C14.168 12.2918 13.9502 12.2115 13.7263 12.2184C13.5025 12.2253 13.29 12.319 13.1339 12.4796L11.0698 14.6023C10.573 14.5074 9.57418 14.196 8.54602 13.1705C7.51786 12.1415 7.20648 11.14 7.11419 10.6467L9.2352 8.58172C9.39598 8.42571 9.48976 8.21326 9.49669 7.98933C9.50361 7.7654 9.42313 7.54756 9.27228 7.38191L6.08517 3.87738C5.93426 3.71121 5.72452 3.61042 5.50049 3.59641C5.27646 3.5824 5.0558 3.65627 4.88536 3.80234L3.01363 5.40754C2.86451 5.5572 2.7755 5.7564 2.76349 5.96733C2.75055 6.18297 2.50386 11.291 6.46469 15.2535C9.92006 18.708 14.2483 18.9608 15.4404 18.9608C15.6146 18.9608 15.7216 18.9556 15.75 18.9539C15.9609 18.9421 16.16 18.8527 16.309 18.7029L17.9133 16.8303C18.0595 16.6599 18.1335 16.4393 18.1196 16.2153C18.1058 15.9913 18.0052 15.7815 17.8391 15.6305L14.3337 12.4425Z"
        fill="#FFFFFF"
      />
    </svg>
  );
};

export default PhoneIcon;
