import React from "react";

const SaveMoney = () => {
  return (
    <svg
      width="81"
      height="81"
      viewBox="0 0 81 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.5 20.5V60.5M30.5 51.1067L33.43 53.3033C37.3333 56.2333 43.6633 56.2333 47.57 53.3033C51.4767 50.3733 51.4767 45.6267 47.57 42.6967C45.62 41.23 43.06 40.5 40.5 40.5C38.0833 40.5 35.6667 39.7667 33.8233 38.3033C30.1367 35.3733 30.1367 30.6267 33.8233 27.6967C37.51 24.7667 43.49 24.7667 47.1767 27.6967L48.56 28.7967M70.5 40.5C70.5 44.4397 69.724 48.3407 68.2164 51.9805C66.7087 55.6203 64.499 58.9274 61.7132 61.7132C58.9274 64.499 55.6203 66.7087 51.9805 68.2164C48.3407 69.724 44.4397 70.5 40.5 70.5C36.5603 70.5 32.6593 69.724 29.0195 68.2164C25.3797 66.7087 22.0726 64.499 19.2868 61.7132C16.501 58.9274 14.2913 55.6203 12.7836 51.9805C11.276 48.3407 10.5 44.4397 10.5 40.5C10.5 32.5435 13.6607 24.9129 19.2868 19.2868C24.9129 13.6607 32.5435 10.5 40.5 10.5C48.4565 10.5 56.0871 13.6607 61.7132 19.2868C67.3393 24.9129 70.5 32.5435 70.5 40.5Z"
        stroke="#00FF82"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SaveMoney;
