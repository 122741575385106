import React from "react";

import "../../../App.css";
import { Translate } from "react-auto-translate";

const GreenItalicText = ({ text, className }) => {
  return (
    <span
      className={`text-green italic leading-[1.2] italic-font ${className}`}
    >
      <Translate>{text}</Translate>
    </span>
  );
};

export default GreenItalicText;
