import React from "react";

const AnomalousRiskyActivityLogo = () => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9998 12.3954V17.3954M3.59576 21.8967C2.44109 23.8967 3.88509 26.3954 6.19309 26.3954H25.8064C28.1131 26.3954 29.5571 23.8967 28.4038 21.8967L18.5984 4.89941C17.4438 2.89941 14.5558 2.89941 13.4011 4.89941L3.59576 21.8967ZM15.9998 21.3954H16.0091V21.4061H15.9998V21.3954Z"
        stroke="#00FF82"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AnomalousRiskyActivityLogo;
