import React, { useState } from "react";
import { saveAs } from "file-saver";
import DownloadIcon from "../../../components/common/download-icon/download-icon";

const ResourceInfographics = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedInfographic, setSelectedInfographic] = useState(null);

  const openModal = (infographic) => {
    setSelectedInfographic(infographic);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedInfographic(null);
  };

  const baseURL =
    "https://d3oo9a669kwmx7.cloudfront.net/resources-infographics/";

  const downloadImage = () => {
    if (selectedInfographic) {
      saveAs(
        `${baseURL}${selectedInfographic.imgUrl}`,
        `${selectedInfographic.title}.jpg`,
      );
    }
  };

  const infographics = [
    {
      sr: 1,
      title: "AI Chatbots",
      imgUrl: "1.+AI+Chatbots.png",
    },
    {
      sr: 2,
      title: "Cloud Drive Security",
      imgUrl: "10.+Cloud+Drive+Security.png",
    },
    {
      sr: 3,
      title: "Whaling",
      imgUrl: "11.+What+is+Whaling.png",
    },
    {
      sr: 4,
      title: "Social Media",
      imgUrl: "12.+Social+Media.png",
    },
    {
      sr: 5,
      title: "Two Factor Authentication",
      imgUrl: "13.+Two-Factor+Authentication.png",
    },
    {
      sr: 6,
      title: "Web Conference Phishing",
      imgUrl: "14.+Web+Conference+Phishing+(1).png",
    },
    {
      sr: 7,
      title: "QR Codes",
      imgUrl: "15.+QR+Codes.png",
    },
    {
      sr: 8,
      title: "Vishing",
      imgUrl: "16.+Vishing+(1).png",
    },
    {
      sr: 9,
      title: "MFA Attacks",
      imgUrl: "17.+MFA+Attacks.png",
    },
    {
      sr: 10,
      title: "Tech Support Social Engineering",
      imgUrl: "18.+Tech+Support+Social+Engineering+(1).png",
    },
    {
      sr: 11,
      title: "Domain Spoofing",
      imgUrl: "19.+Domain+Spoofing.png",
    },
    {
      sr: 12,
      title: "Pretexting",
      imgUrl: "20.+Pretexting.png",
    },
    {
      sr: 13,
      title: "Business Email Compromise",
      imgUrl: "21.+Business+Email+Compromise.png",
    },
    {
      sr: 14,
      title: "Voice Activated Devices",
      imgUrl: "22.+Voice-Activated+Devices.png",
    },
    {
      sr: 15,
      title: "Passwords",
      imgUrl: "23.+Passwords.png",
    },
    {
      sr: 16,
      title: "Tailgating",
      imgUrl: "26.+Tailgating.png",
    },
    {
      sr: 17,
      title: "Malicious Attachments",
      imgUrl: "27.+Malicious+Attachments.png",
    },
    {
      sr: 18,
      title: "Location Sharing",
      imgUrl: "28.+Location+Sharing.png",
    },
    {
      sr: 19,
      title: "CEO Fraud",
      imgUrl: "29.+CEO+Fraud+(1).png",
    },
    {
      sr: 20,
      title: "Introduction to Ransomware",
      imgUrl: "3.+Introduction+to+Ransomware+(1).png",
    },
    {
      sr: 21,
      title: "Data Breaches",
      imgUrl: "30.+Data+Breaches.png",
    },
    {
      sr: 22,
      title: "Mobile Devices",
      imgUrl: "31.+Mobile+Devices+(1).png",
    },
    {
      sr: 23,
      title: "External Drives",
      imgUrl: "32.+External+Drives+(1).png",
    },
    {
      sr: 24,
      title: "Software Updates",
      imgUrl: "33.+Software+Updates.png",
    },
    {
      sr: 25,
      title: "Smishing",
      imgUrl: "34.+Smishing.png",
    },
    {
      sr: 26,
      title: "Deep Fake Scams",
      imgUrl: "39.+Deep+Fake+Scams.png",
    },
    {
      sr: 27,
      title: "Intro to Social Engineering",
      imgUrl: "4.+Intro+to+Social+Engineering.png",
    },
    {
      sr: 28,
      title: "Public Wifi",
      imgUrl: "5.+Public+Wifi.png",
    },
    {
      sr: 29,
      title: "Essential Cybersecurity Habits for Employees",
      imgUrl: "6+Essential+Cybersecurity+Habits+for+Employees+(1).png",
    },
    {
      sr: 30,
      title: "Email Spoofing",
      imgUrl: "6.+Email+Spoofing.png",
    },
    {
      sr: 31,
      title: "Safe Web Browsing",
      imgUrl: "7.+Safe+Web+Browsing+.png",
    },
    {
      sr: 32,
      title: "Working Remotely",
      imgUrl: "8.+Working+Remotely.png",
    },
    {
      sr: 32,
      title: "How to Catch a Vishing Attempt",
      imgUrl: "How+to+Catch+a+Vishing+Attempt+(1).png",
    },
    {
      sr: 33,
      title: "Spear Phishing",
      imgUrl: "35.+Spear+Phishing+(1).png",
    },
    {
      sr: 34,
      title: "Callback Phishing",
      imgUrl: "36.+Callback+Phishing+(1).png",
    },
    {
      sr: 35,
      title: "Email Chain Attacks",
      imgUrl: "37.+Email+Chain+Attacks.png",
    },
    {
      sr: 36,
      title: "Insider Threat Risk",
      imgUrl: "38.+Insider+Threat+Risk+(1).png",
    },
  ];

  return (
    <div className="flex justify-center items-center h-full pt-5 text-white max-w-78.75 mx-auto">
      <div className="grid grid-cols-1 md:grid-cols-3">
        {infographics.map((infographic) => (
          <div
            key={infographic.sr}
            className="p-3 bg-gray-800 rounded-md shadow-md cursor-pointer"
            onClick={() => openModal(infographic)}
          >
            <div className="flex justify-center items-center overflow-hidden">
              <img
                src={`${baseURL}${infographic.imgUrl}`}
                alt={infographic.title}
                className="w-40 h-40 object-cover transform transition-transform duration-300 ease-in-out hover:scale-110"
              />
            </div>
            <div className="w-full mx-auto text-h4 hover:text-gray transform ease-in duration-300 cursor-pointer mb-2 mt-1 text-center items-center">
              {infographic.title}
            </div>
          </div>
        ))}
      </div>

      {isOpen && selectedInfographic && (
        <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center w-full h-auto mx-auto z-auto">
          <div className="bg-black rounded-small relative py-4 md:py-0 mt-6">
            <button
              className="absolute top-0 md:top-2 right-2 md:right-10 text-white text-3xl hover:text-gray-400"
              onClick={closeModal}
            >
              &times;
            </button>

            <div className="overflow-hidden">
              <img
                src={`${baseURL}${selectedInfographic.imgUrl}`}
                alt={selectedInfographic.title}
                className="w-78.75 md:w-60 max-h-screen object-contain pt-2"
              />
            </div>

            <div className="flex justify-center">
              <button
                onClick={downloadImage}
                className="flex items-center justify-center bg-dune-green-100 text-white text-h4 mt-1 mb-2 font-semibold rounded-lg hover:bg-dune-green transition-all duration-300 ease-in-out"
              >
                <span className="pr-1">
                  <DownloadIcon />
                </span>
                Download Image
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResourceInfographics;
