import React from "react";

const VerifiedUserOutlinedIcon = () => {
  return (
    <div className="mr-0 md:mr-0.5">
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.2593 1L3.25928 5V11C3.25928 16.55 7.09928 21.74 12.2593 23C17.4193 21.74 21.2593 16.55 21.2593 11V5L12.2593 1ZM19.2593 11C19.2593 15.52 16.2793 19.69 12.2593 20.93C8.23928 19.69 5.25928 15.52 5.25928 11V6.3L12.2593 3.19L19.2593 6.3V11ZM7.66928 11.59L6.25928 13L10.2593 17L18.2593 9L16.8493 7.58L10.2593 14.17L7.66928 11.59Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default VerifiedUserOutlinedIcon;
