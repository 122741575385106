import React, { useCallback, useEffect, useState } from "react";

import Papa from "papaparse";

import {
  getDepartments,
  getInsightsOrganizationAreasToImprove,
  getInsightsOrganizationComplianceFrameworks,
  getInsightsOrganizationHighestRiskUsers,
  getInsightsOrganizationIndustryRiskScore,
  getInsightsOrganizationLowestRiskUsers,
  getInsightsOrganizationRiskScore,
  getInteractionsOrganization,
  getSimulationTrackerOrganization,
  getTestHTMLSimulationSchedule,
  getTrainingCompletion,
  getUsers,
  getUsersByInteraction,
} from "../../../api";

import { Dashboard } from "../../../components";

import { pagesPlatform } from "../../../constants";

/**
 * Component for displaying organization-level insights.
 * Fetches relevant insights data of an organization.
 */
function InsightsOrganization({
  isInitialLoading,
  email,
  setIsPlatformLoading,
}) {
  // This is for the simulation schedule card.
  const getTodayFormattedDate = () => {
    const today = new Date();
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const day = today.getDate().toString().padStart(2, "0");
    const year = today.getFullYear();
    return `${month}-${day}-${year}`;
  };

  // Insights
  const [riskScore, setRiskScore] = useState(null);
  const [comparedRiskScore, setComparedRiskScore] = useState(null);
  const [scoreChange, setScoreChange] = useState(null);
  const [riskScoreOverTime, setRiskScoreOverTime] = useState(null);
  const [areasToImprove, setAreasToImprove] = useState(null);
  const [interactions, setInteractions] = useState({
    totalInteractions: 0,
    totalInteractionsClicked: 0,
    totalInteractionsResponded: 0,
    totalInteractionsKeyDown: 0,
    totalInteractionsDataEntered: 0,
    totalInteractionsNoEngagement: 0,
    totalInteractionsReported: 0,
    totalInteractionsQRCodesScanned: 0,
    totalInteractionsDownloadAttachments: 0,
    totalInteractionsMFAEntered: 0,
  });
  const [usersInteraction, setUsersInteraction] = useState(null);
  const [complianceFrameworks, setComplianceFrameworks] = useState(null);
  const [simulationAssets, setSimulationAssets] = useState(null);
  const [highestRiskUsers, setHighestRiskUsers] = useState(null);
  const [lowestRiskUsers, setLowestRiskUsers] = useState(null);
  const [departments, setDepartments] = useState(null);
  const [
    selectedDateForSimulationTracker,
    setSelectedDateForSimulationTracker,
  ] = useState(getTodayFormattedDate());
  const [testHTMLSimulationSchedule, setTestHTMLSimulationSchedule] =
    useState("");
  const [trainingCompletion, setTrainingCompletion] = useState(null);
  const [trainingCompletionUsers, setTrainingCompletionUsers] = useState(null);

  // Cache
  const [riskScoreOverTimeCache, setRiskScoreOverTimeCache] = useState({});
  const [interactionsCache, setInteractionsCache] = useState({});
  const [usersCache, setUsersCache] = useState({});

  const handleChangeSelectedDateForSimulationTracker = async (date) => {
    setSelectedDateForSimulationTracker(date);
  };

  const handleChangeSelectedDays = async (days) => {
    setIsPlatformLoading(true);

    // For risk score over time and interactions cards
    if (riskScoreOverTimeCache[days]) {
      setRiskScoreOverTime(riskScoreOverTimeCache[days].riskScoreOverTime);
      setScoreChange(riskScoreOverTimeCache[days].scoreChange);
    } else if (
      days !== "All Time" &&
      riskScoreOverTimeCache[90] &&
      riskScoreOverTimeCache[90].riskScoreOverTime
    ) {
      // For day numbers, use the 90 days data and filter it.
      const today = new Date();

      const cutOffDate = new Date();
      cutOffDate.setDate(today.getDate() - days);

      const filteredRiskScoreOverTime =
        riskScoreOverTimeCache[90].riskScoreOverTime.filter((element) => {
          const [month, day] = element.date.split(" ");
          let year = today.getFullYear();
          const dateForYearCheck = new Date(`${month} ${day}, ${year}`);

          if (dateForYearCheck > today) {
            year--;
          }

          const date = new Date(`${month} ${day}, ${year}`);

          return date >= cutOffDate;
        });

      setRiskScoreOverTime(filteredRiskScoreOverTime);

      let scoreChange = 0;

      if (filteredRiskScoreOverTime[0]) {
        scoreChange = riskScore - filteredRiskScoreOverTime[0].riskScore;
      }

      setScoreChange(scoreChange);

      // Store the risk score over time data in the cache.
      updateRiskScoreOverTimeCache(
        days,
        filteredRiskScoreOverTime,
        scoreChange
      );
    } else {
      await loadRiskScore(days);
    }

    if (interactionsCache[days]) {
      const { interactions } = interactionsCache[days];

      setInteractions(interactions);
    } else {
      await loadInteractions(days);
    }

    setIsPlatformLoading(false);
  };

  const handleClickInteraction = async (interaction, days) => {
    setIsPlatformLoading(true);

    // For interactions card
    if (email !== "david@dune.demo") {
      setUsersInteraction(null);

      if (usersCache[interaction] && usersCache[interaction][days]) {
        const { users } = usersCache[interaction][days];

        setUsersInteraction(users);
      } else {
        let usersData = {};

        if (days === "All Time") {
          usersData = await getUsersByInteraction(interaction);
        } else {
          usersData = await getUsersByInteraction(interaction, days);
        }

        if (Object.keys(usersData.error).length > 0) {
          console.error(usersData.error.message);
        } else {
          const { users } = usersData.result;

          setUsersInteraction(users);

          // Store the users data in the cache.
          const updatedUsersCache = { ...usersCache };

          let updatedUsersCacheInteraction = {};

          if (updatedUsersCache[interaction]) {
            updatedUsersCacheInteraction = {
              ...updatedUsersCache[interaction],
            };
          }

          updatedUsersCacheInteraction[days] = {
            users,
          };

          updatedUsersCache[interaction] = updatedUsersCacheInteraction;

          setUsersCache(updatedUsersCache);
        }
      }
    }

    setIsPlatformLoading(false);
  };

  const handleClickViewTestAsset = async (assetFileName) => {
    setIsPlatformLoading(true);
    await loadTestHTMLSimulationSchedule(assetFileName);
    setIsPlatformLoading(false);
  };

  const handleClickShareReport = async () => {
    // For share report button
    const usersData = await getUsers();

    if (Object.keys(usersData.error).length > 0) {
      console.error(usersData.error.message);
    } else {
      const users = usersData.result;
      const csv = Papa.unparse(
        users.map(({ compliant, complianceFrameworks, ...fields }) => {
          const row = { ...fields };

          if (
            complianceFrameworks &&
            typeof complianceFrameworks === "object" &&
            Object.keys(complianceFrameworks).length > 0
          ) {
            Object.entries(complianceFrameworks).forEach(([key, value]) => {
              row[key] = value !== null ? (value ? "yes" : "no") : "N/A";
            });
          }

          return row;
        })
      );

      const blob = new Blob([csv], {
        type: "text/csv;charset=utf-8;",
      });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "organization-report.csv";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const updateRiskScoreOverTimeCache = (
    days,
    riskScoreOverTime,
    scoreChange
  ) => {
    setRiskScoreOverTimeCache((prevCache) => {
      const updatedRiskScoreOverTimeCache = { ...prevCache };

      if (days === "All Time") {
        updatedRiskScoreOverTimeCache["All Time"] = {
          riskScoreOverTime,
          scoreChange,
        };
      } else {
        updatedRiskScoreOverTimeCache[days] = {
          riskScoreOverTime,
          scoreChange,
        };
      }

      return updatedRiskScoreOverTimeCache;
    });
  };

  const loadRiskScore = async (days) => {
    if (riskScoreOverTimeCache[days]) {
      // Check the cache first.
      setRiskScoreOverTime(riskScoreOverTimeCache[days].riskScoreOverTime);
      setScoreChange(riskScoreOverTimeCache[days].scoreChange);
    } else {
      const insightsOrganizationRiskScore =
        await getInsightsOrganizationRiskScore(
          days && days !== "All Time" ? days : null
        );

      if (Object.keys(insightsOrganizationRiskScore.error).length > 0) {
        console.error(insightsOrganizationRiskScore.error.message);
      } else {
        const { riskScore, riskScoreOverTime, scoreChange } =
          insightsOrganizationRiskScore.result;

        setRiskScore(riskScore);
        setRiskScoreOverTime(riskScoreOverTime);
        setScoreChange(scoreChange);

        // Store the risk score over time and score change data in the cache.
        updateRiskScoreOverTimeCache(days, riskScoreOverTime, scoreChange);
      }
    }
  };

  const loadInteractions = async (days) => {
    if (interactionsCache[days]) {
      // Check the cache first.
      setInteractions(interactionsCache[days]);
    } else {
      const interactionsOrganization = await getInteractionsOrganization(
        days && days !== "All Time" ? days : null
      );

      if (Object.keys(interactionsOrganization.error).length > 0) {
        console.error(interactionsOrganization.error.message);
      } else {
        const {
          totalInteractions,
          totalInteractionsResponded,
          totalInteractionsClicked,
          totalInteractionsKeyDown,
          totalInteractionsDataEntered,
          totalInteractionsNoEngagement,
          totalInteractionsReported,
          totalInteractionsMFAEntered,
        } = interactionsOrganization.result;

        const interactions = {
          totalInteractions,
          totalInteractionsResponded,
          totalInteractionsClicked,
          totalInteractionsKeyDown,
          totalInteractionsDataEntered,
          totalInteractionsNoEngagement,
          totalInteractionsReported,
          totalInteractionsMFAEntered,
        };

        setInteractions(interactions);

        // Store the interactions data in the cache.
        const updatedInteractionsCache = { ...interactionsCache };

        if (days === "All Time") {
          updatedInteractionsCache["All Time"] = {
            interactions,
          };
        } else {
          updatedInteractionsCache[days] = {
            interactions,
          };
        }

        setInteractionsCache(updatedInteractionsCache);
      }
    }
  };

  const loadSimulationTracker = useCallback(async () => {
    const simulationAssetsOrganization = await getSimulationTrackerOrganization(
      selectedDateForSimulationTracker
    );

    if (Object.keys(simulationAssetsOrganization.error).length > 0) {
      console.error(simulationAssetsOrganization.error.message);
    } else {
      const { simulationAssets } = simulationAssetsOrganization.result;

      setSimulationAssets(simulationAssets);
    }
  }, [selectedDateForSimulationTracker]);

  const loadTestHTMLSimulationSchedule = async (assetFileName) => {
    const testHTMLSimulationSchedule = await getTestHTMLSimulationSchedule(
      assetFileName
    );

    if (Object.keys(testHTMLSimulationSchedule.error).length > 0) {
      console.error(testHTMLSimulationSchedule.error.message);
    } else {
      const { testHTML } = testHTMLSimulationSchedule.result;

      setTestHTMLSimulationSchedule(testHTML);
    }
  };

  const loadTrainingCompletion = async () => {
    const trainingCompletionStatuses = await getTrainingCompletion();

    if (Object.keys(trainingCompletionStatuses.error).length > 0) {
      console.error(trainingCompletionStatuses.error.message);
    } else {
      const { trainingCompletion, users } = trainingCompletionStatuses.result;

      setTrainingCompletion(trainingCompletion);
      setTrainingCompletionUsers(users);
    }
  };

  // For demo account only
  const loadTrainingCompletionUsersForDemo = async (trainingCompletion) => {
    const usersData = await getUsers();

    if (Object.keys(usersData.error).length > 0) {
      console.error(usersData.error.message);
    } else {
      const users = usersData.result;

      // Remove double occurrence of Duncan Idaho demo account.
      if (email === "david@dune.demo") {
        // Demo account
        const usersDemoEndUser = users.filter(
          (user) => user.email === "duncan.idaho@dune.demo"
        );
        const usersDemoManager = users.filter(
          (user) => user.email === "aaron.chavez@dune.demo"
        );
        let updatedUsers = users.filter(
          (user) =>
            user.email !== "duncan.idaho@dune.demo" &&
            user.email !== "aaron.chavez@dune.demo"
        );

        if (usersDemoEndUser.length > 0) {
          usersDemoEndUser[0].riskScore = 97;
          usersDemoEndUser[0].compliant = false;

          updatedUsers = [...updatedUsers, usersDemoEndUser[0]];
        }

        if (usersDemoManager.length > 0) {
          usersDemoManager[0].riskScore = 14;
          usersDemoManager[0].compliant = true;
          usersDemoManager[0].role = "Chief Financial Officer";

          updatedUsers = [...updatedUsers, usersDemoManager[0]];
        } else {
          updatedUsers = [
            {
              firstName: "Aaron",
              lastName: "Chavez",
              initials: "AC",
              email: "aaron.chavez@dune.demo",
              department: "Finance",
              role: "Chief Financial Officer",
              compliant: true,
              complianceFrameworks: [],
              riskScore: 14,
            },
            ...updatedUsers,
          ];
        }

        // Add more servere users.
        updatedUsers = [
          {
            firstName: "Alex",
            lastName: "Rivera",
            initials: "AR",
            email: "alex.rivera@dune.demo",
            department: "Program",
            role: "Program Analyst",
            compliant: false,
            complianceFrameworks: [],
            riskScore: 76,
          },
          {
            firstName: "Jack",
            lastName: "Turner",
            initials: "JT",
            email: "jack.turner@dune.demo",
            department: "Technology",
            role: "Senior Software Engineer",
            compliant: false,
            complianceFrameworks: [],
            riskScore: 78,
          },
          {
            firstName: "Logan",
            lastName: "Murphy",
            initials: "LM",
            email: "logan.murphy@dune.demo",
            department: "Program",
            role: "Program Analyst",
            compliant: false,
            complianceFrameworks: [],
            riskScore: 79,
          },
          {
            firstName: "Casey",
            lastName: "Lee",
            initials: "CL",
            email: "casey.lee@dune.demo",
            department: "Investment",
            role: "Intern",
            compliant: false,
            complianceFrameworks: [],
            riskScore: 80,
          },
          {
            firstName: "Sam",
            lastName: "Jordan",
            initials: "SJ",
            email: "sam.jordan@dune.demo",
            department: "Program",
            role: "Program Analyst",
            compliant: false,
            complianceFrameworks: [],
            riskScore: 81,
          },
          {
            firstName: "Mason",
            lastName: "Wright",
            initials: "MW",
            email: "mason.wright@dune.demo",
            department: "Investment",
            role: "Program & Community Analyst",
            compliant: false,
            complianceFrameworks: [],
            riskScore: 83,
          },
          {
            firstName: "Avery",
            lastName: "Brooks",
            initials: "AB",
            email: "avery.brooks@dune.demo",
            department: "Investment",
            role: "Program & Community Analyst",
            compliant: false,
            complianceFrameworks: [],
            riskScore: 90,
          },
          ...updatedUsers,
        ];

        // Identify the specific user
        const specificUserEmail = "duncan.idaho@dune.demo";
        const specificUser = updatedUsers.find(
          (user) => user.email === specificUserEmail
        );
        const otherUsers = updatedUsers.filter(
          (user) => user.email !== specificUserEmail
        );

        // Calculate the number of users for each status
        const numUsers = updatedUsers.length;
        const numCompleted = Math.round(
          trainingCompletion.Completed * (numUsers - 1)
        ); // Exclude specific user, Duncan Idaho
        const numInProgress = Math.round(
          trainingCompletion["In Progress"] * (numUsers - 1)
        );

        // Assign statuses to remaining users
        const usersWithStatus = otherUsers.map((user, index) => {
          if (index < numCompleted) {
            return { ...user, trainingStatus: "Completed" };
          } else if (index < numCompleted + numInProgress) {
            return { ...user, trainingStatus: "In Progress" };
          } else {
            return { ...user, trainingStatus: "Not Started" };
          }
        });

        // Add the specific user with the "Not Started" status
        const finalUsersWithStatus = [
          ...usersWithStatus,
          { ...specificUser, trainingStatus: "Not Started" },
        ];

        setTrainingCompletionUsers(finalUsersWithStatus);
      }
    }
  };

  useEffect(() => {
    const loadIndustryRiskScore = async () => {
      const insightsOrganizationIndustryRiskScore =
        await getInsightsOrganizationIndustryRiskScore();

      if (Object.keys(insightsOrganizationIndustryRiskScore.error).length > 0) {
        console.error(insightsOrganizationIndustryRiskScore.error.message);
      } else {
        const { industryRiskScore } =
          insightsOrganizationIndustryRiskScore.result;

        setComparedRiskScore(industryRiskScore);
      }
    };

    const loadAreasToImprove = async () => {
      const insightsOrganizationAreasToImprove =
        await getInsightsOrganizationAreasToImprove();

      if (Object.keys(insightsOrganizationAreasToImprove.error).length > 0) {
        console.error(insightsOrganizationAreasToImprove.error.message);
      } else {
        const { areasToImprove } = insightsOrganizationAreasToImprove.result;

        let fetchedAreasToImprove = areasToImprove;

        setAreasToImprove(fetchedAreasToImprove);
      }
    };

    const loadComplianceFrameworks = async () => {
      const insightsOrganizationComplianceFrameworks =
        await getInsightsOrganizationComplianceFrameworks();

      if (
        Object.keys(insightsOrganizationComplianceFrameworks.error).length > 0
      ) {
        console.error(insightsOrganizationComplianceFrameworks.error.message);
      } else {
        const { complianceFrameworks } =
          insightsOrganizationComplianceFrameworks.result;

        setComplianceFrameworks(complianceFrameworks);
      }
    };

    const loadHighestRiskUsers = async () => {
      const insightsOrganizationHighestRiskUsers =
        await getInsightsOrganizationHighestRiskUsers();

      if (Object.keys(insightsOrganizationHighestRiskUsers.error).length > 0) {
        console.error(insightsOrganizationHighestRiskUsers.error.message);
      } else {
        const { users } = insightsOrganizationHighestRiskUsers.result;

        setHighestRiskUsers(users);
      }
    };

    const loadLowestRiskUsers = async () => {
      const insightsOrganizationLowestRiskUsers =
        await getInsightsOrganizationLowestRiskUsers();

      if (Object.keys(insightsOrganizationLowestRiskUsers.error).length > 0) {
        console.error(insightsOrganizationLowestRiskUsers.error.message);
      } else {
        const { users } = insightsOrganizationLowestRiskUsers.result;

        setLowestRiskUsers(users);
      }
    };

    const loadDepartments = async () => {
      const departmentsData = await getDepartments();

      if (Object.keys(departmentsData.error).length > 0) {
        console.error(departmentsData.error.message);
      } else {
        const { departments } = departmentsData.result;

        setDepartments(departments);
      }
    };

    const loadInsightsOrganization = async () => {
      setIsPlatformLoading(true);

      if (email === "david@dune.demo") {
        // Demo account
        setRiskScore(46);

        setComparedRiskScore(60);

        let riskScoreOverTime = [];

        const currentDate = new Date(); // Start with today's date.
        currentDate.setDate(currentDate.getDate() - 90);

        let riskScore = 90;
        const targetScore = 46;
        const totalDays = 90;

        const options = {
          month: "short",
          day: "numeric",
        };

        for (let i = 0; i < totalDays; i++) {
          const formattedDate = currentDate.toLocaleDateString(
            "en-US",
            options
          );

          let stepChange = (riskScore - targetScore) / (totalDays - i);

          let variability = (Math.random() - 0.5) * stepChange;

          let fluctuation =
            Math.sin(((2 * Math.PI) / totalDays) * i) * stepChange;

          riskScore -= stepChange + variability + fluctuation;

          if (i === totalDays - 1 && riskScore > targetScore) {
            riskScore = targetScore;
          } else if (riskScore < targetScore) {
            riskScore = targetScore + Math.random();
          }

          riskScoreOverTime.push({
            date: formattedDate,
            riskScore: Math.round(riskScore),
          });

          currentDate.setDate(currentDate.getDate() + 1);
        }

        setRiskScoreOverTime(riskScoreOverTime);

        let scoreChange = 0;

        if (riskScoreOverTime[0]) {
          scoreChange = riskScore - riskScoreOverTime[0].riskScore;
        }

        setScoreChange(scoreChange);

        // Store the risk score over time data and score changein the cache (for both 90 days and all time).
        updateRiskScoreOverTimeCache(90, riskScoreOverTime, scoreChange);
        updateRiskScoreOverTimeCache(
          "All Time",
          riskScoreOverTime,
          scoreChange
        );

        setAreasToImprove([
          {
            name: "C-suite impersonation",
          },
          {
            name: "Impersonation of internal processes",
          },
          {
            name: "Link manipulation",
          },
        ]);
        setComplianceFrameworks([
          {
            name: "SOC 2 Type 2",
            progress: 0.92,
          },
          {
            name: "HIPAA",
            progress: 0.94,
          },
          {
            name: "ISO 27001",
            progress: 0.91,
          },
        ]);

        const interactions = {
          totalInteractions: 10483,
          totalInteractionsResponded: 150,
          totalInteractionsClicked: 2735,
          totalInteractionsKeyDown: 302,
          totalInteractionsDataEntered: 44,
          totalInteractionsNoEngagement: 5577,
          totalInteractionsReported: 2021,
          totalInteractionsMFAEntered: 26,
          totalInteractionsQRCodesScanned: 19,
          totalInteractionsDownloadAttachments: 9,
        };
        setInteractions(interactions);
        setInteractionsCache({
          30: { interactions },
          60: { interactions },
          90: { interactions },
          "All Time": { interactions },
        });

        const usersInteraction = [
          {
            firstName: "Duncan",
            lastName: "Idaho",
            initials: "DI",
            email: "duncan.idaho@dune.demo",
            department: "Capital",
            compliant: false,
            riskScore: 97,
            frequency: 2,
          },
          {
            firstName: "Stefanie",
            lastName: "Gonzalez",
            initials: "SG",
            email: "stefanie.gonzalez@dune.demo",
            department: "Technology",
            compliant: true,
            riskScore: 84,
            frequency: 1,
          },
          {
            firstName: "David",
            lastName: "Hurst",
            initials: "DH",
            email: "david.hurst@dune.demo",
            department: "Early-Stage Fund Management",
            compliant: true,
            riskScore: 79,
            frequency: 1,
          },
          {
            firstName: "David",
            lastName: "Sparks",
            initials: "DS",
            email: "david.sparks@dune.demo",
            department: "Portfolio",
            compliant: true,
            riskScore: 56,
            frequency: 1,
          },
          {
            firstName: "Eric",
            lastName: "Guzman",
            initials: "EG",
            email: "eric.guzman@dune.demo",
            department: "Program",
            compliant: true,
            riskScore: 51,
            frequency: 1,
          },
          {
            firstName: "Andrew",
            lastName: "Villegas",
            initials: "AV",
            email: "andrew.villegas@dune.demo",
            department: "Program",
            compliant: true,
            riskScore: 35,
            frequency: 1,
          },
          {
            firstName: "Thomas",
            lastName: "Lloyd",
            initials: "TL",
            email: "thomas.lloyd@dune.demo",
            department: "Operations",
            compliant: true,
            riskScore: 68,
            frequency: 1,
          },
          {
            firstName: "Dawn",
            lastName: "Thomas",
            initials: "DT",
            email: "dawn.thomas@dune.demo",
            department: "Founder Scouting",
            compliant: true,
            riskScore: 49,
            frequency: 1,
          },
          {
            firstName: "Connor",
            lastName: "Schultz",
            initials: "CS",
            email: "connor.schultz@dune.demo",
            department: "Program",
            compliant: true,
            riskScore: 57,
            frequency: 1,
          },
          {
            firstName: "John",
            lastName: "Harrell",
            initials: "JH",
            email: "john.harrell@dune.demo",
            department: "Capital",
            compliant: true,
            riskScore: 74,
            frequency: 1,
          },
        ];
        setUsersInteraction(usersInteraction);

        setHighestRiskUsers([
          {
            firstName: "Duncan",
            lastName: "Idaho",
            initials: "DI",
            email: "duncan.idaho@dune.demo",
            riskScore: 97,
          },
          {
            firstName: "Avery",
            lastName: "Brooks",
            initials: "AB",
            email: "avery.brooks@dune.demo",
            riskScore: 90,
          },
          {
            firstName: "Stefanie",
            lastName: "Gonzalez",
            initials: "SG",
            email: "stefanie.gonzalez@dune.demo",
            riskScore: 84,
          },
          {
            firstName: "Mason",
            lastName: "Wright",
            initials: "MW",
            email: "mason.wright@dune.demo",
            riskScore: 83,
          },
          {
            firstName: "Sam",
            lastName: "Jordan",
            initials: "SJ",
            email: "sam.jordan@dune.demo",
            riskScore: 81,
          },
          {
            firstName: "Casey",
            lastName: "Lee",
            initials: "CL",
            email: "casey.lee@dune.demo",
            riskScore: 80,
          },
        ]);

        setLowestRiskUsers([
          {
            firstName: "Amy",
            lastName: "Hernandez",
            initials: "AH",
            email: "amy.hernandez@dune.demo",
            riskScore: 1,
          },
          {
            firstName: "William",
            lastName: "Sparks",
            initials: "WS",
            email: "william.sparks@dune.demo",
            riskScore: 1,
          },
          {
            firstName: "Brittany",
            lastName: "Larsen",
            initials: "BL",
            email: "brittany.larsen@dune.demo",
            riskScore: 1,
          },
          {
            firstName: "James",
            lastName: "Richards",
            initials: "JR",
            email: "james.richards@dune.demo",
            riskScore: 1,
          },
          {
            firstName: "Justin",
            lastName: "Steele",
            initials: "JS",
            email: "justin.steele@dune.demo",
            riskScore: 3,
          },
          {
            firstName: "Billy",
            lastName: "Snyder",
            initials: "BS",
            email: "billy.snyder@dune.demo",
            riskScore: 3,
          },
        ]);

        setDepartments([
          {
            name: "Capital",
            riskScore: 61,
          },
          {
            name: "Engineering",
            riskScore: 26,
          },
          {
            name: "Global Support",
            riskScore: 9,
          },
          {
            name: "Information Technology",
            riskScore: 43,
          },
          {
            name: "Investment",
            riskScore: 36,
          },
          {
            name: "Legal",
            riskScore: 52,
          },
          {
            name: "Marketing & Communications",
            riskScore: 8,
          },
          {
            name: "Portfolio",
            riskScore: 9,
          },
          {
            name: "Program",
            riskScore: 15,
          },
        ]);

        const trainingCompletion = {
          Completed: 0.8643,
          "In Progress": 0.1041,
          "Not Started": 0.0316,
        };

        setTrainingCompletion(trainingCompletion);
        await loadTrainingCompletionUsersForDemo(trainingCompletion);
      } else {
        loadRiskScore(90); // Default day value is 90.
        loadIndustryRiskScore();
        loadAreasToImprove();
        loadComplianceFrameworks();
        loadHighestRiskUsers();
        loadLowestRiskUsers();
        loadDepartments();
        loadInteractions(90); // Default day value is 90.
        await loadTrainingCompletion();
      }

      setIsPlatformLoading(false);
    };

    if (!isInitialLoading && email) {
      loadInsightsOrganization();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialLoading, email, setIsPlatformLoading]);

  useEffect(() => {
    const updateComponent = async () => {
      setIsPlatformLoading(true);

      if (email === "david@dune.demo") {
        const simulationAssets = [
          {
            assetName: "Email Vulnerabilities",
            assetFileName: "email-vulnerabilities.html",
            recipients: [
              {
                firstName: "Duncan",
                lastName: "Idaho",
                initials: "DI",
                email: "duncan.idaho@dune.demo",
                riskScore: 97,
              },
              {
                firstName: "Avery",
                lastName: "Brooks",
                initials: "AB",
                email: "avery.brooks@dune.demo",
                riskScore: 90,
              },
              {
                firstName: "Stefanie",
                lastName: "Gonzalez",
                initials: "SG",
                email: "stefanie.gonzalez@dune.demo",
                riskScore: 84,
              },
              {
                firstName: "Mason",
                lastName: "Wright",
                initials: "MW",
                email: "mason.wright@dune.demo",
                riskScore: 83,
              },
            ],
            attackDepth: "Credentials",
            method: "Link Manipulation",
            geography: "🌎 International",
          },
          {
            assetName: "Paypal Receipt From Best Buy",
            assetFileName: "paypal-receipt-from-best-buy.html",
            recipients: [
              {
                firstName: "Sam",
                lastName: "Jordan",
                initials: "SJ",
                email: "sam.jordan@dune.demo",
                riskScore: 81,
              },
              {
                firstName: "Casey",
                lastName: "Lee",
                initials: "CL",
                email: "casey.lee@dune.demo",
                riskScore: 80,
              },
              {
                firstName: "Lorgan",
                lastName: "Murphy",
                initials: "LM",
                email: "lorgan.murphy@dune.demo",
                riskScore: 79,
              },
              {
                firstName: "David",
                lastName: "Hurst",
                initials: "DH",
                email: "david.hurst@dune.demo",
                riskScore: 79,
              },
            ],
            attackDepth: "Credentials",
            method: "Deceptive Phishing",
            geography: "🇺🇸 United States",
          },
          {
            assetName: "Sharepoint Verify Identity",
            assetFileName: "sharepoint-verify-identity.html",
            recipients: [
              {
                firstName: "Jack",
                lastName: "Turner",
                initials: "JT",
                email: "jack.turner@dune.demo",
                riskScore: 78,
              },
              {
                firstName: "Alex",
                lastName: "Rivera",
                initials: "AR",
                email: "alex.rivera@dune.demo",
                riskScore: 76,
              },
            ],
            attackDepth: "Credentials",
            method: "Link Manipulation",
            geography: "🇺🇸 United States",
          },
          {
            assetName: "Calendly Touch Base With Employee",
            assetFileName: "calendly-touch-base-with-employee.html",
            recipients: [
              {
                firstName: "John",
                lastName: "Harrell",
                initials: "JH",
                email: "john.harrell@dune.demo",
                riskScore: 70,
              },
              {
                firstName: "Thomas",
                lastName: "Lloyd",
                initials: "TL",
                email: "thomas.lloyd@dune.demo",
                riskScore: 68,
              },
              {
                firstName: "Sheila",
                lastName: "Hughes",
                initials: "SH",
                email: "sheila.hughes@dune.demo",
                riskScore: 63,
              },
              {
                firstName: "David",
                lastName: "Peters",
                initials: "DP",
                email: "david.peters@dune.demo",
                riskScore: 62,
              },
              {
                firstName: "Carlos",
                lastName: "Castillo",
                initials: "CC",
                email: "carlos.castillo@dune.demo",
                riskScore: 60,
              },
            ],
            attackDepth: "Link",
            method: "Deceptive Phishing",
            geography: "🌎 International",
          },
          {
            assetName: "Code Of Conduct Acknowledgement Required",
            assetFileName: "code-of-conduct-acknowledgement-required.html",
            recipients: [
              {
                firstName: "Ashley",
                lastName: "Simpson",
                initials: "AS",
                email: "ashley.simpson@dune.demo",
                riskScore: 59,
              },
            ],
            attackDepth: "Link",
            method: "Link Manipulation",
            geography: "🌎 International",
          },
          {
            assetName: "Microsoft Account Password Reset Code",
            assetFileName: "microsoft-account-password-reset-code.html",
            recipients: [
              {
                firstName: "William",
                lastName: "Howard",
                initials: "WH",
                email: "william.howard@dune.demo",
                riskScore: 56,
              },
              {
                firstName: "Tanya",
                lastName: "Callahan",
                initials: "TC",
                email: "tanya.callahan@dune.demo",
                riskScore: 56,
              },
              {
                firstName: "Jonathan",
                lastName: "Mueller",
                initials: "JM",
                email: "jonathan.mueller@dune.demo",
                riskScore: 49,
              },
            ],
            attackDepth: "Credentials",
            method: "Deceptive Phishing",
            geography: "🌎 International",
          },
          {
            assetName: "Holiday Gifts From Ceo",
            assetFileName: "holiday-gifts-from-ceo.html",
            recipients: [
              {
                firstName: "William",
                lastName: "Howard",
                initials: "WH",
                email: "william.howard@dune.demo",
                riskScore: 56,
              },
              {
                firstName: "Tanya",
                lastName: "Callahan",
                initials: "TC",
                email: "tanya.callahan@dune.demo",
                riskScore: 56,
              },
              {
                firstName: "Jonathan",
                lastName: "Mueller",
                initials: "JM",
                email: "jonathan.mueller@dune.demo",
                riskScore: 49,
              },
            ],
            attackDepth: "Link",
            method: "Business Email Compromise (BEC)",
            geography: "🌎 International",
          },
          {
            assetName: "Wetransfer Meeting Recording",
            assetFileName: "wetransfer-meeting-recording.html",
            recipients: [
              {
                firstName: "Duncan",
                lastName: "Idaho",
                initials: "DI",
                email: "duncan.idaho@dune.demo",
                riskScore: 97,
              },
              {
                firstName: "Avery",
                lastName: "Brooks",
                initials: "AB",
                email: "avery.brooks@dune.demo",
                riskScore: 90,
              },
              {
                firstName: "Stefanie",
                lastName: "Gonzalez",
                initials: "SG",
                email: "stefanie.gonzalez@dune.demo",
                riskScore: 84,
              },
              {
                firstName: "Mason",
                lastName: "Wright",
                initials: "MW",
                email: "mason.wright@dune.demo",
                riskScore: 83,
              },
            ],
            attackDepth: "Credentials",
            method: "Link Manipulation",
            geography: "🌎 International",
          },
          {
            assetName: "Gitlab Access Was Granted",
            assetFileName: "gitlab-access-was-granted.html",
            recipients: [],
            attackDepth: "Credentials",
            method: "Business Email Compromise (BEC)",
            geography: "🌎 International",
          },
          {
            assetName: "Ringcentral Recent Missed Call",
            assetFileName: "ringcentral-recent-missed-call.html",
            recipients: [],
            attackDepth: "Link",
            method: "Deceptive Phishing",
            geography: "🇺🇸 United States",
          },
          {
            assetName: "Tax Preparation Services Invoice",
            assetFileName: "tax-preparation-services-invoice.html",
            recipients: [],
            attackDepth: "Credentials",
            method: "Deceptive Phishing",
            geography: "🇺🇸 United States",
          },
          {
            assetName: "Microsoft Single Use Code",
            assetFileName: "microsoft-single-use-code.html",
            recipients: [],
            attackDepth: "Credentials",
            method: "Deceptive Phishing",
            geography: "🌎 International",
          },
          {
            assetName: "Match Com Default Privacy Setting Change",
            assetFileName: "match-com-default-privacy-setting-change.html",
            recipients: [],
            attackDepth: "Link",
            method: "Deceptive Phishing",
            geography: "🌎 International",
          },
          {
            assetName: "Building Evacuation Plan Update",
            assetFileName: "building-evacuation-plan-update.html",
            recipients: [],
            attackDepth: "Credentials",
            method: "Link Manipulation",
            geography: "🌎 International",
          },
          {
            assetName: "I 9 Verification Requirement",
            assetFileName: "i-9-verification-requirement.html",
            recipients: [],
            attackDepth: "Credentials",
            method: "Deceptive Phishing",
            geography: "🇺🇸 United States",
          },
          {
            assetName: "Booking Com Free Benefits",
            assetFileName: "booking-com-free-benefits.html",
            recipients: [],
            attackDepth: "Credentials",
            method: "Link Manipulation",
            geography: "🌎 International",
          },
          {
            assetName: "Sharepoint New Contract Document",
            assetFileName: "sharepoint-new-contract-document.html",
            recipients: [],
            attackDepth: "Credentials",
            method: "Link Manipulation",
            geography: "🌎 International",
          },

          {
            assetName: "Welcome To The Company",
            assetFileName: "welcome-to-the-company.html",
            recipients: [],
            attackDepth: "Credentials",
            method: "Link Manipulation",
            geography: "🌎 International",
          },
          {
            assetName: "Microsoft Office 365 Voicemail",
            assetFileName: "microsoft-office-365-voicemail.html",
            recipients: [],
            attackDepth: "Credentials",
            method: "Deceptive Phishing",
            geography: "🌎 International",
          },
          {
            assetName: "Surveymonkey Complete Survey",
            assetFileName: "surveymonkey-complete-survey.html",
            recipients: [],
            attackDepth: "Credentials",
            method: "Deceptive Phishing",
            geography: "🌎 International",
          },
          {
            assetName: "Okta Password Reset Requested",
            assetFileName: "okta-password-reset-requested.html",
            recipients: [],
            attackDepth: "Credentials",
            method: "Link Manipulation",
            geography: "🌎 International",
          },
          {
            assetName: "Sharepoint Document Review",
            assetFileName: "sharepoint-document-review.html",
            recipients: [],
            attackDepth: "Credentials",
            method: "Link Manipulation",
            geography: "🇺🇸 United States",
          },
          {
            assetName: "Compensation And Benefits Survey",
            assetFileName: "compensation-and-benefits-survey.html",
            recipients: [],
            attackDepth: "Link",
            method: "Link Manipulation",
            geography: "🌎 International",
          },
          {
            assetName: "Microsoft New App Connected To Your Account",
            assetFileName: "microsoft-new-app-connected-to-your-account.html",
            recipients: [],
            attackDepth: "Credentials",
            method: "Deceptive Phishing",
            geography: "🌎 International",
          },
          {
            assetName: "Account Closure Request Is In Progress",
            assetFileName: "account-closure-request-is-in-progress.html",
            recipients: [],
            attackDepth: "Credentials",
            method: "Link Manipulation",
            geography: "🌎 International",
          },
          {
            assetName: "Contact Request From Consumer Site",
            assetFileName: "contact-request-from-consumer-site.html",
            recipients: [],
            attackDepth: "Link",
            method: "Deceptive Phishing",
            geography: "🇺🇸 United States",
          },
          {
            assetName: "Mercury Ach Payment",
            assetFileName: "mercury-ach-payment.html",
            recipients: [],
            attackDepth: "Credentials",
            method: "Link Manipulation",
            geography: "🌎 International",
          },
          {
            assetName: "Ups Update Package Scheduled For Today",
            assetFileName: "ups-update-package-scheduled-for-today.html",
            recipients: [],
            attackDepth: "Link",
            method: "Deceptive Phishing",
            geography: "🇺🇸 United States",
          },
          {
            assetName: "Egencia Survey With Starbucks Gift Card",
            assetFileName: "egencia-survey-with-starbucks-gift-card.html",
            recipients: [],
            attackDepth: "Link",
            method: "Pretexting",
            geography: "🇺🇸 United States",
          },
          {
            assetName: "Security Training Violation Notice",
            assetFileName: "security-training-violation-notice.html",
            recipients: [],
            attackDepth: "Credentials",
            method: "Link Manipulation",
            geography: "🌎 International",
          },
          {
            assetName: "Midjourney Exclusive Invitation",
            assetFileName: "midjourney-exclusive-invitation.html",
            recipients: [],
            attackDepth: "Credentials",
            method: "Clone Phishing",
            geography: "🌎 International",
          },
          {
            assetName: "Draft Kings Trending Bet Alert",
            assetFileName: "draft-kings-trending-bet-alert.html",
            recipients: [],
            attackDepth: "Credentials",
            method: "Deceptive Phishing",
            geography: "🇺🇸 United States",
          },
          {
            assetName: "Wells Fargo Customer Sent Money",
            assetFileName: "wells-fargo-customer-sent-money.html",
            recipients: [],
            attackDepth: "Credentials",
            method: "Link Manipulation",
            geography: "🇺🇸 United States",
          },
          {
            assetName: "New Project Assignment",
            assetFileName: "new-project-assignment.html",
            recipients: [],
            attackDepth: "Credentials",
            method: "Deceptive Phishing",
            geography: "🌎 International",
          },
          {
            assetName: "Prosapient Payment",
            assetFileName: "prosapient-payment.html",
            recipients: [],
            attackDepth: "Credentials",
            method: "Deceptive Phishing",
            geography: "🇺🇸 United States",
          },
          {
            assetName: "Stanford Ai Expert Today",
            assetFileName: "stanford-ai-expert-today.html",
            recipients: [],
            attackDepth: "Link",
            method: "Content Injection Phishing",
            geography: "🇺🇸 United States",
          },
          {
            assetName: "Ebay Survey With Visa Gift Card",
            assetFileName: "ebay-survey-with-visa-gift-card.html",
            recipients: [],
            attackDepth: "Credentials",
            method: "Link Manipulation",
            geography: "🌎 International",
          },
          {
            assetName: "Fastspring Subscription Payment",
            assetFileName: "fastspring-subscription-payment.html",
            recipients: [],
            attackDepth: "Credentials",
            method: "Deceptive Phishing",
            geography: "🌎 International",
          },
          {
            assetName: "Surveymonkey Paid Research Opportunity",
            assetFileName: "surveymonkey-paid-research-opportunity.html",
            recipients: [],
            attackDepth: "Credentials",
            method: "Deceptive Phishing",
            geography: "🇺🇸 United States",
          },
          {
            assetName: "Dhl Parcel Arrival Notice",
            assetFileName: "dhl-parcel-arrival-notice.html",
            recipients: [],
            attackDepth: "Link",
            method: "Business Email Compromise (BEC)",
            geography: "🌎 International",
          },
          {
            assetName: "Threads Free Verified",
            assetFileName: "threads-free-verified.html",
            recipients: [],
            attackDepth: "Link",
            method: "Deceptive Phishing",
            geography: "🌎 International",
          },
          {
            assetName: "Netflix Email Change",
            assetFileName: "netflix-email-change.html",
            recipients: [],
            attackDepth: "Credentials",
            method: "Deceptive Phishing",
            geography: "🌎 International",
          },
          {
            assetName: "Amazon Action Needed Sign In",
            assetFileName: "amazon-action-needed-sign-in.html",
            recipients: [],
            attackDepth: "Credentials",
            method: "Link Manipulation",
            geography: "🌎 International",
          },
        ];

        for (let i = 0; i < simulationAssets.length; i++) {
          if (
            !simulationAssets[i].recipients ||
            simulationAssets[i].recipients.length === 0
          ) {
            simulationAssets[i].recipients = [
              {
                firstName: "John",
                lastName: "Harrell",
                initials: "JH",
                email: "john.harrell@dune.demo",
                riskScore: 70,
              },
              {
                firstName: "Thomas",
                lastName: "Lloyd",
                initials: "TL",
                email: "thomas.lloyd@dune.demo",
                riskScore: 68,
              },
              {
                firstName: "Sheila",
                lastName: "Hughes",
                initials: "SH",
                email: "sheila.hughes@dune.demo",
                riskScore: 63,
              },
              {
                firstName: "David",
                lastName: "Peters",
                initials: "DP",
                email: "david.peters@dune.demo",
                riskScore: 62,
              },
              {
                firstName: "Carlos",
                lastName: "Castillo",
                initials: "CC",
                email: "carlos.castillo@dune.demo",
                riskScore: 60,
              },
            ];
          }

          setSimulationAssets(simulationAssets);
        }
      } else {
        await loadSimulationTracker();
      }

      setIsPlatformLoading(false);
    };

    if (!isInitialLoading && email) {
      updateComponent();
    }
  }, [
    selectedDateForSimulationTracker,
    loadSimulationTracker,
    isInitialLoading,
    email,
    setIsPlatformLoading,
  ]);

  return (
    <Dashboard
      pageTitle={pagesPlatform.INSIGHTS.subpages.ORGANIZATION}
      riskScore={riskScore}
      comparedRiskScore={comparedRiskScore}
      scoreChange={scoreChange}
      riskScoreOverTime={riskScoreOverTime}
      areasToImprove={areasToImprove}
      interactions={interactions}
      usersInteraction={usersInteraction}
      complianceFrameworks={complianceFrameworks}
      simulationAssets={simulationAssets}
      highestRiskUsers={highestRiskUsers}
      lowestRiskUsers={lowestRiskUsers}
      departments={departments}
      trainingCompletion={trainingCompletion}
      trainingCompletionUsers={trainingCompletionUsers}
      email={email}
      selectedDateForSimulationTracker={selectedDateForSimulationTracker}
      testHTMLSimulationSchedule={testHTMLSimulationSchedule}
      onChangeSelectedDateForSimulationTracker={
        handleChangeSelectedDateForSimulationTracker
      }
      onChangeSelectedDays={handleChangeSelectedDays}
      onClickShareReport={handleClickShareReport}
      onClickInteraction={handleClickInteraction}
      onClickViewTestAsset={handleClickViewTestAsset}
    />
  );
}

export default InsightsOrganization;
