import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Papa from "papaparse";

import {
  DownloadIcon,
  EyeIcon,
  Filters,
  Pagination,
  SearchIcon,
} from "./utils";

import { ButtonOutlinedGray, Card, RiskGauge } from "../";

import "./users-table.css";

function UsersTable({
  data,
  headers,
  defaultOrder = "asc",
  defaultOrderBy,
  defaultFilters,
  disableFilters,
  disablePagination,
  disableProfilePage,
  disableSearchBar,
  customFooter,
  customHeader,
  fixedTotal,
  onExportSelectedReports,
  rowsPerPage = 10,
}) {
  // Table data
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");

  // Selected data
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [selectedData, setSelectedData] = useState([]);

  // Filters
  const [selectedRiskLevel, setSelectedRiskLevel] = useState("");
  //   const [selectedCompliant, setSelectedCompliant] = useState("");
  const [selectedCompliant] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedComplianceFramework, setSelectedComplianceFramework] =
    useState("");
  const [departments, setDepartments] = useState([]);
  const [complianceFrameworks, setComplianceFrameworks] = useState([]);

  //   const tableRef = useRef(null);

  const descendingComparator = (a, b, orderBy) => {
    if (orderBy === "riskScore") {
      const aRisk = a[orderBy] === null || a[orderBy] === 0;
      const bRisk = b[orderBy] === null || b[orderBy] === 0;

      if (aRisk && !bRisk) {
        return 1;
      }

      if (!aRisk && bRisk) {
        return -1;
      }

      if (aRisk && bRisk) {
        return 0;
      }
    }

    if (b[orderBy] < a[orderBy]) {
      return -1;
    }

    if (b[orderBy] > a[orderBy]) {
      return 1;
    }

    return 0;
  };

  const downloadReport = async (data) => {
    const csv = Papa.unparse(
      data.map(({ compliant, complianceFrameworks, ...fields }) => {
        const row = { ...fields };

        return row;
      })
    );

    const blob = new Blob([csv], {
      type: "text/csv;charset=utf-8;",
    });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "report.csv";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getComparator = (order, orderBy) => {
    let updatedOrderBy = orderBy;

    if (updatedOrderBy === "name") {
      // Sort by first name if name is selected.
      updatedOrderBy = "firstName";
    }

    return order === "desc"
      ? (a, b) => descendingComparator(a, b, updatedOrderBy)
      : (a, b) => -descendingComparator(a, b, updatedOrderBy);
  };

  const handleChangeDepartment = (event) => {
    setSelectedDepartment(event.target.value);
    setPage(0);
  };

  const handleChangeRiskLevel = (event) => {
    setSelectedRiskLevel(event.target.value);
    setPage(0);
  };

  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
    setPage(0);
  };

  //   const handleChangePage = (event, newPage) => {
  //     setPage(newPage);
  //   };

  //   const handleChangeRowsPerPage = (event) => {
  //     tableRef.current && tableRef.current.scrollIntoView();

  //     setRowsPerPage(parseInt(event.target.value, 10));
  //     setPage(0);
  //   };

  const handleClickCheckbox = (user) => {
    const updatedSelectedData = [...selectedData];
    const index = selectedData.findIndex(
      (element) => element.email === user.email
    );

    if (index > -1) {
      updatedSelectedData.splice(index, 1);
    } else {
      updatedSelectedData.push(user);
    }

    setSelectedData([...updatedSelectedData]);
  };

  const handleClickCheckboxSelectAll = () => {
    if (selectedData.length === filteredRows.length) {
      setSelectedData([]);
    } else {
      setSelectedData([...filteredRows]);
    }
  };

  const handleClickExportSelectedReports = () => {
    if (selectedData && selectedData.length === 0) {
      downloadReport(filteredRows);
    } else {
      onExportSelectedReports(selectedData, "user-reports.csv");
    }
  };

  // const handleClickCheckboxSelectAll = () => {
  //   if (isSelectAll) {
  //     setIsSelectAll(false);
  //     setSelectedData(selectedData.filter((row) => !visibleRows.includes(row)));
  //   } else {
  //     setIsSelectAll(true);
  //     setSelectedData([...new Set([...selectedData, ...visibleRows])]);
  //   }
  // };

  const handleSortRequest = (property) => {
    if (orderBy !== property) {
      setPage(0);
    }

    const isAsc = orderBy === property && order === "asc";

    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);

    console.log(complianceFrameworks); // To delete
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);

      if (order !== 0) {
        return order;
      }

      return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
  };

  let filteredRows = [];

  if (search !== "") {
    for (let i = 0; i < data.length; i++) {
      let isMatch = false;

      for (let j = 0; j < headers.length; j++) {
        if (headers[j].isSearchable) {
          if (headers[j].id === "name") {
            if (
              data[i].name &&
              data[i].name.toLowerCase().indexOf(search.toLowerCase()) > -1
            ) {
              isMatch = true;
              break;
            } else if (
              (data[i].firstName &&
                data[i].firstName.toLowerCase().indexOf(search.toLowerCase()) >
                  -1) ||
              (data[i].lastName &&
                data[i].lastName.toLowerCase().indexOf(search.toLowerCase()) >
                  -1)
            ) {
              isMatch = true;
              break;
            }
          } else if (
            data[i][headers[j].id] &&
            data[i][headers[j].id].toLowerCase().indexOf(search.toLowerCase()) >
              -1
          ) {
            isMatch = true;
            break;
          }
        }
      }

      // Check for the user's email even if it is not part of the table.
      if (
        !isMatch &&
        data[i].email &&
        data[i].email.toLowerCase().indexOf(search.toLowerCase()) > -1
      ) {
        isMatch = true;
      }

      if (isMatch) {
        filteredRows.push(data[i]);
      }
    }
  } else {
    filteredRows = [...data];
  }

  if (selectedRiskLevel === "Severe") {
    filteredRows = filteredRows.filter(
      (row) => row.riskScore && row.riskScore > 75
    );
  } else if (selectedRiskLevel === "High") {
    filteredRows = filteredRows.filter(
      (row) => row.riskScore && row.riskScore > 50 && row.riskScore <= 75
    );
  } else if (selectedRiskLevel === "Moderate") {
    filteredRows = filteredRows.filter(
      (row) => row.riskScore && row.riskScore > 25 && row.riskScore <= 50
    );
  } else if (selectedRiskLevel === "Low") {
    filteredRows = filteredRows.filter(
      (row) => row.riskScore && row.riskScore > 0 && row.riskScore <= 25
    );
  }

  if (selectedCompliant === "Yes") {
    filteredRows = filteredRows.filter((row) => row.compliant === true);
  } else if (selectedCompliant === "No") {
    filteredRows = filteredRows.filter((row) => row.compliant === false);
  }

  if (selectedDepartment) {
    filteredRows = filteredRows.filter(
      (row) => row.department === selectedDepartment
    );
  }

  if (selectedComplianceFramework) {
    filteredRows = filteredRows.filter(
      (row) =>
        row.complianceFrameworks &&
        row.complianceFrameworks[selectedComplianceFramework] === true
    );
  }

  const visibleRows = stableSort(
    filteredRows,
    getComparator(order, orderBy)
  ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  useEffect(() => {
    const updateComponent = async () => {
      const allDepartments = data.map((user) => user.department);
      const departmentNames = new Set(allDepartments);

      const departments = [...departmentNames].map((name) => {
        return {
          name,
        };
      });
      departments.sort((a, b) => a.name.localeCompare(b.name));

      setDepartments(departments);

      let complianceFrameworks = data[0].complianceFrameworks;

      if (
        complianceFrameworks &&
        Array.isArray(complianceFrameworks) &&
        complianceFrameworks.length > 0
      ) {
        complianceFrameworks = complianceFrameworks.map(
          (complianceFramework) => {
            return {
              name: complianceFramework.name,
            };
          }
        );

        complianceFrameworks.sort((a, b) => a.name.localeCompare(b.name));

        setComplianceFrameworks(complianceFrameworks);
      }
    };

    if (data && Array.isArray(data) && data.length > 0) {
      updateComponent().finally(() => {
        if (defaultFilters) {
          if (
            defaultFilters.complianceFramework &&
            defaultFilters.complianceFramework.name
          ) {
            setSelectedComplianceFramework(
              defaultFilters.complianceFramework.name
            );
          }

          if (defaultFilters.department) {
            setSelectedDepartment(defaultFilters.department);
          }

          if (defaultFilters.riskLevel && defaultFilters.riskLevel.name) {
            setSelectedRiskLevel(defaultFilters.riskLevel.name);
          }
        }
      });
    }
  }, [data, defaultFilters]);

  useEffect(() => {
    // Check select all
    let isSelectAll = true;

    for (let i = 0; i < visibleRows.length; i++) {
      if (!selectedData.includes(visibleRows[i])) {
        isSelectAll = false;
        break;
      }
    }

    setIsSelectAll(isSelectAll);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleRows]);

  useEffect(() => {
    const newSelectedData = selectedData.filter((selectedRow) =>
      filteredRows.some(
        (filteredRow) => filteredRow.email === selectedRow.email
      )
    );

    setSelectedData(newSelectedData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedRiskLevel,
    selectedCompliant,
    selectedDepartment,
    selectedComplianceFramework,
    search,
  ]);

  return (
    <div className="w-full">
      {!disableSearchBar && (
        <div className="flex flex-col items-center md:flex-row">
          {customHeader && (
            <div className="flex flex-row items-center md:mr-[20px] w-full">
              {customHeader}
            </div>
          )}
          <div className="flex w-full mt-[20px] md:mt-0">
            <div className="w-full">
              <input
                type="text"
                className="rounded-small py-[10px] px-[20px] w-full border description placeholder-white placeholder-opacity-50  placeholder:italic border-border-color bg-gray-background focus:ring-0 focus:outline-none focus:border-green"
                value={search}
                placeholder="Type in an employee's name"
                autoComplete="off"
                onChange={handleChangeSearch}
              />
            </div>
            <div className="ml-[4px]">
              <ButtonOutlinedGray
                padding="py-[10px] px-[20px]"
                additionalClassName="flex flex-row items-center"
                title="Search employees."
              >
                Search
                <div className="w-[18px] h-[18px] ml-[4px]">
                  <SearchIcon />
                </div>
              </ButtonOutlinedGray>
            </div>
            {!disableFilters && (
              <Filters
                selectedDepartment={selectedDepartment}
                selectedRiskLevel={selectedRiskLevel}
                departments={departments}
                onChangeDepartment={handleChangeDepartment}
                onChangeRiskLevel={handleChangeRiskLevel}
              />
            )}
            <div className="ml-[4px]">
              <ButtonOutlinedGray
                additionalClassName="h-[41px] w-[46px]"
                title="Download a report of employees."
                onClick={handleClickExportSelectedReports}
              >
                <div className="w-[24px] h-[24px]">
                  <DownloadIcon />
                </div>
              </ButtonOutlinedGray>
            </div>
          </div>
        </div>
      )}
      <div className={disableSearchBar ? "" : "mt-1.25"}>
        <Card padding="px-0.625 pb-0.625">
          <div className="overflow-x-auto">
            <table className="w-full">
              <colgroup>
                {headers.map((header, index) => (
                  <col style={{ width: header.width }} key={`col-${index}`} />
                ))}
              </colgroup>
              <thead>
                <tr className="border border-t-0 border-l-0 border-r-0 border-b-border-color">
                  {headers.map((header, index) => {
                    // If the header is select, display the select all checkbox.
                    if (header.id === "select") {
                      return (
                        <th key={`head-cell-${index}`}>
                          <input
                            type="checkbox"
                            checked={isSelectAll}
                            onClick={handleClickCheckboxSelectAll}
                          />
                        </th>
                      );
                    }

                    return (
                      <th
                        key={`head-cell-${index}`}
                        className={`card-header p-[8px] ${
                          header.isSortable && "cursor-pointer"
                        }`}
                        onClick={() =>
                          header.isSortable && handleSortRequest(header.id)
                        }
                      >
                        <div
                          className={`flex flex-row items-center justify-${
                            header.align === "center"
                              ? "center"
                              : header.align === "right"
                              ? "end"
                              : "start"
                          }`}
                        >
                          {header.label}
                          {header.isSortable && orderBy === header.id && (
                            <span className="ml-[8px]">
                              {order === "asc" ? (
                                <svg
                                  className="inline w-[16px] h-[16px]"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M5 15l7-7 7 7"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  className="inline w-[16px] h-[16px]"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M19 9l-7 7-7-7"
                                  />
                                </svg>
                              )}
                            </span>
                          )}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {visibleRows.length > 0 ? (
                  visibleRows.map((user, userIndex) => (
                    <tr
                      className="border border-t-0 border-l-0 border-r-0 border-b-border-color last:border-0"
                      key={`row-${userIndex}`}
                    >
                      {headers.map((header, headerIndex) => {
                        if (header.id === "select") {
                          const isSelected = selectedData.some(
                            (element) => element.email === user.email
                          );

                          return (
                            <td
                              key={`user-${userIndex}-data-${headerIndex}`}
                              className={`whitespace-nowrap text-${
                                header.align === "center"
                                  ? "center"
                                  : header.align === "right"
                                  ? "end"
                                  : "start"
                              }`}
                            >
                              <input
                                type="checkbox"
                                className="form-checkbox"
                                checked={isSelected}
                                onClick={() => handleClickCheckbox(user)}
                              />
                            </td>
                          );
                        }

                        if (header.id === "name") {
                          return (
                            <td
                              key={`user-${userIndex}-data-${headerIndex}`}
                              className={`description p-[8px] text-center ${
                                header.align === "right"
                                  ? "text-right"
                                  : "text-left"
                              }`}
                            >
                              {!disableProfilePage ? (
                                <Link
                                  to="/insights/employees/"
                                  className="description "
                                  title={`Go to ${
                                    user.firstName && user.lastName
                                      ? `${user.firstName} ${user.lastName}'s `
                                      : ""
                                  }insights.`}
                                  aria-label={`Go to ${
                                    user[header.id]
                                      ? `${user[header.id]} `
                                      : " "
                                  }insights.`}
                                  state={{ email: user.email }}
                                >
                                  <div
                                    className={`flex flex-row items-center justify-${
                                      header.align === "center"
                                        ? "center"
                                        : header.align === "right"
                                        ? "end"
                                        : "start"
                                    }`}
                                  >
                                    <div className="flex items-center justify-center w-[32px] h-[32px] bg-[#4f4e4a] text-white rounded-full">
                                      {user.initials}
                                    </div>
                                    <div className="overflow-hidden whitespace-nowrap text-ellipsis ml-[8px] hover:underline">
                                      {user.firstName} {user.lastName}
                                    </div>
                                  </div>
                                </Link>
                              ) : (
                                <div
                                  className={`flex flex-row items-center justify-${
                                    header.align === "center"
                                      ? "center"
                                      : header.align === "right"
                                      ? "end"
                                      : "start"
                                  }`}
                                >
                                  <div className="flex items-center justify-center w-[32px] h-[32px] bg-[#4f4e4a] text-white bg-gray-500 rounded-full">
                                    {user.initials}
                                  </div>
                                  <div className="ml-[8px] hover:underline">
                                    {user.firstName} {user.lastName}
                                  </div>
                                </div>
                              )}
                            </td>
                          );
                        }

                        if (header.id === "compliant") {
                          return (
                            <td
                              key={`user-${userIndex}-data-${headerIndex}`}
                              className={`description p-[8px] ${
                                user[header.id]
                                  ? "text-green-600"
                                  : "text-red-600"
                              } text-${
                                header.align === "center"
                                  ? "center"
                                  : header.align === "right"
                                  ? "end"
                                  : "start"
                              }`}
                            >
                              {user[header.id] !== "N/A"
                                ? user[header.id]
                                  ? "Yes"
                                  : "No"
                                : "N/A"}
                            </td>
                          );
                        }

                        if (header.id === "riskLevel") {
                          return (
                            <td
                              key={`user-${userIndex}-data-${headerIndex}`}
                              className={`p-[8px] text-${
                                header.align === "center"
                                  ? "center"
                                  : header.align === "right"
                                  ? "end"
                                  : "start"
                              }`}
                            >
                              <div className="flex justify-center">
                                <div
                                  className="flex rounded-[4px] w-fit py-[2px] px-[8px]"
                                  style={{
                                    background:
                                      user.riskScore > 75
                                        ? "#FF2B00"
                                        : user.riskScore > 50
                                        ? "#FFA100"
                                        : user.riskScore > 25
                                        ? "#FDFF00"
                                        : user.riskScore > 0
                                        ? "#00FF82"
                                        : "#acacac",
                                  }}
                                >
                                  <span
                                    className={`detailed-emphasized ${
                                      user.riskScore > 75
                                        ? "text-red-text"
                                        : user.riskScore > 50
                                        ? "text-orange-text"
                                        : user.riskScore > 25
                                        ? "text-yellow-text"
                                        : user.riskScore > 0
                                        ? "text-green-text"
                                        : "text-black"
                                    }`}
                                  >
                                    {user.riskScore > 75
                                      ? "Severe"
                                      : user.riskScore > 50
                                      ? "High"
                                      : user.riskScore > 25
                                      ? "Moderate"
                                      : user.riskScore > 0
                                      ? "Low"
                                      : "N/A"}
                                  </span>
                                </div>
                              </div>
                            </td>
                          );
                        }

                        if (header.id === "riskScore") {
                          return (
                            <td
                              key={`user-${userIndex}-data-${headerIndex}`}
                              className={`p-[8px] `}
                            >
                              <div
                                className={`flex flex-row justify-${
                                  header.align === "center"
                                    ? "center"
                                    : header.align === "right"
                                    ? "end"
                                    : "start"
                                } items-center`}
                              >
                                <RiskGauge
                                  riskScore={user.riskScore}
                                  cells={16}
                                />
                                <div className="flex w-[24px]">
                                  <span className="description-emphasized ml-[8px]">
                                    {user[header.id] || "N/A"}
                                  </span>
                                </div>
                              </div>
                            </td>
                          );
                        }

                        if (
                          header.id === "isAdmin" ||
                          header.id === "isSuperadmin"
                        ) {
                          return (
                            <td
                              key={`user-${userIndex}-data-${headerIndex}`}
                              className={`description p-[8px] text-${
                                header.align === "center"
                                  ? "center"
                                  : header.align === "right"
                                  ? "end"
                                  : "start"
                              }`}
                            >
                              {user[header.id] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="text-blue-600"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M16.707 5.293a1 1 0 00-1.414 0L9 11.586 5.707 8.293a1 1 0 10-1.414 1.414l4 4a1 1 0 001.414 0l7-7a1 1 0 000-1.414z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              )}
                            </td>
                          );
                        }

                        if (header.id === "recipients") {
                          return (
                            <td
                              key={`user-${userIndex}-data-${headerIndex}`}
                              className={`detailed p-[8px] text-${
                                header.align === "center"
                                  ? "center"
                                  : header.align === "right"
                                  ? "end"
                                  : "start"
                              } ${header.onClick && "cursor-pointer"}`}
                              onClick={
                                header.onClick
                                  ? () =>
                                      header.onClick(
                                        user.recipients,
                                        user.assetName
                                      )
                                  : null
                              }
                            >
                              <div className="flex flex-row items-center">
                                {user.recipients
                                  .slice(0, 4)
                                  .map((recipient, recipientIndex) => (
                                    <div
                                      className={`flex items-center justify-center text-[0.625rem] w-[20px] h-[20px] bg-[#4f4e4a] text-white rounded-full ${
                                        recipientIndex !== 0 ? "ml-[2px]" : ""
                                      }`}
                                      key={`user-${userIndex}-data-${headerIndex}-recipient-${recipientIndex}`}
                                    >
                                      {recipient.initials}
                                    </div>
                                  ))}
                                {user.recipients.length > 4 && (
                                  <div className="flex items-center justify-center px-[8px] h-[20px]  bg-gray-badge-light text-black rounded-full  ml-[2px]">
                                    +{user.recipients.length - 4}
                                  </div>
                                )}
                              </div>
                            </td>
                          );
                        }

                        if (header.id === "viewAsset") {
                          return (
                            <td
                              key={`user-${userIndex}-data-${headerIndex}`}
                              className={`detailed p-[8px] text-${
                                header.align === "center"
                                  ? "center"
                                  : header.align === "right"
                                  ? "end"
                                  : "start"
                              } ${header.onClick && "cursor-pointer"}`}
                              onClick={
                                header.onClick
                                  ? () =>
                                      header.onClick(
                                        user.assetFileName,
                                        user.assetName
                                      )
                                  : null
                              }
                            >
                              <ButtonOutlinedGray
                                padding="p-[5px]"
                                title="View this asset."
                                // onClick={() => setShowFilters(true)}
                              >
                                <div className="w-[16px] h-[16px]">
                                  <EyeIcon />
                                </div>
                              </ButtonOutlinedGray>
                            </td>
                          );
                        }

                        if (header.id === "departmentName") {
                          return (
                            <td
                              key={`user-${userIndex}-data-${headerIndex}`}
                              className={`description-emphasized p-[8px] text-${
                                header.align === "center"
                                  ? "center"
                                  : header.align === "right"
                                  ? "end"
                                  : "start"
                              }`}
                            >
                              <div
                                className="overflow-hidden whitespace-nowrap text-ellipsis"
                                style={{
                                  maxWidth: header.width
                                    ? header.width
                                    : "100%",
                                }}
                              >
                                {user[header.id]}
                              </div>
                            </td>
                          );
                        }

                        if (header.enableBadge) {
                          return (
                            <td
                              key={`user-${userIndex}-data-${headerIndex}`}
                              className={`description text-[0.625rem] p-[8px] text-${
                                header.align === "center"
                                  ? "center"
                                  : header.align === "right"
                                  ? "end"
                                  : "start"
                              }`}
                            >
                              <div
                                className="overflow-hidden whitespace-nowrap text-ellipsis"
                                style={{
                                  maxWidth: header.width
                                    ? header.width
                                    : "100%",
                                }}
                              >
                                <div className="flex flex-row items-center bg-gray-badge-light text-black font-medium py-[2px] px-[8px] rounded-[4px] w-fit">
                                  {user[header.id]}
                                </div>
                              </div>
                            </td>
                          );
                        }

                        return (
                          <td
                            key={`user-${userIndex}-data-${headerIndex}`}
                            className={`description p-[8px] text-${
                              header.align === "center"
                                ? "center"
                                : header.align === "right"
                                ? "end"
                                : "start"
                            }`}
                          >
                            <div
                              className="overflow-hidden whitespace-nowrap text-ellipsis"
                              style={{
                                maxWidth: header.width ? header.width : "100%",
                              }}
                            >
                              {user[header.id]}
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={headers.length}
                      className="text-center p-[8px] description-emphasized"
                    >
                      No users found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {!disablePagination && (
            <div className="flex flex-col md:flex-row justify-between items-center mt-1.25">
              <div>
                <Pagination
                  page={page}
                  setPage={setPage}
                  totalPages={
                    fixedTotal
                      ? Math.ceil(fixedTotal / rowsPerPage)
                      : Math.ceil(filteredRows.length / rowsPerPage)
                  }
                />
              </div>
              <div className="flex justify-end items-center w-[112px] ml-1.25 mt-1.25 md:mt-0">
                <p className="detailed text-gray">
                  Viewing {visibleRows.length} of{" "}
                  {fixedTotal
                    ? fixedTotal.toLocaleString("en-US")
                    : filteredRows.length.toLocaleString("en-US")}
                </p>
              </div>
            </div>
          )}
          {customFooter && customFooter}
        </Card>
      </div>
    </div>
  );
}

export default UsersTable;
