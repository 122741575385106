import React, { useEffect, useState } from "react";

import { colors } from "../../../../../styles";

import { Card } from "../../../../common";

function CardRiskScore({ riskScore, riskLevel, color, description }) {
  const [animatedRiskScore, setAnimatedRiskScore] = useState(0);
  const [borderColor, setBorderColor] = useState(null);
  const [riskGaugeLevel, setRiskGaugeLevel] = useState(0);

  useEffect(() => {
    if (riskScore) {
      let start = 0;
      const duration = 1000;
      const increment = riskScore / (duration / 50);

      const interval = setInterval(() => {
        if (!borderColor) {
          setBorderColor(colors.green); // Start with the green border color.
        }

        start += increment;

        if (start >= riskScore) {
          setAnimatedRiskScore(riskScore);
          clearInterval(interval);

          // Risk gauge level
          const updatedRiskGaugeLevel = Math.round(riskScore / (100 / 24));
          if (updatedRiskGaugeLevel !== riskGaugeLevel) {
            setRiskGaugeLevel(Math.round(riskScore / (100 / 24)));
          }

          // Border color
          if (riskScore > 75) {
            if (borderColor !== colors.red) {
              setBorderColor(colors.red);
            }
          } else if (riskScore > 50) {
            if (borderColor !== colors.orange) {
              setBorderColor(colors.orange);
            }
          } else if (riskScore > 25) {
            if (borderColor !== colors.yellow) {
              setBorderColor(colors.yellow);
            }
          }
        } else {
          setAnimatedRiskScore(start);

          // Risk gauge level
          const updatedRiskGaugeLevel = Math.round(start / (100 / 24));
          if (updatedRiskGaugeLevel !== riskGaugeLevel) {
            setRiskGaugeLevel(Math.round(start / (100 / 24)));
          }

          // Border color
          if (start > 75) {
            if (borderColor !== colors.red) {
              setBorderColor(colors.red);
            }
          } else if (start > 50) {
            if (borderColor !== colors.orange) {
              setBorderColor(colors.orange);
            }
          } else if (start > 25) {
            if (borderColor !== colors.yellow) {
              setBorderColor(colors.yellow);
            }
          }
        }
      }, 50);

      return () => clearInterval(interval);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [riskScore]);

  return (
    <Card padding="py-[10px] px-[20px]" borderColor={borderColor}>
      <div>
        <h3 className="text-center card-header">Risk Score</h3>
      </div>
      <div className="mt-0.625">
        <div className="flex flex-row justify-between">
          {new Array(24).fill(null).map((_, index) => (
            <div
              className="rounded-[2px]"
              style={{
                background:
                  riskGaugeLevel === index + 1
                    ? index >= 18
                      ? "#ef3c1f"
                      : index >= 12
                      ? "#f59a31"
                      : index >= 6
                      ? "#fee442"
                      : "#02ff82"
                    : riskGaugeLevel < index + 1
                    ? `linear-gradient(0deg, rgba(35, 35, 35, 0.50) 0%, rgba(35, 35, 35, 0.50) 100%), ${
                        index >= 18
                          ? colors.red
                          : index >= 12
                          ? colors.orange
                          : index >= 6
                          ? colors.yellow
                          : colors.green
                      }`
                    : index >= 18
                    ? colors.red
                    : index >= 12
                    ? colors.orange
                    : index >= 6
                    ? colors.yellow
                    : colors.green,
                boxShadow:
                  riskGaugeLevel === index + 1
                    ? "0 0 10px rgba(255, 255, 255, 0.8), 0 0 20px rgba(255, 255, 255, 0.6), 0 0 30px rgba(255, 255, 255, 0.4)"
                    : "1px 1px 3px 0px rgba(0, 0, 0, 0.30)",
                height: "18.182px",
                width: "3px",
              }}
              key={`card-risk-score-level-${index}`}
            />
          ))}
        </div>
      </div>
      <div className="flex flex-row justify-between items-center mt-0.625">
        <div>
          <p className="text-white font-sans text-[3.5rem] leading-[normal]">
            {Math.round(animatedRiskScore)}
          </p>
        </div>
        <div>
          <div
            className="py-[4px] px-[10px] rounded-small"
            style={{
              background: color,
            }}
          >
            <p className="text-black detailed-emphasized">{riskLevel}</p>
          </div>
        </div>
      </div>
      <div className="mt-0.625">
        <p className="detailed text-[0.625rem]">{description}</p>
      </div>
    </Card>
  );
}

export default CardRiskScore;
