import React, { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import {
  ArrowBackIosNewOutlined,
  CalendarTodayOutlined,
  PermIdentityOutlined,
} from '@mui/icons-material';

import { createClient } from 'contentful';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';

import { FadeInSection, ResourcesGroup } from '../../../components';

import './resource-bootcamp.css';

function ResourceBootcamp() {
  // Resource
  const [author, setAuthor] = useState('');
  const [bodyContent, setBodyContent] = useState([]);
  const [date, setDate] = useState('');
  const [thumbnailURL, setThumbnailURL] = useState('');
  const [title, setTitle] = useState('');

  // Resources (Read more)
  const [resources, setResources] = useState([]);

  const { resourceID } = useParams();
  const { referenceId } = useParams();

  const client = useMemo(
    () =>
      createClient({
        space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
        accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
      }),
    []
  );

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { file, title } = node.data.target.fields;
        const imageUrl = file.url.startsWith('//')
          ? `https:${file.url}`
          : file.url;

        return (
          <Box display="flex" justifyContent="center" marginTop="28px">
            <Box
              component="img"
              src={imageUrl}
              alt={title ? title : 'Embedded asset'}
              style={{
                maxWidth: '500px',
                height: 'auto',
              }}
            />
          </Box>
        );
      },
    },
  };

  const formatDate = (date) => {
    // Replace dashes to slashes to avoid having the date being a day off.
    const givenDate = new Date(date.replace(/-/g, '/'));
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    return givenDate.toLocaleDateString('en-US', options);
  };

  useEffect(() => {
    const fetchResource = async () => {
      await client
        .getEntries({
          'content_type': 'resourcesBootcamp',
          'fields.resourceID': resourceID,
          'limit': 1,
        })
        .then((response) => {
          if (response.items.length > 0) {
            const resource = response.items[0].fields;

            setAuthor(resource.author);
            setBodyContent(resource.body.content);
            setDate(resource.date);
            setThumbnailURL(resource.thumbnail.fields.file.url);
            setTitle(resource.title);
          } else {
          }
        })
        .catch(console.error);
    };

    const fetchResources = async () => {
      let fetchedResourcesBootcamp = [];
      let fetchedResourcesTeam = [];
      let fetchedResourcesWebinarRecapBlog = [];

      await client
        .getEntries({
          content_type: 'resourcesBootcamp',
          limit: 3,
          order: '-fields.date,fields.title',
        })
        .then((response) => {
          fetchedResourcesBootcamp = response.items.map((item) => {
            const { fields, sys } = item;
            return {
              id: sys.id,
              title: fields.title,
              href: `/resources/bootcamp/${fields.resourceID}`,
              thumbnailURL: fields.thumbnail.fields.file.url,
              date: fields.date,
            };
          });
        })
        .catch(console.error);

      await client
        .getEntries({
          content_type: 'resourcesTeam',
          limit: 3,
          order: '-fields.date,fields.title',
        })
        .then((response) => {
          fetchedResourcesTeam = response.items.map((item) => {
            const { fields, sys } = item;

            return {
              id: sys.id,
              title: fields.title,
              pathname: `/resources/team/${fields.resourceID}`,
              thumbnailURL: fields.thumbnail.fields.file.url,
              date: fields.date,
            };
          });
        })
        .catch(console.error);

      await client
        .getEntries({
          content_type: 'webinarRecapBlog',
          limit: 3,
          order: '-fields.date,fields.title',
        })
        .then((response) => {
          fetchedResourcesWebinarRecapBlog = response.items.map((item) => {
            const { fields, sys } = item;

            return {
              id: sys.id,
              title: fields.title,
              href: `/resources/webinar-recap-blog/${fields.resourceID}`,
              thumbnailURL: fields.thumbnail.fields.file.url,
              date: fields.date,
            };
          });
        })
        .catch(console.error);

      const fetchedResourcesMostRecent = [
        ...fetchedResourcesBootcamp,
        ...fetchedResourcesTeam,
        ...fetchedResourcesWebinarRecapBlog,
      ];

      fetchedResourcesMostRecent.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);

        if (dateA < dateB) {
          return 1;
        } else if (dateA > dateB) {
          return -1;
        }

        return a.title.localeCompare(b.title);
      });

      setResources(fetchedResourcesMostRecent.slice(0, 3));
    };

    fetchResource();
    fetchResources();
  }, [client, referenceId, resourceID]);

  return (
    <>
      <Box
        className="resource-box-main-content"
        marginTop="-80px"
        paddingTop="80px">
        <FadeInSection>
          <Box
            display="flex"
            justifyContent="center"
            marginTop={{ xs: '60px', md: '80px' }}
            padding="0 28px">
            <Box maxWidth="1152px" width="100%">
              <Button
                variant="text"
                component={Link}
                className="dune-button-text-black resource-button-resource-hub"
                startIcon={
                  <ArrowBackIosNewOutlined className="resource-icon-resource-hub" />
                }
                to="/resources/"
                title="Go to the resources hub page.">
                Resource hub
              </Button>
              <Typography
                component="h1"
                className="dune-text-header-main resource-text-header-main"
                marginTop="40px">
                {title}
              </Typography>
            </Box>
          </Box>
        </FadeInSection>

        <FadeInSection>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginTop={{ xs: '60px', md: '80px' }}>
            <Box
              component="img"
              alt={title}
              src={thumbnailURL}
              maxWidth="100%"
              maxHeight="600px"
            />
          </Box>
        </FadeInSection>

        <FadeInSection>
          <Box
            display="flex"
            justifyContent="center"
            paddingTop="40px"
            paddingLeft="28px"
            paddingRight="28px">
            <Box maxWidth="1152px" width="100%">
              <Box>
                <Stack direction="row" alignItems="center">
                  {date && (
                    <>
                      <CalendarTodayOutlined className="resource-icon-info" />
                      <Typography
                        component="p"
                        className="resource-text-small"
                        marginLeft="40px">
                        {formatDate(date)}
                      </Typography>
                    </>
                  )}
                  {author && (
                    <>
                      <Box display="flex" alignItems="center" marginLeft="60px">
                        <PermIdentityOutlined className="resource-icon-info" />
                      </Box>
                      <Typography
                        component="p"
                        className="resource-text-small"
                        marginLeft="40px">
                        {author}
                      </Typography>
                    </>
                  )}
                </Stack>
              </Box>
              <Divider className="resource-divider" />
              <Box
                className="resource-box-body-content"
                marginTop={{ xs: '80px', md: '120px' }}>
                {bodyContent.map((contentItem) =>
                  documentToReactComponents(contentItem, options)
                )}
              </Box>
            </Box>
          </Box>
        </FadeInSection>
        <FadeInSection>
          {resources.length > 0 && (
            <Box
              display="flex"
              justifyContent="center"
              marginTop={{
                xs: '80px',
                md: '120px',
              }}
              padding={{
                xs: '0 28px 80px',
                md: '0 28px 120px',
              }}>
              <Box maxWidth="1152px" width="100%">
                <Typography
                  component="h2"
                  className="resource-text-header-secondary">
                  Read more
                </Typography>
                <Box marginTop="80px">
                  <ResourcesGroup resources={resources} isLight />
                </Box>
                <Box display="flex" justifyContent="flex-end" marginTop="80px">
                  <Button
                    variant="text"
                    component={Link}
                    className="dune-button-text-black resource-button-view-all"
                    endIcon={
                      <ArrowBackIosNewOutlined className="resource-icon-view-all" />
                    }
                    to="/resources/"
                    title="Go to the resources hub page.">
                    View all
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </FadeInSection>
      </Box>
    </>
  );
}

export default ResourceBootcamp;
