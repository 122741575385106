import {
  CampaignFilledIcon,
  CampaignOutlinedIcon,
  GPPGoodFilledIcon,
  GPPGoodOutlinedIcon,
  HomeFilledIcon,
  HomeOutlinedIcon,
  PersonFilledIcon,
  PersonOutlinedIcon,
  SettingsFilledIcon,
  SettingsOutlinedIcon,
} from "../images";

const pagesPlatformBeforeBRA = {
  WELCOME: {
    name: "Welcome",
    path: "/welcome/",
    icon: <HomeOutlinedIcon />,
    activeIcon: <HomeFilledIcon />,
    tooltip: "Go to the compliance page.",
  },
  SSO_SETTINGS: {
    name: "SSO settings",
    path: "/sso-settings/",
    icon: <SettingsOutlinedIcon />,
    activeIcon: <SettingsFilledIcon />,
    tooltip: "Go to the SSO settings page.",
  },
  COMPLIANCE: {
    name: "Compliance",
    path: "/compliance-onboarding/",
    icon: <GPPGoodOutlinedIcon />,
    activeIcon: <GPPGoodFilledIcon />,
    tooltip: "Go to the compliance page.",
  },
  EMPLOYEES: {
    name: "Employees",
    path: "/employees/",
    icon: <PersonOutlinedIcon />,
    activeIcon: <PersonFilledIcon />,
    tooltip: "Go to the employees page.",
  },
  CAMPAIGNS: {
    name: "Campaigns",
    path: "/campaigns/",
    icon: <CampaignOutlinedIcon />,
    activeIcon: <CampaignFilledIcon />,
    tooltip: "Go to the campaign page.",
  },
};

export default pagesPlatformBeforeBRA;
