import React from "react";
import { useLocation } from "react-router-dom";

import { Box, Button, Typography } from "@mui/material";

import { getSlackAuthentication } from "../../../api";

function DuneGuardian() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get("status");

  const handleClickAddDuneGuardianToSlack = async () => {
    const getSlackAuthenticationResponse = await getSlackAuthentication();

    if (Object.keys(getSlackAuthenticationResponse.error).length > 0) {
      console.error(getSlackAuthenticationResponse.error.message);
    } else {
      const { redirectUrl } = getSlackAuthenticationResponse.result;

      if (redirectUrl) {
        window.location.href = redirectUrl;
      }
    }
  };

  return (
    <Box marginTop="60px">
      <Typography component="h2" className="dune-text-header-dashboard">
        Slack
      </Typography>
      {!status && (
        <>
          <Typography component="p" marginTop="40px">
            Download Dune Guardian to your Slack environment and empower your team
            with training reminders and seamless, real-time security insights to
            stay ahead of potential risks.
          </Typography>
          <Box marginTop="20px">
            <Button
              variant="contained"
              className="dune-button-contained-green"
              onClick={handleClickAddDuneGuardianToSlack}
            >
              Add Dune Guardian to Slack
            </Button>
          </Box>
        </>
      )}
      {status && status === "success" && (
        <Typography component="p" marginTop="40px">
          You have successfully added Dune Guardian to your Slack environment.
        </Typography>
      )}
      {status && status === "fail" && (
        <Typography component="p" marginTop="40px">
          An error has occurred when trying to add Dune Guardian to your Slack
          environment. Contact Dune Security at{" "}
          <a
            className="dune-link"
            href="mailto:info@dune.security"
            title="Email info@dune.security."
          >
            info@dune.security
          </a>
          .
        </Typography>
      )}
    </Box>
  );
}

export default DuneGuardian;
