import React from "react";

const UserSpecificTestingLogo = () => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 17.6782L15 20.6782L20 13.6782M16 4.29688C12.981 7.16385 8.96035 8.73631 4.79734 8.67821C4.26779 10.2915 3.99863 11.9789 4.00001 13.6769C4.00001 21.1329 9.09867 27.3969 16 29.1742C22.9013 27.3982 28 21.1342 28 13.6782C28 11.9315 27.72 10.2502 27.2027 8.67688H27C22.7387 8.67688 18.8667 7.01288 16 4.29688Z"
        stroke="#00FF82"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UserSpecificTestingLogo;
