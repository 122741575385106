import React, { useCallback, useEffect, useState } from "react";

import Papa from "papaparse";

import {
  getDepartments,
  getInsightsDepartment,
  getTrainingCompletion,
  getUsers,
  getUsersByDepartment,
} from "../../../api";

import { Dashboard } from "../../../components";

import { pagesPlatform } from "../../../constants";

/**
 * Component for displaying insights of a specific department.
 * Handles department selection and fetches relevant insights data.
 */
function InsightsDepartment({ isInitialLoading, email, setIsPlatformLoading }) {
  // Insights
  const [totalUsers, setTotalUsers] = useState(null);
  const [riskScore, setRiskScore] = useState(null);
  const [scoreChange, setScoreChange] = useState(null);
  const [riskScoreOverTime, setRiskScoreOverTime] = useState(null);
  const [areasToImprove, setAreasToImprove] = useState(null);
  const [complianceFrameworks, setComplianceFrameworks] = useState(null);
  const [employeesByDepartment, setEmployeesByDepartment] = useState(null);
  const [trainingCompletion, setTrainingCompletion] = useState(null);
  const [trainingCompletionUsers, setTrainingCompletionUsers] = useState(null);

  const [riskScoreOverAllTime, setRiskScoreOverAllTime] = useState([]); // Master set (All Time)

  // Departments selector
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");

  // Cache
  const [departmentDataCache, setDepartmentDataCache] = useState({});

  const getDemoDepartmentName = (department) => {
    const demoDepartmentMappings = {
      Engineering: "Founder Scouting",
      Legal: "Legal & Finance",
      "Information Technology": "Technology",
    };

    return demoDepartmentMappings[department] || department;
  };

  const handleChangeSelectedDays = (days) => {
    // For risk score over time card
    updateRiskScoreOverTime(days);
  };

  const handleChangeDepartment = async (event) => {
    setIsPlatformLoading(true);

    const selectedDepartment = event.target.value;
    setSelectedDepartment(selectedDepartment);

    // Change the selected department to the actual department name if the account is a demo account.
    const departmentToLoad =
      email === "david@dune.demo"
        ? getDemoDepartmentName(selectedDepartment)
        : selectedDepartment;

    loadUsersByDepartment(departmentToLoad);
    loadInsightsDepartment(departmentToLoad);
    await loadTrainingCompletion(departmentToLoad);

    setIsPlatformLoading(false);
  };

  const handleClickShareReport = async () => {
    const usersData = await getUsers();

    if (Object.keys(usersData.error).length > 0) {
      console.error(usersData.error.message);
    } else {
      const users = usersData.result.filter(
        (user) => user.department === selectedDepartment,
      );
      const csv = Papa.unparse(
        users.map(({ compliant, complianceFrameworks, ...fields }) => {
          const row = { ...fields };

          if (
            complianceFrameworks &&
            typeof complianceFrameworks === "object" &&
            Object.keys(complianceFrameworks).length > 0
          ) {
            Object.entries(complianceFrameworks).forEach(([key, value]) => {
              row[key] = value !== null ? (value ? "yes" : "no") : "N/A";
            });
          }

          return row;
        }),
      );

      const blob = new Blob([csv], {
        type: "text/csv;charset=utf-8;",
      });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "department-insights.csv";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const updateRiskScoreOverTime = useCallback(
    (days) => {
      if (days === "All Time") {
        // If all time, use the master set riskScoreOverAllTime.
        setRiskScoreOverTime(riskScoreOverAllTime);
      } else {
        const today = new Date();

        const cutOffDate = new Date();
        cutOffDate.setDate(today.getDate() - days);

        const filteredRiskScoreOverTime = riskScoreOverAllTime.filter(
          (element) => {
            const [month, day] = element.date.split(" ");
            let year = today.getFullYear();
            const dateForYearCheck = new Date(`${month} ${day}, ${year}`);

            if (dateForYearCheck > today) {
              year--;
            }

            const date = new Date(`${month} ${day}, ${year}`);

            return date >= cutOffDate;
          },
        );

        setRiskScoreOverTime(filteredRiskScoreOverTime);
      }
    },
    [riskScoreOverAllTime],
  );

  const loadInsightsDepartment = useCallback(
    async (department) => {
      // Check if data for this department is already cached.
      if (departmentDataCache[department]) {
        const cachedData = departmentDataCache[department];

        setTotalUsers(cachedData.totalUsers);
        setRiskScore(cachedData.riskScore);
        setScoreChange(cachedData.scoreChange);
        setAreasToImprove(cachedData.areasToImprove);
        setComplianceFrameworks(cachedData.complianceFrameworks);
        setRiskScoreOverAllTime(cachedData.riskScoreOverAllTime);

        updateRiskScoreOverTime(90); // Default value is 90.

        return; // Return early as we have cached data.
      }

      // Fetch new data as it is not in cache.
      if (email !== "david@dune.demo") {
        const insightsDepartment = await getInsightsDepartment(department);

        if (Object.keys(insightsDepartment.error).length > 0) {
          console.error(insightsDepartment.error.message);
        } else {
          const {
            totalUsers,
            riskScore,
            scoreChange,
            riskScoreOverTime,
            areasToImprove,
            complianceFrameworks,
          } = insightsDepartment.result;

          setTotalUsers(totalUsers);
          setRiskScore(riskScore);
          setScoreChange(scoreChange);
          setRiskScoreOverAllTime(riskScoreOverTime);
          setAreasToImprove(areasToImprove);
          setComplianceFrameworks(complianceFrameworks);

          updateRiskScoreOverTime(90); // Default value is 90.

          setDepartmentDataCache((prevCache) => ({
            ...prevCache,
            [department]: {
              totalUsers,
              riskScore,
              scoreChange,
              areasToImprove,
              complianceFrameworks,
              riskScoreOverAllTime,
            },
          }));
        }
      } else {
        setTotalUsers(0); // To change

        let updatedRiskScore = 0;

        if (department === "Capital") {
          updatedRiskScore = 61;
        } else if (department === "Founder Scouting") {
          // Engineering
          updatedRiskScore = 26;
        } else if (department === "Global Support") {
          updatedRiskScore = 9;
        } else if (department === "Technology") {
          // Information Technology
          updatedRiskScore = 43;
        } else if (department === "Investment") {
          updatedRiskScore = 36;
        } else if (department === "Legal & Finance") {
          // Legal
          updatedRiskScore = 52;
        } else if (department === "Marketing & Communications") {
          updatedRiskScore = 8;
        } else if (department === "Portfolio") {
          updatedRiskScore = 9;
        } else if (department === "Program") {
          updatedRiskScore = 15;
        }

        setRiskScore(updatedRiskScore);

        // Demo account
        let riskScoreOverTime = [];

        const currentDate = new Date(); // Start with today's date.
        currentDate.setDate(currentDate.getDate() - 90);

        let currentRiskScore = 0;
        const targetScore = updatedRiskScore;

        if (targetScore >= 50) {
          // Higher risk
          currentRiskScore = 30;
        } else {
          // Lower risk
          currentRiskScore = 75;
        }

        const totalDays = 90;

        const options = {
          month: "short",
          day: "numeric",
        };

        for (let i = 0; i < totalDays; i++) {
          const formattedDate = currentDate.toLocaleDateString(
            "en-US",
            options,
          );

          let stepChange = 0;

          if (targetScore >= 50) {
            stepChange = (targetScore - currentRiskScore) / (totalDays - i);
          } else {
            stepChange = (currentRiskScore - targetScore) / (totalDays - i);
          }

          let variability = (Math.random() - 0.5) * stepChange;

          let fluctuation =
            Math.sin(((2 * Math.PI) / totalDays) * i) * stepChange;

          if (targetScore >= 50) {
            currentRiskScore += stepChange + variability + fluctuation;
          } else {
            currentRiskScore -= stepChange + variability + fluctuation;
          }

          if (targetScore >= 50) {
            if (i === totalDays - 1 && currentRiskScore < targetScore) {
              currentRiskScore = targetScore;
            } else if (updatedRiskScore > targetScore) {
              currentRiskScore = targetScore - Math.random();
            }
          } else {
            if (i === totalDays - 1 && currentRiskScore > targetScore) {
              currentRiskScore = targetScore;
            } else if (updatedRiskScore < targetScore) {
              currentRiskScore = targetScore + Math.random();
            }
          }

          riskScoreOverTime.push({
            date: formattedDate,
            riskScore: Math.round(currentRiskScore),
          });

          currentDate.setDate(currentDate.getDate() + 1);
        }

        let scoreChange = 0;

        if (
          riskScoreOverTime.length &&
          riskScoreOverTime[0].riskScore &&
          updatedRiskScore
        ) {
          scoreChange = updatedRiskScore - riskScoreOverTime[0].riskScore;
        }

        setScoreChange(scoreChange);

        setRiskScoreOverTime(riskScoreOverTime);
        setRiskScoreOverAllTime(riskScoreOverTime);

        setAreasToImprove([
          {
            name: "Impersonation of internal processes",
          },
          {
            name: "Link manipulation",
          },
        ]);

        setComplianceFrameworks([
          {
            name: "SOC 2 Type 2",
            progress: 0.92,
          },
          {
            name: "HIPAA",
            progress: 0.94,
          },
          {
            name: "ISO 27001",
            progress: 0.91,
          },
        ]);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [departmentDataCache, email, updateRiskScoreOverTime],
  );

  const loadTrainingCompletion = async (department) => {
    const trainingCompletionStatuses = await getTrainingCompletion(department);

    if (Object.keys(trainingCompletionStatuses.error).length > 0) {
      console.error(trainingCompletionStatuses.error.message);
    } else {
      const { trainingCompletion, users } = trainingCompletionStatuses.result;

      setTrainingCompletion(trainingCompletion);
      setTrainingCompletionUsers(users);
    }
  };

  const loadUsersByDepartment = async (department) => {
    const usersData = await getUsersByDepartment(department);

    if (Object.keys(usersData.error).length > 0) {
      console.error(usersData.error.message);
    } else {
      const { users } = usersData.result;

      setEmployeesByDepartment(users);
    }
  };

  useEffect(() => {
    const loadDepartments = async () => {
      setIsPlatformLoading(true);

      // Change data for the demo account.
      if (email === "david@dune.demo") {
        const departments = [
          {
            name: "Capital",
          },
          {
            name: "Engineering", // Founder Scouting
          },
          {
            name: "Global Support",
          },
          {
            name: "Information Technology", // Technology
          },
          {
            name: "Investment",
          },
          {
            name: "Legal", // Legal & Finance
          },
          {
            name: "Marketing & Communications",
          },
          {
            name: "Portfolio",
          },
          {
            name: "Program",
          },
        ];
        const selectedDepartment = "Capital";

        setDepartments(departments);
        setSelectedDepartment(selectedDepartment);

        loadUsersByDepartment(selectedDepartment);
        await loadInsightsDepartment(selectedDepartment);
        // await loadTrainingCompletion(selectedDepartment);
      } else {
        const departmentsData = await getDepartments();

        if (Object.keys(departmentsData.error).length > 0) {
          console.error(departmentsData.error.message);
        } else {
          const { departments } = departmentsData.result;

          setDepartments(departments);

          if (departments.length > 0) {
            setSelectedDepartment(departments[0].name);
            loadUsersByDepartment(departments[0].name);
            loadInsightsDepartment(departments[0].name);
            await loadTrainingCompletion(departments[0].name);
          }
        }
      }
    };

    if (!isInitialLoading && email) {
      loadDepartments().finally(() => {
        setIsPlatformLoading(false);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialLoading, email]);

  useEffect(() => {
    if (riskScoreOverAllTime.length > 0) {
      updateRiskScoreOverTime(90); // Default value is 90.
    }
  }, [riskScoreOverAllTime, updateRiskScoreOverTime]);

  return (
    <Dashboard
      pageTitle={pagesPlatform.INSIGHTS.subpages.DEPARTMENTS}
      departments={departments}
      selectedDepartment={selectedDepartment}
      totalUsers={totalUsers}
      riskScore={riskScore}
      scoreChange={scoreChange}
      riskScoreOverTime={riskScoreOverTime}
      areasToImprove={areasToImprove}
      employeesByDepartment={employeesByDepartment}
      trainingCompletion={trainingCompletion}
      trainingCompletionUsers={trainingCompletionUsers}
      complianceFrameworks={complianceFrameworks}
      onChangeDepartment={handleChangeDepartment}
      onChangeSelectedDays={handleChangeSelectedDays}
      onClickShareReport={handleClickShareReport}
    />
  );
}

export default InsightsDepartment;
