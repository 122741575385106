import React from "react";

const GraduationCapFilledIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 24 22"
      fill="none"
    >
      <path
        d="M16.5 17.4287C17.0174 17.2249 17.5188 16.9827 18 16.7041V20.5C18 20.6989 17.921 20.8897 17.7803 21.0303C17.6397 21.171 17.4489 21.25 17.25 21.25C17.0511 21.25 16.8603 21.171 16.7197 21.0303C16.579 20.8897 16.5 20.6989 16.5 20.5V17.4287ZM17.6025 9.13844L12.3525 6.33812C12.1773 6.24866 11.9741 6.23158 11.7864 6.29056C11.5988 6.34955 11.4418 6.47989 11.3494 6.65349C11.2569 6.82709 11.2364 7.03006 11.2921 7.21868C11.3479 7.40729 11.4755 7.56645 11.6475 7.66187L16.0313 10L17.625 9.15062L17.6025 9.13844ZM23.6025 6.33812L12.3525 0.338122C12.244 0.280342 12.1229 0.250122 12 0.250122C11.8771 0.250122 11.756 0.280342 11.6475 0.338122L0.39751 6.33812C0.277514 6.40207 0.17716 6.49744 0.107185 6.61403C0.0372091 6.73061 0.000244141 6.86402 0.000244141 7C0.000244141 7.13597 0.0372091 7.26938 0.107185 7.38597C0.17716 7.50255 0.277514 7.59792 0.39751 7.66187L3.00001 9.05031V13.5897C2.99914 13.9581 3.13474 14.3138 3.38064 14.5881C4.60876 15.9559 7.36032 18.25 12 18.25C13.5384 18.2627 15.0653 17.9841 16.5 17.4287V10.2503L16.0313 10L12 12.1497L4.10907 7.9375L2.34376 7L12 1.85031L21.6563 7L19.8956 7.9375H19.89L17.625 9.15062C17.739 9.21646 17.8337 9.31117 17.8996 9.42521C17.9654 9.53926 18 9.66863 18 9.80031V16.7041C18.978 16.1395 19.8618 15.4256 20.6194 14.5881C20.8653 14.3138 21.0009 13.9581 21 13.5897V9.05031L23.6025 7.66187C23.7225 7.59792 23.8229 7.50255 23.8928 7.38597C23.9628 7.26938 23.9998 7.13597 23.9998 7C23.9998 6.86402 23.9628 6.73061 23.8928 6.61403C23.8229 6.49744 23.7225 6.40207 23.6025 6.33812Z"
        fill="white"
      />
    </svg>
  );
};

export default GraduationCapFilledIcon;
