import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import {
  Box,
  Stack,
  Typography,
  Tabs,
  Tab,
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import {
  AdsClickOutlined,
  AnalyticsOutlined,
  FeedbackOutlined,
  StarBorderOutlined,
} from "@mui/icons-material";

import { DuneButton, FadeInSection, LogoItem } from "../../../components";

import BootcampCarousel from "./resource-carousel";

// import { createClient } from "contentful";

import EcosystemDiagram from "./utils/ecosystem-diagram/ecosystem-diagram";

import InvisibleLogo from "./utils/invisible-logo/invisible-logo";
import NetlifyLogo from "./utils/netlify-logo/netlify-logo";
import WarnerMusicGroupLogo from "./utils/warner-music-group-logo/warner-music-group-logo";
import SaveTimeLogo from "./utils/savetime-logo/savetime-logo";
import SaveMoneyLogo from "./utils/savemoney-logo/savemoney-logo";
import ReduceRiskLogo from "./utils/reducerisk-logo/reducerisk-logo";
import AssignmentOutlinedIcon from "./utils/assignmentoutlined-icon/assignmentoutlined-icon";
import VerifiedUserOutlinedIcon from "./utils/verifieduseroutlined-icon/verifieduseroutlined-icon";

import { Translate } from "react-auto-translate";

import "./home.css";
import GreenItalicText from "../../../components/common/green-italic-text/green-italic-text";
import HugoBossLogo from "./utils/hugo-boss-logo/hugo-boss-logo";
import CulliganLogo from "./utils/culligan-logo/culligan-logo";
import CisoScrollSection from "../../../components/common/ciso-scrolling/ciso-scrolling";
import PersonIcon from "./utils/person-icon/person-icon";
import ViewInArOutlinedIcon from "./utils/view-in-ar-outlined-icon/view-in-ar-outlined-icon";

function Home() {
  const customersDesktop = [
    {
      name: "Netlify",
      logo: <NetlifyLogo />,
    },
    {
      name: "Warner Music Group",
      logo: <WarnerMusicGroupLogo />,
    },
    {
      name: "Hugo Boss",
      logo: <HugoBossLogo />,
    },
    {
      name: "Culligan",
      logo: <CulliganLogo />,
    },
    {
      name: "Invisible",
      logo: <InvisibleLogo />,
    },
  ];

  const customersMobile = [
    {
      name: "Netlify",
      logo: <NetlifyLogo />,
    },
    {
      name: "Warner Music Group",
      logo: <WarnerMusicGroupLogo />,
    },
    {
      name: "Hugo Boss",
      logo: <HugoBossLogo />,
    },
    {
      name: "Culligan",
      logo: <CulliganLogo />,
    },
    {
      name: "Invisible",
      logo: <InvisibleLogo />,
    },
  ];

  const threats = [
    {
      title: "Increased Use of Generative AI for Attacks",
      icon: "https://d3oo9a669kwmx7.cloudfront.net/icons/connection.gif",
      staticIcon: "https://d3oo9a669kwmx7.cloudfront.net/icons/connection.png",
      description:
        "Cybercriminals use AI tools like ChatGPT to create highly convincing phishing attacks, increasing both sophistication and scale.",
    },
    {
      title: "Advanced Persistent Threats and State-Sponsored Attacks",
      icon: "https://d3oo9a669kwmx7.cloudfront.net/icons/cyberterrorism.gif",
      staticIcon:
        "https://d3oo9a669kwmx7.cloudfront.net/icons/cyberterrorism.png",
      description:
        "APTs and state-sponsored attacks use complex social engineering techniques to gain and maintain long-term access to targeted systems.",
    },
    {
      title: "Rise of Business Email Compromise (BEC)",
      icon: "https://d3oo9a669kwmx7.cloudfront.net/icons/message.gif",
      staticIcon: "https://d3oo9a669kwmx7.cloudfront.net/icons/message.png",
      description:
        "BEC attacks manipulate employees into transferring funds or disclosing sensitive information by impersonating executives or trusted partners.",
    },
  ];

  const categorySolutions = [
    {
      category: "Security Awareness Training",
      solutions: [
        {
          idx: 1,
          title: "User-Adaptation",
          icon: <PersonIcon />,
          description:
            "Training modules adjust based on user behavior and risk level, enhancing learning effectiveness and engagement.",
          imgUrl:
            "https://d3oo9a669kwmx7.cloudfront.net/website-home-categorySolutions/Dune_Security_User_Adaptation.png",
        },
        {
          idx: 2,
          title: "Real Time Feedback",
          icon: <FeedbackOutlined />,
          description:
            "Immediate, personalized interventions reinforce positive behaviors and address potential vulnerabilities, proactively mitigating risks.",
          imgUrl:
            "https://d3oo9a669kwmx7.cloudfront.net/website-home-categorySolutions/Dune_Security_User_Adaptation_2.png",
        },
        {
          idx: 3,
          title: "Comprehensive Reporting",
          icon: <AssignmentOutlinedIcon />,
          description:
            "Comprehensive analytics provide actionable intelligence on user progress, risk reduction trends, and overall security posture improvements, facilitating strategic decision-making and simplified compliance reporting.",
          imgUrl:
            "https://d3oo9a669kwmx7.cloudfront.net/website-home-categorySolutions/Dune_Security_User_Adaptation_3.png",
        },
        {
          idx: 4,
          title: "Automatic Compliance Training",
          icon: <VerifiedUserOutlinedIcon />,
          description:
            "Automate and optimize adherence to industry standards and regulations, ensuring thorough coverage while reducing administrative burden.",
          imgUrl:
            "https://d3oo9a669kwmx7.cloudfront.net/website-home-categorySolutions/Dune_Security_User_Adaptation_4.png",
        },
      ],
    },
    {
      category: "Red Team Social Engineering",
      solutions: [
        {
          idx: 1,
          title: "Hyper-Realistic Simulations",
          icon: <ViewInArOutlinedIcon />,
          description:
            "Test defenses with sophisticated, real-world attack scenarios customized to your organization, proactively identifying and addressing critical vulnerabilities.",
          imgUrl:
            "https://d3oo9a669kwmx7.cloudfront.net/website-home-categorySolutions/Dune_Security_User_Adaptation_5.png",
        },
        {
          idx: 2,
          title: "Sophisticated and Specific",
          icon: <StarBorderOutlined />,
          description:
            "Utilize advanced techniques like phishing, smishing, vishing, and deepfakes to evaluate responses and gain strategic insights.",
          imgUrl:
            "https://d3oo9a669kwmx7.cloudfront.net/website-home-categorySolutions/Group_48095492.png",
        },
        {
          idx: 3,
          title: "Comprehensive Assessments",
          icon: <AnalyticsOutlined />,
          description:
            "Detailed, actionable reports outline vulnerabilities and remediation strategies, enhancing overall security posture.",
          imgUrl:
            "https://d3oo9a669kwmx7.cloudfront.net/website-home-categorySolutions/Dune_Security_User_Adaptation_6.png",
        },
        {
          idx: 4,
          title: "Direct Engagement",
          icon: <AdsClickOutlined />,
          description:
            "Work with SOCs and CISOs to customize threat scenarios and enhance security measures, delivering tailored and impactful insights.",
          imgUrl:
            "https://d3oo9a669kwmx7.cloudfront.net/website-home-categorySolutions/Group_48095491.png",
        },
      ],
    },
  ];

  const aiPoints = [
    {
      id: 0,
      title: "Quantify Employee Risk",
      description:
        "Comprehensively quantify user risk using a variety of input source data, and aggregate the results at organizational, departmental, manager, and other levels.",
      picture: "https://d3oo9a669kwmx7.cloudfront.net/ai-quantify.png",
    },
    {
      id: 1,
      title: "Reduce Employee Risk",
      description:
        "Quantifiably and automatically reduce risk for users through adaptive, timely training tailored to individual and departmental needs in real-time. Ensure compliance and robust protection by continuously enhancing employee awareness and behavior.",
      picture: "https://d3oo9a669kwmx7.cloudfront.net/ai-reduce.png",
    },
    {
      id: 2,
      title: "Adapt Security Controls Around Risk",
      description:
        "For the highest-risk users, seamlessly integrate with your IDP and existing tech stack to dynamically apply restrictions, adjust permissions, and enhance security controls, such as email filters.",
      picture: "https://d3oo9a669kwmx7.cloudfront.net/ai-adapt.png",
    },
  ];

  const benefitsIconClassName = "home-icon-benefit";
  const benefits = [
    {
      title: "Save Time",
      icon: <SaveTimeLogo />,
      description:
        "Automate cybersecurity and reduce manual security tasks like campaign scheduling, training assignments, security controls and reporting.",
    },
    {
      title: "Reduce Risk",
      icon: <ReduceRiskLogo />,
      description:
        "Leverage AI to detect and mitigate risks by analyzing user behavior, offering tailored training and dynamic security to prevent breaches.",
    },
    {
      title: "Save Money",
      icon: <SaveMoneyLogo className={benefitsIconClassName} />,
      description:
        "Lower security costs with targeted interventions for high-risk users, cutting down on breaches and inefficient training programs.",
    },
  ];

  const investors = [
    {
      name: "Craft",
      picture: "https://d3oo9a669kwmx7.cloudfront.net/logos/craft-logo.png",
    },
    {
      name: "Alumni Ventures",
      picture:
        "https://d3oo9a669kwmx7.cloudfront.net/logos/alumni-ventures-logo.png",
    },
    {
      name: "Antler",
      picture: "https://d3oo9a669kwmx7.cloudfront.net/logos/antler-logo.png",
    },
    {
      name: "Firestreak",
      picture:
        "https://d3oo9a669kwmx7.cloudfront.net/logos/firestreak-logo.png",
    },
    {
      name: "Sequoia Scout",
      picture:
        "https://d3oo9a669kwmx7.cloudfront.net/logos/sequoia-scout-logo.png",
    },
  ];

  const [selectedTabSolution, setSelectedTabSolution] = useState(
    categorySolutions[0].solutions[0],
  );
  const [selectedTabSolutionTitle, setSelectedTabSolutionTitle] = useState(
    categorySolutions[0].solutions[0].title,
  );
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);
  const [currentSolutionIndex, setCurrentSolutionIndex] = useState(0);

  const [selectedAIPoint, setSelectedAIPoint] = useState(aiPoints[0]);
  // const [resources, setResources] = useState([]);
  const [hoveredThreat, setHoveredThreat] = useState(null);

  const productRef = useRef(null);

  const location = useLocation();

  const handleChangeSelectSolution = (event) => {
    setSelectedTabSolutionTitle(event.target.value);

    const allSolutions = categorySolutions
      .flatMap((category) => category.solutions)
      .sort((a, b) => a.idx - b.idx); // Sort based on idx

    const filteredSolutions = allSolutions.filter(
      (solution) => solution.title === event.target.value,
    );

    if (filteredSolutions.length > 0) {
      setSelectedTabSolution(filteredSolutions[0]);
    }
  };

  const handleChangeTabSolution = (event, newValue) => {
    setSelectedTabSolutionTitle(newValue);

    const allSolutions = categorySolutions
      .flatMap((category) => category.solutions)
      .sort((a, b) => a.idx - b.idx); // Sort based on idx

    const filteredSolutions = allSolutions.filter(
      (solution) => solution.title === newValue,
    );

    if (filteredSolutions.length > 0) {
      setSelectedTabSolution(filteredSolutions[0]);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentCategorySolutions =
        categorySolutions[currentCategoryIndex].solutions;

      setCurrentSolutionIndex((prevSolutionIndex) => {
        const nextSolutionIndex =
          (prevSolutionIndex + 1) % currentCategorySolutions.length;

        setSelectedTabSolution(currentCategorySolutions[nextSolutionIndex]);
        setSelectedTabSolutionTitle(
          currentCategorySolutions[nextSolutionIndex].title,
        );

        return nextSolutionIndex;
      });

      setCurrentCategoryIndex((prevCategoryIndex) => {
        if (currentSolutionIndex === currentCategorySolutions.length - 1) {
          return (prevCategoryIndex + 1) % categorySolutions.length;
        }
        return prevCategoryIndex;
      });
    }, 10000000000);

    return () => clearInterval(intervalId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSolutionIndex, currentCategoryIndex]);

  useEffect(() => {
    const aiPointTimeout = setTimeout(() => {
      const currentAIPointID = selectedAIPoint.id;

      if (currentAIPointID === aiPoints.length - 1) {
        setSelectedAIPoint(aiPoints[0]);
      } else {
        const filteredAIPoints = aiPoints.filter(
          (aiPoint) => aiPoint.id === currentAIPointID + 1,
        );

        if (filteredAIPoints.length > 0) {
          setSelectedAIPoint(filteredAIPoints[0]);
        }
      }
    }, 5000);

    return () => clearTimeout(aiPointTimeout);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aiPoints]);

  useEffect(() => {
    if (location.pathname === "/product/") {
      setTimeout(() => {
        productRef.current.scrollIntoView({
          behavior: "smooth",
        });
      }, 100);
    }
  }, [location]);

  // useEffect(() => {
  //   const fetchResources = async () => {
  //     if (process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN) {
  //       let fetchedResourcesBootcamp = [];
  //       let fetchedResourcesTeam = [];

  //       const client = createClient({
  //         space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  //         accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
  //       });

  //       await client
  //         .getEntries({
  //           content_type: "resourcesBootcamp",
  //           limit: 3,
  //           order: "-fields.date,fields.title",
  //         })
  //         .then((response) => {
  //           fetchedResourcesBootcamp = response.items.map((item) => {
  //             const { fields, sys } = item;

  //             return {
  //               id: sys.id,
  //               title: fields.title,
  //               href: `/resources/bootcamp/${fields.resourceID}`,
  //               thumbnailURL: fields.thumbnail.fields.file.url,
  //               date: fields.date,
  //             };
  //           });
  //         })
  //         .catch(console.error);

  //       await client
  //         .getEntries({
  //           content_type: "resourcesTeam",
  //           limit: 3,
  //           order: "-fields.date,fields.title",
  //         })
  //         .then((response) => {
  //           fetchedResourcesTeam = response.items.map((item) => {
  //             const { fields, sys } = item;

  //             return {
  //               id: sys.id,
  //               title: fields.title,
  //               href: fields.resourceURL || "",
  //               thumbnailURL: fields.thumbnail.fields.file.url,
  //               date: fields.date,
  //             };
  //           });
  //         })
  //         .catch(console.error);

  //       const fetchedResourcesMostRecent = [
  //         ...fetchedResourcesBootcamp,
  //         ...fetchedResourcesTeam,
  //       ];

  //       fetchedResourcesMostRecent.sort((a, b) => {
  //         const dateA = new Date(a.date);
  //         const dateB = new Date(b.date);

  //         if (dateA < dateB) {
  //           return 1;
  //         } else if (dateA > dateB) {
  //           return -1;
  //         }

  //         return a.title.localeCompare(b.title);
  //       });

  //       setResources(fetchedResourcesMostRecent.slice(0, 3));
  //     }
  //   };

  //   fetchResources();
  // }, []);

  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    if (percentage < 90) {
      const interval = setInterval(() => {
        setPercentage((prevPercentage) => prevPercentage + 1);
      }, 15);

      return () => clearInterval(interval);
    }
  }, [percentage]);

  const aiPointsRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isScrollingThroughAIPoints, setIsScrollingThroughAIPoints] =
    useState(false);
  const [aiPointsInView, setAiPointsInView] = useState(false);

  useEffect(() => {
    let debounceTimeout;

    const handleScroll = (event) => {
      event.preventDefault();

      clearTimeout(debounceTimeout);

      debounceTimeout = setTimeout(() => {
        if (event.deltaY > 0) {
          if (currentIndex < aiPoints.length - 1) {
            setCurrentIndex((prevIndex) => prevIndex + 1);
          } else {
            setIsScrollingThroughAIPoints(false);
          }
        } else if (event.deltaY < 0) {
          if (currentIndex > 0) {
            setCurrentIndex((prevIndex) => prevIndex - 1);
          } else {
            setIsScrollingThroughAIPoints(false);
          }
        }
      }, 0);
    };

    const handleKeyDown = (event) => {
      if (!isScrollingThroughAIPoints && aiPointsInView) {
        if (event.key === "ArrowDown" || event.key === "ArrowUp") {
          setIsScrollingThroughAIPoints(true);
        }
      }
    };

    const handleScrollLock = () => {
      if (window.scrollY > 0 && !isScrollingThroughAIPoints) {
        setIsScrollingThroughAIPoints(true);
      }
    };

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && entry.intersectionRatio === 1) {
          setIsScrollingThroughAIPoints(true);
        } else {
          setIsScrollingThroughAIPoints(false);
        }
      },
      { threshold: 1.0 },
    );

    if (aiPointsRef.current) {
      observer.observe(aiPointsRef.current);
    }

    if (isScrollingThroughAIPoints) {
      window.addEventListener("wheel", handleScroll, { passive: false });
      window.addEventListener("keydown", handleKeyDown);
      window.addEventListener("scroll", handleScrollLock);
    } else {
      window.removeEventListener("wheel", handleScroll, { passive: false });
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("scroll", handleScrollLock);
    }

    return () => {
      clearTimeout(debounceTimeout);
      window.removeEventListener("wheel", handleScroll, { passive: false });
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("scroll", handleScrollLock);
      if (aiPointsRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(aiPointsRef.current);
      }
    };
  }, [
    isScrollingThroughAIPoints,
    currentIndex,
    aiPointsInView,
    aiPoints.length,
  ]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!isScrollingThroughAIPoints && aiPointsInView) {
        if (event.key === "ArrowDown" || event.key === "ArrowUp") {
          setIsScrollingThroughAIPoints(true);
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isScrollingThroughAIPoints, aiPointsInView]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setAiPointsInView(entry.isIntersecting);
        setIsScrollingThroughAIPoints(entry.isIntersecting);
      },
      { threshold: 0.5 },
    );

    if (aiPointsRef.current) {
      observer.observe(aiPointsRef.current);
    }

    return () => {
      if (aiPointsRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(aiPointsRef.current);
      }
    };
  }, []);

  return (
    <>
      <FadeInSection>
        <div
          className="flex justify-center px-1.875 bg-[image:var(--image-url)] bg-center bg-no-repeat"
          style={{
            "--image-url": `url('https://d3oo9a669kwmx7.cloudfront.net/backgrounds/home-main-background.png')`,
          }}
        >
          <div className="max-w-78.75 w-full">
            <div className="max-w-full w-full py-6.25 md:pt-11.25 pl-0 md:pl-1.75">
              <div className="text-white text-h1">
                <span>
                  <Translate>User Adaptive Risk Management, </Translate>
                </span>
                <p>
                  <GreenItalicText text="powered by AI" />
                </p>
              </div>
              <p className="mt-0.75 text-white max-w-11.5 md:max-w-43.75 text-h4">
                <Translate>
                  Prevent sophisticated social engineering attacks with user
                  adaptive security awareness training, red team analysis, and
                  adaptive controls.
                </Translate>
              </p>
              <div className="mt-1.75 max-w-full">
                <Link to="/demo/">
                  <DuneButton variant="dune-glow" text="Learn more" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </FadeInSection>
      <FadeInSection>
        <div className="py-2.5 px-1.875 flex justify-center">
          <div className="flex-wrap items-center justify-between hidden w-full md:flex">
            {customersDesktop.map((customer, index) => (
              <div
                key={`customer-desktop-${index}`}
                className="h-3.375 my-1 flex justify-center items-center"
              >
                {customer.logo}
              </div>
            ))}
          </div>
          <div className="flex flex-col items-center justify-center md:hidden">
            {customersMobile.map((customer, index) => (
              <div key={`customer-mobile-${index}`} className="h-3.375 my-1.5">
                {customer.logo}
              </div>
            ))}
          </div>
        </div>
      </FadeInSection>
      <FadeInSection>
        <div
          className="bg-green-background flex justify-center py-3.75 xs:mt-5 md:mt-3.75"
          ref={productRef}
        >
          <div className={`max-w-full md:max-w-78.75 w-full`}>
            <div className="w-[90%] mx-auto text-center">
              <span
                className="text-center text-white leading-[1.2] text-h1"
                textAlign="center"
              >
                <GreenItalicText
                  className={`text-right inline-block w-3`}
                  text={percentage}
                />
                <GreenItalicText text="% " />{" "}
                <Translate>of breaches start with human behavior</Translate>
              </span>
            </div>
            <div className="w-[65%] mx-auto">
              <p className="px-1 mt-1 text-center text-white text-h4 md:px-0">
                <Translate>
                  Historically the biggest challenge for enterprise security
                  teams, this trend is worsening with new trends that increase
                  the sophistication and scale of attacks on end users.
                </Translate>
              </p>
            </div>
            <div className="flex flex-wrap justify-center md:justify-between items-center mt-3.75 w-full">
              {threats.map((threat, index) => (
                <div
                  className={`max-w-21.875 w-full ${
                    index > 0 ? "mt-5" : "mt-0"
                  } md:mt-0`}
                  key={`threat-${index}`}
                >
                  <div className="flex justify-center h-8">
                    <img
                      className="rounded-small bg-white p-1.25"
                      onMouseEnter={() => setHoveredThreat(index)}
                      onMouseLeave={() => setHoveredThreat(null)}
                      src={
                        hoveredThreat === index
                          ? threat.staticIcon
                          : threat.icon
                      }
                      alt={threat.title}
                    />
                  </div>
                  <div>
                    <div className="text-h3 text-center font-medium leading-[1.2] text-white flex md:min-h-6 min-h-5 items-start mt-1">
                      <Translate>{threat.title}</Translate>
                    </div>
                  </div>
                  <div className="text-body md:min-h-6">
                    <p className="text-center text-white text-h5">
                      <Translate>{threat.description}</Translate>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </FadeInSection>
      <FadeInSection>
        <div className="md:max-w-78.75 w-full mx-auto">
          <Box
            display="flex"
            justifyContent="center"
            marginTop={{
              xs: "80px",
              md: "120px",
            }}
            padding="0 30px"
          >
            <Box width="100%">
              <div>
                <Typography
                  variant="h2"
                  component="h2"
                  className="home-text-header-secondary"
                  textAlign="center"
                >
                  <span className="text-h1.5">
                    <Translate>
                      <LogoItem />{" "}
                      <span>identifies and resolves risky behavior, </span>
                    </Translate>
                    <GreenItalicText text=" automatically" />
                  </span>
                </Typography>
              </div>
              <Box>
                <Typography
                  variant="body-gray"
                  component="p"
                  textAlign="center"
                  marginTop="12px"
                >
                  <span className="text-h4">
                    <Translate>
                      Our configurable solutions enable you to build a program
                      tailored to your organization's needs.
                    </Translate>
                  </span>
                </Typography>
              </Box>
              <Stack
                direction={{
                  xs: "column",
                  md: "row",
                }}
                justifyContent="center"
                marginTop="60px"
              >
                <Box
                  className="home-box-solutions-tabs"
                  maxWidth={{
                    xs: "100%",
                    md: "364px",
                  }}
                  padding={{
                    xs: "0",
                    md: "28.3px",
                  }}
                >
                  <Box display={{ xs: "none", md: "block" }}>
                    {categorySolutions.map(
                      (categoryItem, categoryItemIndex) => (
                        <>
                          <Box
                            marginTop={categoryItemIndex > 0 ? "50px" : 0}
                            key={`category-item-${categoryItemIndex}`}
                          >
                            <Typography
                              component="span"
                              className="home-text-category-solution-name"
                            >
                              <Translate>{categoryItem.category}</Translate>
                            </Typography>
                          </Box>
                          <Box marginTop="10px">
                            <Tabs
                              className="home-tabs"
                              orientation="vertical"
                              value={selectedTabSolution.title}
                              onChange={handleChangeTabSolution}
                            >
                              {categoryItem.solutions.map(
                                (solution, solutionIndex) => (
                                  <Tab
                                    className="home-tab"
                                    icon={solution.icon}
                                    iconPosition="start"
                                    label={
                                      <Translate>{solution.title}</Translate>
                                    }
                                    value={solution.title}
                                    key={`category-item-${categoryItemIndex}-solution-${solutionIndex}`}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      // gap: "5px",
                                      marginRight: "5px",
                                    }}
                                  />
                                ),
                              )}
                            </Tabs>
                          </Box>
                        </>
                      ),
                    )}
                  </Box>
                  <Box display={{ xs: "block", md: "none" }}>
                    <Select
                      backgroundColor="black"
                      value={selectedTabSolutionTitle}
                      onChange={handleChangeSelectSolution}
                      fullWidth
                      variant="outlined"
                      displayEmpty
                      renderValue={(selected) => (
                        <Box display="flex" alignItems="center">
                          <ListItemIcon
                            sx={{ color: "white", minWidth: "40px" }}
                          >
                            {
                              categorySolutions
                                .flatMap((cat) => cat.solutions)
                                .find((sol) => sol.title === selected)?.icon
                            }
                          </ListItemIcon>
                          <ListItemText primary={selected} />
                        </Box>
                      )}
                      sx={{
                        color: "white",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                    >
                      {categorySolutions
                        .map((categoryItem, categoryItemIndex) => [
                          <ListSubheader
                            key={`subheader-${categoryItemIndex}`}
                            sx={{
                              backgroundColor: "black",
                              color: "#FFFFFF",
                              fontSize: "14px",
                              fontWeight: 400,
                              textTransform: "uppercase",
                              letterSpacing: "0.1em",
                              padding: "16px 16px 8px",
                              lineHeight: "20px",
                            }}
                          >
                            <Translate>{categoryItem.category}</Translate>
                          </ListSubheader>,
                          ...categoryItem.solutions.map(
                            (solution, solutionIndex) => (
                              <MenuItem
                                value={solution.title}
                                className="dune-menu-item"
                                key={`mobile-category-item-${categoryItemIndex}-solution-${solutionIndex}`}
                                sx={{
                                  backgroundColor: "black",
                                  color: "white",
                                  "&.Mui-selected": {
                                    backgroundColor: "#262525 !important",
                                  },
                                  "&.Mui-selected:hover": {
                                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                                  },
                                }}
                              >
                                <Box display="flex" alignItems="center">
                                  <ListItemIcon
                                    sx={{ color: "white", minWidth: "40px" }}
                                  >
                                    <div className="mx-0.625">
                                      {solution.icon}
                                    </div>
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={
                                      <Translate>{solution.title}</Translate>
                                    }
                                    primaryTypographyProps={{
                                      variant: "body1",
                                    }}
                                  />
                                </Box>
                              </MenuItem>
                            ),
                          ),
                        ])
                        .flat()}
                    </Select>
                  </Box>
                </Box>
                <Box
                  maxWidth="600px"
                  width="100%"
                  marginLeft={{
                    xs: 0,
                    md: "60px",
                  }}
                  marginTop={{
                    xs: "30px",
                    md: 0,
                  }}
                >
                  <Stack
                    direction="column"
                    justifyContent="space-between"
                    alignItems="center"
                    height="100%"
                  >
                    <Box
                      className="home-box-background-gray"
                      height={{
                        xs: "240px",
                        md: "385px",
                      }}
                      width="100%"
                      sx={{
                        background: "inherit !important",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <figure>
                        <img
                          src={selectedTabSolution.imgUrl}
                          alt="solution"
                          className="w-full h-full"
                        />
                      </figure>
                    </Box>
                    <Box
                      className="home-box-background-gray"
                      padding="20px"
                      marginTop="10px"
                    >
                      <Box>
                        <Typography variant="h3" component="h3">
                          {<Translate>{selectedTabSolutionTitle}</Translate>}
                        </Typography>
                      </Box>
                      <Box marginTop="10px">
                        <Typography variant="body-gray" component="p">
                          {
                            <Translate>
                              {selectedTabSolution.description}
                            </Translate>
                          }
                        </Typography>
                      </Box>
                    </Box>
                  </Stack>
                </Box>
              </Stack>
            </Box>
          </Box>
        </div>
      </FadeInSection>
      <FadeInSection>
        <EcosystemDiagram />
      </FadeInSection>
      <FadeInSection>
        <div
          className="w-full md:max-w-78.75 mx-auto flex flex-col md:flex-row-reverse justify-center items-center scroll-smooth mt-7.5"
          ref={aiPointsRef}
        >
          <div className="w-21.875 md:w-37.5 relative mb-1 md:mb-0 overflow-hidden">
            {aiPoints.map((point, index) => (
              <div
                key={point.id}
                className={`${
                  currentIndex === index ? "opacity-100" : "opacity-0"
                } transition-opacity duration-500 ease-in-out ${
                  currentIndex === index ? "relative" : "absolute"
                } w-full top-0 left-0 mt-3.125`}
              >
                <img
                  src={point.picture}
                  alt={<Translate>{point.title}</Translate>}
                  style={{ width: "100%", height: "auto", display: "block" }}
                />
              </div>
            ))}
          </div>
          <div className="flex flex-col items-center justify-center md:items-start">
            {aiPoints.map((point, index) => (
              <div
                className={`max-w-21.875 w-[80%] md:w-full mb-1 ${
                  currentIndex === index
                    ? "border border-0.031 border-gray border-opacity-35"
                    : "border border-0.031 border-transparent"
                } rounded-small p-1.25 text-white`}
                key={point.id}
              >
                <div className="text-h4 font-medium text-white mb-0.5">
                  {<Translate>{point.title}</Translate>}
                </div>
                <div className="text-white text-h5">
                  {<Translate>{point.description}</Translate>}
                </div>
              </div>
            ))}
          </div>
        </div>
      </FadeInSection>
      <FadeInSection>
        <div className={`flex justify-center xs:mt-5 md:mt-7.5 px-0 py-1.875`}>
          <div className={`max-w-full w-full mx-auto`}>
            <div>
              <div className="text-center text-white text-h1.5">
                <GreenItalicText text=" Secure " />
                <span>
                  <Translate>smarter,</Translate>
                </span>{" "}
                <GreenItalicText text=" operate " />
                <Translate> better, </Translate>
                <GreenItalicText text=" empower " />{" "}
                <Translate>your team.</Translate>
              </div>
              <div className="text-center mt-0.625 text-white text-h4">
                <Translate>
                  See how AI can personalize testing and training at the
                  organization and user level.
                </Translate>
              </div>
            </div>
            <div className="max-w-78.75 w-full mx-auto">
              <div className="md:flex xs:flex-col justify-center items-center mt-1.875">
                {benefits.map((benefit, index) => (
                  <div
                    className="bg-[#111110] md:flex items-center xs:flex-col mb-1.25 md:mb-0  border border-[#E9E9E7] border-opacity-30 p-0.5 rounded-small my-0 mx-0.5"
                    key={index}
                  >
                    <div className="rounded-small w-full mb-1.25 md:mb-0 mr-0 flex justify-center items-center">
                      {benefit.icon}
                    </div>
                    <div>
                      <div className="text-white text-h3">
                        {<Translate>{benefit.title}</Translate>}
                      </div>
                      <p className="mt-0.75 md:mt-0 text-h5 text-white">
                        {<Translate>{benefit.description}</Translate>}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </FadeInSection>
      <FadeInSection>
        <div className="flex justify-center mt-5 md:mt-7.5 py-1.875">
          <div className="w-full font-semibold">
            <div>
              <p className="text-h5 text-white text-center tracking-[9.6px] uppercase leading-[1.2]">
                <Translate>The CISO Advisory Council</Translate>
              </p>
            </div>
            <div className="mt-0.625">
              <div className="text-h1.5 leading-[1.2] text-white text-center">
                <Translate>Built</Translate> <GreenItalicText text="for " />
                <Translate>enterprise CISOs,</Translate>{" "}
                <GreenItalicText text="by " />
                <Translate>enterprise CISOs.</Translate>
              </div>
            </div>
          </div>
        </div>
        <CisoScrollSection />
      </FadeInSection>
      <FadeInSection>
        <div className="flex justify-center mt-5 md:mt-7.5 py-1.875">
          <div className={`max-w-full md:max-w-78.75 w-full`}>
            <div className="mt-0.625">
              <div className="text-h1.5 text-white text-center leading-[1.2]">
                <Translate>Best-in-class technology</Translate>{" "}
                <GreenItalicText text="with " />
                <Translate>best-in-class investors.</Translate>
              </div>
            </div>
            <div className="mt-1.875">
              <div
                className="flex flex-wrap items-center justify-between"
                container
              >
                {investors.map((investor, index) => (
                  <div
                    className={`
                    flex flex-wrap justify-center
                    ${index > 0 ? "mt-10" : "mt-0"} 
                    ${index > 1 ? "sm:mt-10" : "sm:mt-0"} 
                    ${index > 2 ? "md:mt-10" : "md:mt-0"} 
                    lg:mt-0
                    w-full sm:w-1/2 md:w-1/3 lg:w-auto
                  `}
                  >
                    <img
                      className={`
                        ${index % 2 !== 0 ? "sm:ml-0" : "ml-0"}
                        ${index % 3 !== 0 ? "md:ml-0" : "ml-0"}
                        ${index > 0 ? "lg:ml-0" : "ml-0"}
                        xs:ml-0 h-2.5 w-auto
                      `}
                      src={investor.picture}
                      alt={investor.name}
                      key={`investor-${index}`}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </FadeInSection>
      <FadeInSection>
        <div
          style={{
            "--image-url": `url('https://d3oo9a669kwmx7.cloudfront.net/backgrounds/meet-the-team.png')`,
          }}
          className="relative max-w-21.875 md:max-w-78.75 w-full mx-auto rounded-small border border-gray border-opacity-35 bg-[image:var(--image-url)] bg-center bg-cover bg-no-repeat px-1.25 py-2.5 mt-5"
        >
          <div className="relative z-10">
            <p className="text-white flex justify-center text-h1.5 md:text-center text-left">
              Meet the experts behind our cybersecurity solutions
            </p>
            <p className="text-h4 md:text-h4 text-white flex justify-center mt-0.625 md:text-center text-left">
              Learn more about the talent behind our innovative cybersecurity
              solutions.
            </p>
            <div className="flex justify-center mt-2 md:mt-1">
              <Link to="/company">
                <DuneButton variant="dune-glow" text="Meet the team" />
              </Link>
            </div>
          </div>
          <div className="absolute inset-0 z-0 bg-black border border-transparent opacity-75 rounded-small"></div>
        </div>
      </FadeInSection>
      <FadeInSection>
        <BootcampCarousel />
      </FadeInSection>
    </>
  );
}

export default Home;
