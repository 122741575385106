const capitalizeWords = (str) => {
  return str.replace(/\b\w+/g, (word) => {
    return word === "bec"
      ? "BEC"
      : word.charAt(0).toUpperCase() + word.slice(1);
  });
};

const formatAreasToImproveOrganization = (areasToImprove) => {
  if (!areasToImprove) {
    return [];
  }

  const formattedAreasToImproveOrganization = Object.entries(areasToImprove)
    .sort(([, a], [, b]) => b - a) // Sort by value (descending).
    .slice(0, 6) // Limit to top 6.
    .map(([name, count]) => ({ name: capitalizeWords(name), count })); // Map to the desired format.

  // Calculate the percentages of the top 6 areas to improve and add value for the green circle in the radar chart.
  const totalCount = formattedAreasToImproveOrganization.reduce(
    (acc, obj) => acc + obj.count,
    0
  );
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  for (let i = 0; i < formattedAreasToImproveOrganization.length; i++) {
    formattedAreasToImproveOrganization[i].percentage =
      formattedAreasToImproveOrganization[i].count / totalCount;
    formattedAreasToImproveOrganization[i].letter =
      alphabet[i % alphabet.length];
  }

  return formattedAreasToImproveOrganization;
};

export default formatAreasToImproveOrganization;
