import React from "react";

const ReduceRisk = () => {
  return (
    <svg
      width="67"
      height="60"
      viewBox="0 0 67 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.5004 23.4998V35.9998M2.49038 47.2531C-0.396286 52.2531 3.21371 58.4998 8.98371 58.4998H58.017C63.7837 58.4998 67.3937 52.2531 64.5104 47.2531L39.997 4.75977C37.1104 -0.240234 29.8904 -0.240234 27.0037 4.75977L2.49038 47.2531ZM33.5004 45.9998H33.5237V46.0264H33.5004V45.9998Z"
        stroke="#00FF82"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ReduceRisk;
