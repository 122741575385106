import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import { CollapseTransition } from "../../../../common";

import { DropDownArrowIcon, DropUpArrowIcon } from "../../../../../images";

function NavigationList({
  header,
  pages,
  open,
  pathname,
  onClickExpand,
  onClickPageWithSubpages,
  padding = "",
}) {
  return (
    <div className={padding ? padding : ""}>
      <h2 className="text-white card-header">{header}</h2>
      {Object.keys(pages).map((pageKey, pageIndex) => {
        const page = pages[pageKey];

        let isPageSelected = false;

        if (
          pathname.includes("/dashboard/trainings/") &&
          page.path === "/dashboard/trainings/"
        ) {
          isPageSelected = true;
        } else {
          isPageSelected = pathname === page.path;
        }

        return (
          <Fragment key={`navigation-platform-list-item-${pageIndex}`}>
            <div>
              {page.subpages ? (
                <button
                  className={`navigation-platform-list-item-button mt-0.25 description flex rounded-small items-center w-full p-0.625 ${
                    isPageSelected && !page.subpages
                      ? "navigation-platform-list-item-button-selected bg-background-container font-medium"
                      : ""
                  }`}
                  aria-label={page.name}
                  onClick={() => onClickPageWithSubpages(page.path)}
                >
                  <div className="flex items-center justify-between w-full">
                    <div className="flex items-center">
                      <div className="mr-[12px] w-[24px] h-[24px]">
                        {isPageSelected ? page.activeIcon : page.icon}
                      </div>
                      <span>{page.name}</span>
                    </div>
                    {page.subpages && (
                      <>
                        {open[page.name] ? (
                          <div
                            className="w-[8px] h-[8px]"
                            onClick={(event) => {
                              event.stopPropagation(); // Prevents the click from triggering the button click
                              onClickExpand(page.name);
                            }}
                          >
                            <DropUpArrowIcon />
                          </div>
                        ) : (
                          <div
                            className="w-[8px] h-[8px]"
                            onClick={(event) => {
                              event.stopPropagation(); // Prevents the click from triggering the button click
                              onClickExpand(page.name);
                            }}
                          >
                            <DropDownArrowIcon />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </button>
              ) : (
                <Link
                  to={page.path}
                  className={`navigation-platform-list-item-button mt-0.25 description flex rounded-small items-center w-full p-0.625 ${
                    isPageSelected
                      ? "navigation-platform-list-item-button-selected bg-background-container font-medium"
                      : ""
                  }`}
                  aria-label={page.name}
                >
                  <div className="flex items-center">
                    <div className="mr-[12px] w-[24px] h-[24px]">
                      {isPageSelected ? page.activeIcon : page.icon}
                    </div>
                    <span>{page.name}</span>
                  </div>
                </Link>
              )}
            </div>
            {page.subpages && (
              <CollapseTransition isOpen={open[page.name]}>
                {Object.keys(page.subpages).map((subpageKey, subpageIndex) => {
                  const subpage = page.subpages[subpageKey];

                  const isSubpageSelected =
                    subpage.path === pathname ||
                    (pathname === "/insights/employees/" &&
                      subpage.path === "/insights/search-employee/");

                  return (
                    <Link
                      to={subpage.path}
                      className={`navigation-platform-list-item-button mt-0.25 description flex rounded-small items-center w-full p-0.625 ${
                        isSubpageSelected
                          ? "navigation-platform-list-item-button-selected bg-background-container font-medium"
                          : ""
                      }`}
                      key={`page-${pageIndex}-subpage-${subpageIndex}`}
                    >
                      <div className="flex items-center">
                        <div className="mr-[12px] w-[24px] h-[24px]">
                          {isSubpageSelected
                            ? subpage.activeIcon
                            : subpage.icon}
                        </div>
                        <span>{subpage.name}</span>
                      </div>
                    </Link>
                  );
                })}
              </CollapseTransition>
            )}
          </Fragment>
        );
      })}
    </div>
  );
}

export default NavigationList;
