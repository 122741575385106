import React, { useMemo, useRef } from "react";
import { useTheme, useMediaQuery } from "@mui/material";
import DuneSecurityLogo from "../dune-security-logo/dune-security-logo";
import OktaLogoIcon from "../okta-logo-icon/okta-logo-icon";
import MicrosoftEntraIDIcon from "../microsoft-entra-id-icon/microsoft-entra-id-icon";
import PhoneIcon from "../phone-icon/phone-icon";
import SMSIcon from "../sms-icon/sms-icon";
import ZoomLogoIcon from "../zoom-logo-icon/zoom-logo-icon";
import "./ecosystem-diagram.css";
import EcosystemLinearSVG from "./ecosystem-linear-svg";
import EcosystemEclipseSVG from "./ecosystem-eclipse-svg";

import EcosystemSingleLineSVG from "./ecosystem-single-line-svg";
import {
  BasicUserDataLogo,
  BlastRadiusAnalysisLogo,
  UserSpecificTestingLogo,
  AnomalousRiskyActivityLogo,
  AccessPermissionLogo,
  AdaptLogo,
} from "./logos";
import ReduceLogo from "./logos/reduce-logo/reduce-logo";
import QuantifyLogo from "./logos/quantify-logo/quantify-logo";
import GoogleIcon from "../google-icon/google-icon";
import MicrosoftDefenderIcon from "../microsoft-defender-icon/microsoft-defender-icon";
import { GreenItalicText } from "../../../../../components";

import { Translate } from "react-auto-translate";
import EnvelopeIcon from "../envelope-icon/envelope-icon";
import GeneticAlgorithm from "../genetic-algorithm/genetic-algorithm";
import OrganizationIcon from "../organization-icon/organization-icon";

const EcosystemDiagram = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isLinearView = useMediaQuery("(min-width: 1250px)");
  const domRef = useRef();
  const topBoxesRefs = useRef([]);
  const box1Ref = useRef(null);
  const box2Ref = useRef(null);
  const box3Ref = useRef(null);

  const ecosystemInputs = useMemo(
    () => [
      {
        title: "Basic User Data",
        icon: <BasicUserDataLogo />,
        logos: [
          <MicrosoftEntraIDIcon />,
          <OktaLogoIcon />,
          <GoogleIcon />,
          <MicrosoftDefenderIcon />,
        ],
      },
      {
        title: "Blast Radius Analysis",
        icon: <BlastRadiusAnalysisLogo />,
        logos: [<GeneticAlgorithm />, <OrganizationIcon />],
      },
      {
        title: "User Specific Testing",
        icon: <UserSpecificTestingLogo />,
        logos: [<EnvelopeIcon />, <SMSIcon />, <PhoneIcon />, <ZoomLogoIcon />],
      },
      {
        title: "Anomalous & Risky Activity",
        icon: <AnomalousRiskyActivityLogo />,
        logos: [
          <MicrosoftEntraIDIcon />,
          <OktaLogoIcon />,
          <GoogleIcon />,
          <MicrosoftDefenderIcon />,
        ],
      },
      {
        title: "Access & Permssions",
        icon: <AccessPermissionLogo />,
        logos: [
          <MicrosoftEntraIDIcon />,
          <OktaLogoIcon />,
          <GoogleIcon />,
          <MicrosoftDefenderIcon />,
        ],
      },
    ],
    [],
  );

  return (
    <div className={`relative`} ref={domRef}>
      <div className="flex justify-center xs:mt-5 mt-5 md:mt-7.5 px-1.875">
        <div className={`xs:max-w-full md:max-w-78.75 w-full`}>
          <div>
            <div className="text-h1.5 text-center leading-[1.2] text-white">
              <span>
                <Translate>Welcome to the </Translate>
              </span>
              <GreenItalicText text=" future " />
              <span>
                <Translate>of employee risk management</Translate>
              </span>
            </div>
            <div className="text-center pt-0.625 text-white text-h4">
              <Translate>
                Quantify individual user risk, target training to users’
                vulnerabilities, and adapt security controls for high-risk
                users.
              </Translate>
            </div>
          </div>
          <div className="z-2 relative">
            <div className="mt-2.5">
              <div className="flex flex-row justify-around flex-wrap">
                {ecosystemInputs.map((input, inputIndex) => (
                  <div
                    className="home-box-ecosystem-input bg-[#141414] rounded-small border border-gray border-opacity-50 max-w-13.25 p-0.75 w-full md:max-w-[70%] md:mt-2.5"
                    ref={(el) => (topBoxesRefs.current[inputIndex] = el)}
                    key={`ecosystem-input-${inputIndex}`}
                  >
                    <div className="flex justify-center">{input.icon}</div>
                    <div className="flex justify-center mt-0.75">
                      <span
                        className="text-white text-center text-h5 font-medium"
                        variant="body-emphasized-gray"
                      >
                        {<Translate>{input.title}</Translate>}
                      </span>
                    </div>
                    <div className="mt-0.75">
                      <div className="flex flex-row justify-center items-center">
                        {input.logos.map((logo, logoIndex) => (
                          <div
                            className={`flex justify-evenly items-center h-1.5 w-3 text-xl ${logoIndex > -1 ? "mx-0.25" : ""}`}
                            key={`ecosystem-input-${inputIndex}-logo-${logoIndex}`}
                          >
                            {logo}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div
            className={`flex justify-center items-center ${
              isLinearView ? "-mt-1" : "-mt-37.5"
            } -z-1`}
          >
            {isLinearView ? (
              <EcosystemLinearSVG isMobile={isMobile} />
            ) : (
              <EcosystemSingleLineSVG />
            )}
          </div>
          <div className="flex justify-center items-start -mt-1.5 z-10 relative">
            <DuneSecurityLogo />
          </div>

          <div className="z-10 relative xs:flex xs:flex-col xs:items-center xs:mt-2.5">
            <div className="flex justify-center mt-0.75">
              <div
                className={`flex ${
                  isMobile ? "flex-col" : "flex-row"
                } justify-between ${
                  isMobile ? "max-w-25" : "max-w-43.75"
                } w-full`}
              >
                <div
                  className={`bg-[#191919] rounded-small border border-gray border-opacity-50 p-0.5 ${
                    isMobile ? "mb-0.5" : "0"
                  }`}
                  ref={box1Ref}
                >
                  <div className="flex flex-row w-fit items-center mx-auto">
                    <div className="flex items-center">
                      <QuantifyLogo />
                    </div>
                    <div className="flex items-center ml-0.5">
                      <span className="text-center text-white text-h5 font-medium">
                        <Translate>Quantify</Translate>
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className={`bg-[#191919] rounded-small border border-gray border-opacity-50 p-0.5 ${
                    isMobile ? "mb-0.5" : "0"
                  }`}
                  ref={box2Ref}
                >
                  <div className="flex flex-row w-fit items-center mx-auto">
                    <div className="flex items-center">
                      <AdaptLogo />
                    </div>
                    <div className="flex items-center ml-0.5">
                      <span className="text-center text-white text-h5 font-medium">
                        <Translate>Adapt</Translate>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center mt-0.5">
              <div
                className={`bg-[#191919] rounded-small border border-gray border-opacity-50 p-0.5 -mt-0.5  ${
                  isMobile ? "w-43.75" : "max-w-43.75"
                } xs:w-full md:w-auto`}
                ref={box3Ref}
              >
                <div className="flex justify-center mx-auto items-center">
                  <div
                    className="flex items-center"
                    display="flex"
                    alignItems="center"
                  >
                    <ReduceLogo />
                  </div>
                  <div className="flex items-center ml-0.5">
                    <span className="text-center text-h5 text-white font-medium">
                      <Translate>Reduce</Translate>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`flex justify-center items-center z-1 relative ${
              isMobile ? "-mt-12.5" : "-mt-11.25"
            } ${isMobile ? "mb-5" : "mb-0"}`}
          >
            <div>
              <EcosystemEclipseSVG isMobile={isMobile} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EcosystemDiagram;
