import React, { useCallback, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";

import InProgressIcon from "./utils/in-progress-icon/in-progress-icon";
import QuizDialog from "./utils/quiz-dialog/quiz-dialog";
import TrainingVideo from "./utils/training-video/training-video";

import { getTrainings, postTrainingCompleted } from "../../../api";

import {
  ButtonContainedGreen,
  Divider,
  TitleWithBadge,
} from "../../../components";

import {
  CheckmarkIcon,
  CircleOutlinedIcon,
  GraduationCapFilledIcon,
} from "../../../images";

import "./trainings-video-player.css";

function TrainingsVideoPlayer({ isInitialLoading, email, setNewTitle }) {
  const [selectedVideo, setSelectedVideo] = useState([]);
  const [modules, setModules] = useState([]);
  const [category, setCategory] = useState(null);

  const [isClickedTakeQuiz, setIsClickedTakeQuiz] = useState(false);
  const [isComplianceModulesExisting, setIsComplianceModulesExisting] =
    useState(false);
  const [isCompletedModulesExisting, setIsCompletedModulesExisting] =
    useState(false);
  const [isQuizLocked, setIsQuizLocked] = useState(true);

  const [openDialogQuiz, setOpenDialogQuiz] = useState(false);

  const complianceFrameworks = [
    { name: "GDPR" },
    { name: "GLBA" },
    { name: "HIPAA" },
    { name: "ISO 27001" },
    { name: "NIST" },
    { name: "SOC 2 Type 2" },
  ];

  const { pathname, state } = useLocation();

  const handleClickModule = (module) => {
    setSelectedVideo(module);
    setIsClickedTakeQuiz(false);
    setIsQuizLocked(module.status !== "in progress");
  };

  const handleClickTakeQuiz = () => {
    if (!isClickedTakeQuiz) {
      setIsClickedTakeQuiz(true);
    }
    if (!isQuizLocked) {
      setOpenDialogQuiz(true);
    }
  };

  const handleCloseDialogQuiz = async () => {
    setOpenDialogQuiz(false);
    await loadTrainings();
  };

  const handleEndedVideo = async () => {
    if (selectedVideo.status !== "completed") {
      const requestBody = { training_id: selectedVideo.id };
      const videoTrainingCompletion = await postTrainingCompleted(requestBody);
      if (videoTrainingCompletion.error) {
        console.error(videoTrainingCompletion.error.message);
      } else {
        setSelectedVideo({ ...selectedVideo, status: "completed" });
        const updatedModules = modules.map((mod) =>
          mod.id === selectedVideo.id ? { ...mod, status: "completed" } : mod,
        );
        setModules(updatedModules);
      }
    }
  };

  const loadTrainings = useCallback(
    async (isFirstTime = false) => {
      const trainings = await getTrainings();
      if (!trainings.error) {
        const { learningModules, complianceModules, completedModules } =
          trainings.result;
        if (pathname.includes("compliance")) {
          if (!complianceModules?.length) {
            setIsComplianceModulesExisting(true);
          } else {
            setModules(complianceModules[0].videos);
            if (isFirstTime) {
              setSelectedVideo(complianceModules[0].videos[0]);
              setCategory(`${complianceModules[0].name} compliance`);
              setIsQuizLocked(
                complianceModules[0].videos[0].status !== "in progress",
              );
            }
          }
        } else if (pathname.includes("completed")) {
          if (!completedModules?.length) {
            setIsCompletedModulesExisting(true);
          } else {
            setModules(completedModules);
            if (isFirstTime) {
              setSelectedVideo(completedModules[0]);
              setCategory("Completed modules");
              setIsQuizLocked(completedModules[0].status !== "in progress");
            }
          }
        } else {
          setModules(learningModules);
          if (isFirstTime) {
            setSelectedVideo(learningModules[0]);
            setCategory("Learning modules");
            setIsQuizLocked(learningModules[0].status !== "in progress");
          }
        }
      }
    },
    [pathname],
  );

  useEffect(() => {
    if (!isInitialLoading && email) {
      if (state) {
        setSelectedVideo(state.selectedVideo);
        setModules(state.modules);
        setCategory(state.category);
        setIsQuizLocked(state.selectedVideo.status !== "in progress");
      } else {
        loadTrainings(true);
      }
    }
  }, [isInitialLoading, email, loadTrainings, state]);

  useEffect(() => {
    if (selectedVideo.title) {
      setNewTitle(
        <TitleWithBadge
          title={selectedVideo.title}
          badgeText="Training"
          badgeIcon={<GraduationCapFilledIcon />}
        />,
      );
    }
  }, [selectedVideo, setNewTitle]);

  if (isComplianceModulesExisting || isCompletedModulesExisting) {
    return <Navigate to="/error/" />;
  }

  return (
    <>
      <div className="mt-0.625">
        <div className="flex flex-col">
          {selectedVideo && (
            <div>
              <div className="flex justify-center">
                <div className="w-[800px] h-[450px]">
                  <TrainingVideo
                    selectedVideo={selectedVideo}
                    pathname={pathname}
                    onEndedVideo={handleEndedVideo}
                  />
                </div>
              </div>
              <div className="flex flex-col justify-between mt-0.625 md:flex-row">
                <div>
                  {!complianceFrameworks.some(
                    (framework) => framework.name === selectedVideo.category,
                  ) &&
                    selectedVideo.status !== "completed" && (
                      <div className="flex items-center">
                        <div className="flex justify-start md:justify-end">
                          <ButtonContainedGreen onClick={handleClickTakeQuiz}>
                            Take quiz
                          </ButtonContainedGreen>
                        </div>
                        {isQuizLocked && isClickedTakeQuiz && (
                          <p className="description text-green ml-0.625">
                            Complete the video to take the quiz.
                          </p>
                        )}
                      </div>
                    )}
                </div>
              </div>
              <div className="mt-0.625">
                <Divider />
              </div>
            </div>
          )}
        </div>
        <div className="mt-0.625">
          {modules && modules.length > 0 && category && (
            <div>
              <div>
                <h2 className="h4">{category}</h2>
                <p className="description mt-0.25">1 of {modules.length}</p>
              </div>
              <div className="mt-0.625">
                {modules.map((module, index) => (
                  <div
                    className="flex items-center cursor-pointer"
                    key={`modules-list-item-${index}`}
                    onClick={() => handleClickModule(module)}
                  >
                    {module.status === "in progress" ? (
                      <div className="w-[24px] h-[24px]">
                        <InProgressIcon />
                      </div>
                    ) : module.status === "completed" ? (
                      <div className="w-[24px] h-[24px]">
                        <CheckmarkIcon />
                      </div>
                    ) : (
                      <div className="w-[24px] h-[24px]">
                        <CircleOutlinedIcon />
                      </div>
                    )}
                    <div className="w-full ml-0.625">
                      <p className="description">{module.title}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <QuizDialog
        openDialog={openDialogQuiz}
        selectedVideo={selectedVideo}
        email={email}
        onCloseDialogQuiz={handleCloseDialogQuiz}
      />
    </>
  );
}

export default TrainingsVideoPlayer;
