import React from "react";

const DownloadIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 17 16"
      fill="none"
    >
      <g clipPath="url(#clip0_16851_27173)">
        <path
          d="M8.27181 4.33333C9.49763 4.32926 10.6997 4.67119 11.7399 5.31982C12.7801 5.96845 13.6161 6.89744 14.1518 8C13.0518 10.2467 10.8051 11.6667 8.27181 11.6667C5.73848 11.6667 3.49181 10.2467 2.39181 8C2.92756 6.89744 3.76357 5.96845 4.80373 5.31982C5.84389 4.67119 7.04599 4.32926 8.27181 4.33333ZM8.27181 3C4.93848 3 2.09181 5.07333 0.938477 8C2.09181 10.9267 4.93848 13 8.27181 13C11.6051 13 14.4518 10.9267 15.6051 8C14.4518 5.07333 11.6051 3 8.27181 3ZM8.27181 6.33333C8.71384 6.33333 9.13776 6.50893 9.45032 6.82149C9.76288 7.13405 9.93848 7.55797 9.93848 8C9.93848 8.44203 9.76288 8.86595 9.45032 9.17851C9.13776 9.49107 8.71384 9.66667 8.27181 9.66667C7.82978 9.66667 7.40586 9.49107 7.0933 9.17851C6.78074 8.86595 6.60514 8.44203 6.60514 8C6.60514 7.55797 6.78074 7.13405 7.0933 6.82149C7.40586 6.50893 7.82978 6.33333 8.27181 6.33333ZM8.27181 5C6.61848 5 5.27181 6.34667 5.27181 8C5.27181 9.65333 6.61848 11 8.27181 11C9.92514 11 11.2718 9.65333 11.2718 8C11.2718 6.34667 9.92514 5 8.27181 5Z"
          fill="white"
          fillOpacity="0.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_16851_27173">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.270508)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DownloadIcon;
