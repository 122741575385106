import React from "react";

const GraduationCapOutlinedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M23.6025 8.33812L12.3525 2.33812C12.244 2.28034 12.1229 2.25012 12 2.25012C11.8771 2.25012 11.756 2.28034 11.6475 2.33812L0.39751 8.33812C0.277514 8.40207 0.17716 8.49744 0.107185 8.61403C0.0372091 8.73061 0.000244141 8.86402 0.000244141 9C0.000244141 9.13597 0.0372091 9.26938 0.107185 9.38597C0.17716 9.50255 0.277514 9.59792 0.39751 9.66187L3.00001 11.0503V15.5897C2.99914 15.9581 3.13474 16.3138 3.38064 16.5881C4.60876 17.9559 7.36032 20.25 12 20.25C13.5384 20.2627 15.0653 19.9841 16.5 19.4287V22.5C16.5 22.6989 16.579 22.8897 16.7197 23.0303C16.8603 23.171 17.0511 23.25 17.25 23.25C17.4489 23.25 17.6397 23.171 17.7803 23.0303C17.921 22.8897 18 22.6989 18 22.5V18.7041C18.978 18.1395 19.8618 17.4256 20.6194 16.5881C20.8653 16.3138 21.0009 15.9581 21 15.5897V11.0503L23.6025 9.66187C23.7225 9.59792 23.8229 9.50255 23.8928 9.38597C23.9628 9.26938 23.9998 9.13597 23.9998 9C23.9998 8.86402 23.9628 8.73061 23.8928 8.61403C23.8229 8.49744 23.7225 8.40207 23.6025 8.33812ZM12 18.75C7.94345 18.75 5.55751 16.7681 4.50001 15.5897V11.85L11.6475 15.6619C11.756 15.7197 11.8771 15.7499 12 15.7499C12.1229 15.7499 12.244 15.7197 12.3525 15.6619L16.5 13.4503V17.7947C15.3188 18.3459 13.83 18.75 12 18.75ZM19.5 15.5859C19.0504 16.0848 18.5474 16.5328 18 16.9219V12.6497L19.5 11.85V15.5859ZM17.625 11.1506L17.6044 11.1384L12.3544 8.33812C12.1792 8.24866 11.9759 8.23158 11.7883 8.29056C11.6007 8.34955 11.4437 8.47989 11.3513 8.65349C11.2588 8.82709 11.2382 9.03006 11.294 9.21868C11.3498 9.40729 11.4774 9.56645 11.6494 9.66187L16.0313 12L12 14.1497L2.34376 9L12 3.85031L21.6563 9L17.625 11.1506Z"
        fill="white"
      />
    </svg>
  );
};

export default GraduationCapOutlinedIcon;
