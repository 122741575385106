import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  getTrainings,
  getTrainingsOptional,
  postTrainingOptional,
} from "../../../api";

import {
  ButtonOutlinedGray,
  Card,
  // Tooltip
} from "../../../components";

import {
  HourGlassIcon,
  LeftArrowIcon,
  RightArrowIcon,
  WarningIcon,
} from "../../../images";

import "./trainings.css";

function Trainings({ isInitialLoading, email, setIsPlatformLoading }) {
  // Modules
  const [learningModules, setLearningModules] = useState(null);
  const [complianceModules, setComplianceModules] = useState(null);
  const [optionalModules, setOptionalModules] = useState(null);

  const navigate = useNavigate();
  const scrollContainerLearningModulesRef = useRef(null);
  const scrollContainerComplianceModulesRef = useRef(null);
  const scrollContainerOptionalModulesRef = useRef(null);

  const getThumbnailFileName = (module) => {
    if (!(module && module.fileNameThumbnail)) {
      return "";
    }

    return `https://d36cattz2ccgpt.cloudfront.net/general/${module.fileNameThumbnail}`;
  };

  const getThumbnailFileNameOld = (module, isCompliance = false) => {
    if (!(module && module.fileName)) {
      return "";
    }

    let extension = ".jpg"; // Default is .jpg.

    if (module.startDate) {
      const dateUpdate = new Date("2024-06-04T00:00:00");
      const dateModule = new Date(module.startDate);

      if (dateModule < dateUpdate) {
        extension = ".png";
      }
    }

    return `https://d36cattz2ccgpt.cloudfront.net/${
      !isCompliance ? "general/" : ""
    }${module.fileName.slice(0, -4)}${extension}`;
  };

  const handleClickLeft = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollBy({
        left: -310 * 3,
        behavior: "smooth",
      });
    }
  };

  const handleClickModule = async (
    module,
    modules,
    category,
    path,
    isOptional = false,
  ) => {
    const selectedVideo = { ...module };

    if (isOptional) {
      const id = await updateTrainingOptional(module.moduleID);

      // Set the missing attributes of this selected video.
      selectedVideo.id = id;
      selectedVideo.fileName = selectedVideo.fileNameVideo;
      selectedVideo.status = "not started";

      const today = new Date();
      selectedVideo.startDate = today.toISOString();
    }

    navigate(path, {
      state: {
        selectedVideo,
        modules,
        category,
      },
    });
  };

  const handleClickRight = (ref) => {
    if (ref.current) {
      ref.current.scrollBy({
        left: 310 * 3,
        behavior: "smooth",
      });
    }
  };

  const loadTrainingsOptional = async () => {
    const trainings = await getTrainingsOptional();

    if (Object.keys(trainings.error).length > 0) {
      console.error(trainings.error.message);
    } else {
      const { optionalModules } = trainings.result;
      setOptionalModules(optionalModules);
    }
  };

  const updateTrainingOptional = async (moduleID) => {
    let assignedTrainingID = "";

    const requestBody = {
      training_id: moduleID,
    };

    const postTrainingOptionalResponse =
      await postTrainingOptional(requestBody);

    if (Object.keys(postTrainingOptionalResponse.error).length > 0) {
      console.error(postTrainingOptionalResponse.error.message);
    } else {
      const { id } = postTrainingOptionalResponse.result;
      assignedTrainingID = id;
    }

    return assignedTrainingID;
  };

  useEffect(() => {
    const loadTrainings = async () => {
      setIsPlatformLoading(true);

      if (email === "duncan.idaho@dune.demo") {
        // Demo account for end user
        setLearningModules([
          {
            id: "0",
            status: "Not started",
            title: "Domain Spoofing",
            fileName: "domain-spoofing.mp4",
            startDate: "2024-06-18T00:00:00",
          },
          {
            id: "1",
            status: "Not started",
            title: "Malicious Attachments",
            fileName: "malicious-attachments.mp4",
            startDate: "2024-06-18T00:00:00",
          },
          {
            id: "2",
            status: "Not started",
            title: "Business Email Compromise",
            fileName: "business-email-compromise.mp4",
            startDate: "2024-06-18T00:00:00",
          },
        ]);

        setComplianceModules([
          {
            name: "SOC 2 Type 2",
            videos: [
              {
                id: "3",
                category: "SOC 2 Type 2",
                status: "Not started",
                title: "Foundations of SOC 2 Compliance",
                fileName: "soc-2-type-2/01-foundations-of-soc-2-compliance.mp4",
                startDate: "2024-06-03T00:00:00",
              },
              {
                id: "4",
                category: "SOC 2 Type 2",
                status: "Not started",
                title: "Key Security Practices for SOC 2",
                fileName:
                  "soc-2-type-2/02-key-security-practices-for-soc-2.mp4",
                startDate: "2024-06-03T00:00:00",
              },
              {
                id: "5",
                category: "SOC 2 Type 2",
                status: "Not started",
                title: "Role-Specific Training and Responsibilities",
                fileName:
                  "soc-2-type-2/03-role-specific-training-and-responsibilities.mp4",
                startDate: "2024-06-03T00:00:00",
              },
            ],
          },
          {
            name: "HIPAA",
            videos: [
              {
                id: "6",
                category: "HIPAA",
                status: "Not started",
                title: "Foundations of HIPAA",
                fileName: "hipaa/01-foundations-of-hipaa.mp4",
                startDate: "2024-06-03T00:00:00",
              },
              {
                id: "7",
                category: "HIPAA",
                status: "Not started",
                title: "Understanding and Protecting PHI",
                fileName: "hipaa/02-understanding-and-protecting-phi.mp4",
                startDate: "2024-06-03T00:00:00",
              },
              {
                id: "8",
                category: "HIPAA",
                status: "Not started",
                title: "A Guide to Protecting Electronic PHI",
                fileName: "hipaa/03-a-guide-to-protecting-electronic-phi.mp4",
                startDate: "2024-06-03T00:00:00",
              },
            ],
          },
          {
            name: "ISO 27001",
            videos: [
              {
                id: "9",
                category: "ISO 27001",
                status: "completed",
                title: "Introduction to ISO 27001",
                fileName: "iso-27001/01-introduction-to-iso-27001.mp4",
                startDate: "2024-06-03T00:00:00",
              },
              {
                id: "10",
                category: "ISO 27001",
                status: "Not started",
                title: "The Information Security Management System (ISMS)",
                fileName:
                  "iso-27001/02-the-information-security-management-system-isms.mp4",
                startDate: "2024-06-03T00:00:00",
              },
              {
                id: "11",
                category: "ISO 27001",
                status: "Not started",
                title: "Certification and Continuous Improvement",
                fileName:
                  "iso-27001/03-certification-and-continuous-improvement.mp4",
                startDate: "2024-06-03T00:00:00",
              },
            ],
          },
        ]);
      } else if (email === "aaron.chavez@dune.demo") {
        // Demo account for manager
        setLearningModules([]);

        setComplianceModules([
          {
            name: "SOC 2 Type 2",
            videos: [
              {
                id: "3",
                category: "SOC 2 Type 2",
                status: "completed",
                title: "Foundations of SOC 2 Compliance",
                fileName: "soc-2-type-2/01-foundations-of-soc-2-compliance.mp4",
                startDate: "2024-06-03T00:00:00",
              },
              {
                id: "4",
                category: "SOC 2 Type 2",
                status: "completed",
                title: "Key Security Practices for SOC 2",
                fileName:
                  "soc-2-type-2/02-key-security-practices-for-soc-2.mp4",
                startDate: "2024-06-03T00:00:00",
              },
              {
                id: "5",
                category: "SOC 2 Type 2",
                status: "completed",
                title: "Role-Specific Training and Responsibilities",
                fileName:
                  "soc-2-type-2/03-role-specific-training-and-responsibilities.mp4",
                startDate: "2024-06-03T00:00:00",
              },
            ],
          },
          {
            name: "HIPAA",
            videos: [
              {
                id: "6",
                category: "HIPAA",
                status: "completed",
                title: "Foundations of HIPAA",
                fileName: "hipaa/01-foundations-of-hipaa.mp4",
                startDate: "2024-06-03T00:00:00",
              },
              {
                id: "7",
                category: "HIPAA",
                status: "completed",
                title: "Understanding and Protecting PHI",
                fileName: "hipaa/02-understanding-and-protecting-phi.mp4",
                startDate: "2024-06-03T00:00:00",
              },
              {
                id: "8",
                category: "HIPAA",
                status: "completed",
                title: "A Guide to Protecting Electronic PHI",
                fileName: "hipaa/03-a-guide-to-protecting-electronic-phi.mp4",
                startDate: "2024-06-03T00:00:00",
              },
            ],
          },
          {
            name: "ISO 27001",
            videos: [
              {
                id: "9",
                category: "ISO 27001",
                status: "completed",
                title: "Introduction to ISO 27001",
                fileName: "iso-27001/01-introduction-to-iso-27001.mp4",
                startDate: "2024-06-03T00:00:00",
              },
              {
                id: "10",
                category: "ISO 27001",
                status: "completed",
                title: "The Information Security Management System (ISMS)",
                fileName:
                  "iso-27001/02-the-information-security-management-system-isms.mp4",
                startDate: "2024-06-03T00:00:00",
              },
              {
                id: "11",
                category: "ISO 27001",
                status: "completed",
                title: "Certification and Continuous Improvement",
                fileName:
                  "iso-27001/03-certification-and-continuous-improvement.mp4",
                startDate: "2024-06-03T00:00:00",
              },
            ],
          },
        ]);
      } else {
        const trainings = await getTrainings();

        if (Object.keys(trainings.error).length > 0) {
          console.error(trainings.error.message);
        } else {
          const { learningModules, complianceModules } = trainings.result;

          setLearningModules(learningModules);
          setComplianceModules(complianceModules);
        }
      }

      setIsPlatformLoading(false);
    };

    if (!isInitialLoading && email) {
      loadTrainings();
      loadTrainingsOptional();
    }
  }, [isInitialLoading, email, setIsPlatformLoading]);

  return (
    <div>
      <div className="mt-0.625">
        <div className="flex flex-row justify-between">
          <div>
            <div className="flex flex-row items-center">
              <h2 className="h4">Your Learning Modules</h2>
              {/* <div className="flex items-center ml-[4px]">
                <Tooltip text="" />
              </div> */}
            </div>
            <div className="mt-0.25">
              <p className="description">
                Lower your risk score by completing your assigned trainings.
              </p>
            </div>
          </div>
          <div className="flex">
            <div>
              <ButtonOutlinedGray
                padding="p-0.25"
                onClick={() =>
                  handleClickLeft(scrollContainerLearningModulesRef)
                }
              >
                <div className="w-[14px] h-[14px]">
                  <LeftArrowIcon />
                </div>
              </ButtonOutlinedGray>
            </div>
            <div className="ml-0.25">
              <ButtonOutlinedGray
                padding="p-0.25"
                onClick={() =>
                  handleClickRight(scrollContainerLearningModulesRef)
                }
              >
                <div className="w-[14px] h-[14px]">
                  <RightArrowIcon />
                </div>
              </ButtonOutlinedGray>
            </div>
          </div>
        </div>
        <div className="mt-0.625">
          <div
            className="flex flex-row overflow-x-hidden"
            ref={scrollContainerLearningModulesRef}
          >
            {learningModules &&
              Array.isArray(learningModules) &&
              learningModules.length > 0 &&
              learningModules.map((module, index) => (
                <div
                  className={`w-[300px] shrink-0 cursor-pointer group ${index > 0 ? "ml-0.625" : ""}`}
                  onClick={() =>
                    handleClickModule(
                      module,
                      learningModules,
                      "Learning Modules",
                      "/dashboard/trainings/learning/",
                    )
                  }
                  key={`learning-modules-${index}`}
                >
                  {/* <Card padding="p-0">
                    <div className="flex flex-row">
                      <div>
                        <img
                          className="rounded-l-medium max-w-[116px]"
                          alt={module.title}
                          src={getThumbnailFileNameOld(module)}
                        />
                      </div>
                      <div className="flex items-center p-0.625">
                        <h3 className="description group-hover:text-gray">
                          {module.title}
                        </h3>
                      </div>
                    </div>
                  </Card> */}
                  <Card padding="p-0">
                    <div className="flex flex-col">
                      <div>
                        <img
                          className="rounded-t-medium"
                          alt={module.title}
                          src={getThumbnailFileNameOld(module)}
                        />
                      </div>
                      <div className="flex items-center p-0.625">
                        <h3 className="description-emphasized group-hover:text-gray">
                          {module.title}
                        </h3>
                      </div>
                    </div>
                  </Card>
                </div>
              ))}
            {learningModules &&
              Array.isArray(learningModules) &&
              learningModules.length === 0 && (
                <div className="flex flex-col justify-center items-center w-full h-[225.63px]">
                  <div className="w-[60px] h-[60px]">
                    <WarningIcon />
                  </div>
                  <div className="mt-0.625">
                    <p className="text-center body">
                      You have no modules to view.
                    </p>
                  </div>
                </div>
              )}
            {learningModules === null && (
              <div className="flex flex-col justify-center items-center w-full h-[225.63px]">
                <div className="w-[60px] h-[60px]">
                  <HourGlassIcon />
                </div>
                <div className="mt-0.625">
                  <p className="text-center body">
                    Content loading, please wait.
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mt-1.25">
        <div className="flex flex-row justify-between">
          <div>
            <div className="flex flex-row items-center">
              <h2 className="h4">Compliance Modules</h2>
              {/* <div className="flex items-center ml-[4px]">
                <Tooltip text="" />
              </div> */}
            </div>
            <div className="mt-0.25">
              <p className="description">
                Keep watching your compliance videos to breeze through your
                mandatory trainings.
              </p>
            </div>
          </div>
          <div className="flex">
            <div>
              <ButtonOutlinedGray
                padding="p-0.25"
                onClick={() =>
                  handleClickLeft(scrollContainerComplianceModulesRef)
                }
              >
                <div className="w-[14px] h-[14px]">
                  <LeftArrowIcon />
                </div>
              </ButtonOutlinedGray>
            </div>
            <div className="ml-0.25">
              <ButtonOutlinedGray
                padding="p-0.25"
                onClick={() =>
                  handleClickRight(scrollContainerComplianceModulesRef)
                }
              >
                <div className="w-[14px] h-[14px]">
                  <RightArrowIcon />
                </div>
              </ButtonOutlinedGray>
            </div>
          </div>
        </div>
        <div className="mt-0.625">
          <div
            className="flex flex-row overflow-x-hidden"
            ref={scrollContainerComplianceModulesRef}
          >
            {complianceModules &&
              Array.isArray(complianceModules) &&
              complianceModules.length > 0 &&
              complianceModules.map((module, index) => (
                <div
                  className={`w-[300px] shrink-0 cursor-pointer group ${index > 0 ? "ml-0.625" : ""}`}
                  onClick={() =>
                    handleClickModule(
                      module.videos[0],
                      module.videos,
                      `${module.name} Compliance`,
                      "/dashboard/trainings/compliance/",
                    )
                  }
                  key={`learning-modules-${index}`}
                >
                  {/* <Card padding="p-0">
                    <div className="flex flex-row">
                      <div>
                        <img
                          className="rounded-l-medium max-w-[116px]"
                          alt={module.title}
                          src={getThumbnailFileNameOld(module)}
                        />
                      </div>
                      <div className="flex items-center p-0.625">
                        <h3 className="description group-hover:text-gray">
                          {module.title}
                        </h3>
                      </div>
                    </div>
                  </Card> */}
                  <Card padding="p-0">
                    <div className="flex flex-col">
                      <div>
                        <img
                          className="rounded-t-medium"
                          alt={module.name}
                          src={getThumbnailFileNameOld(module.videos[0], true)}
                        />
                      </div>
                      <div className="flex items-center p-0.625">
                        <h3 className="description-emphasized group-hover:text-gray">
                          {module.name}
                        </h3>
                      </div>
                    </div>
                  </Card>
                </div>
              ))}
            {complianceModules &&
              Array.isArray(complianceModules) &&
              complianceModules.length === 0 && (
                <div className="flex flex-col justify-center items-center w-full h-[225.63px]">
                  <div className="w-[60px] h-[60px]">
                    <WarningIcon />
                  </div>
                  <div className="mt-0.625">
                    <p className="text-center body">
                      You have no modules to view.
                    </p>
                  </div>
                </div>
              )}
            {complianceModules === null && (
              <div className="flex flex-col justify-center items-center w-full h-[225.63px]">
                <div className="w-[60px] h-[60px]">
                  <HourGlassIcon />
                </div>
                <div className="mt-0.625">
                  <p className="text-center body">
                    Content loading, please wait.
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mt-1.25">
        <div className="flex flex-row justify-between">
          <div>
            <div className="flex flex-row items-center">
              <h2 className="h4">Optional Trainings</h2>
              {/* <div className="flex items-center ml-[4px]">
                <Tooltip text="" />
              </div> */}
            </div>
            <div className="mt-0.25">
              <p className="description">
                Some additional trainings that will help you improve your score.
              </p>
            </div>
          </div>
          <div className="flex">
            <div>
              <ButtonOutlinedGray
                padding="p-0.25"
                onClick={() =>
                  handleClickLeft(scrollContainerOptionalModulesRef)
                }
              >
                <div className="w-[14px] h-[14px]">
                  <LeftArrowIcon />
                </div>
              </ButtonOutlinedGray>
            </div>
            <div className="ml-0.25">
              <ButtonOutlinedGray
                padding="p-0.25"
                onClick={() =>
                  handleClickRight(scrollContainerOptionalModulesRef)
                }
              >
                <div className="w-[14px] h-[14px]">
                  <RightArrowIcon />
                </div>
              </ButtonOutlinedGray>
            </div>
          </div>
        </div>
        <div className="mt-0.625">
          <div
            className="flex flex-row overflow-x-hidden"
            ref={scrollContainerOptionalModulesRef}
          >
            {optionalModules &&
              Array.isArray(optionalModules) &&
              optionalModules.length > 0 &&
              optionalModules.map((module, index) => (
                <div
                  className={`w-[300px] shrink-0 cursor-pointer group ${index > 0 ? "ml-0.625" : ""}`}
                  onClick={() =>
                    handleClickModule(
                      module,
                      [module, ...learningModules],
                      "Learning Modules",
                      "/dashboard/trainings/learning/",
                      true,
                    )
                  }
                  key={`optional-modules-${index}`}
                >
                  <Card padding="p-0">
                    <div className="flex flex-col">
                      <div>
                        <img
                          className="rounded-t-medium"
                          alt={module.title}
                          src={getThumbnailFileName(module)}
                        />
                      </div>
                      <div className="flex items-center p-0.625">
                        <h3 className="description-emphasized group-hover:text-gray">
                          {module.title}
                        </h3>
                      </div>
                    </div>
                  </Card>
                </div>
              ))}
            {optionalModules &&
              Array.isArray(optionalModules) &&
              optionalModules.length === 0 && (
                <div className="flex flex-col justify-center items-center w-full h-[225.63px]">
                  <div className="w-[60px] h-[60px]">
                    <WarningIcon />
                  </div>
                  <div className="mt-0.625">
                    <p className="text-center body">
                      You have no modules to view.
                    </p>
                  </div>
                </div>
              )}
            {optionalModules === null && (
              <div className="flex flex-col justify-center items-center w-full h-[225.63px]">
                <div className="w-[60px] h-[60px]">
                  <HourGlassIcon />
                </div>
                <div className="mt-0.625">
                  <p className="text-center body">
                    Content loading, please wait.
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Trainings;
