import React from "react";

const EnvelopeIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9554 3.94922H4.27196C3.19372 3.94922 2.32133 4.83141 2.32133 5.90965L2.31152 17.6723C2.31152 18.7505 3.19372 19.6327 4.27196 19.6327H19.9554C21.0337 19.6327 21.9159 18.7505 21.9159 17.6723V5.90965C21.9159 4.83141 21.0337 3.94922 19.9554 3.94922ZM19.9554 7.87009L12.1137 12.7712L4.27196 7.87009V5.90965L12.1137 10.8107L19.9554 5.90965V7.87009Z"
        fill="white"
      />
    </svg>
  );
};

export default EnvelopeIcon;
