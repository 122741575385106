const EcosystemLinearSVG = () => {
  const position = {
    x1: 697,
    y1: 0,
    x2: 697,
    y2: 230,
  };

  if (position.x1 === position.x2) {
    position.x1 -= 0.0001;
  }

  return (
    <svg
      width="1187"
      height="211"
      viewBox="0 0 1387 211"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient
          id="electric-gradient"
          x1="0%"
          y1="0%"
          x2="0%"
          y2="100%"
        >
          <stop offset="0%" stopColor="#1d1d1d59" />
          <stop offset="40%" stopColor="#1d1d1d59">
            <animate
              attributeName="offset"
              values="0;1"
              dur="2s"
              repeatCount="indefinite"
            />
          </stop>
          <stop offset="50%" stopColor="#00FF82">
            <animate
              attributeName="offset"
              values="0.1;1.1"
              dur="2s"
              repeatCount="indefinite"
            />
          </stop>
          <stop offset="60%" stopColor="#1d1d1d59">
            <animate
              attributeName="offset"
              values="0.2;1.2"
              dur="2s"
              repeatCount="indefinite"
            />
          </stop>
          <stop offset="100%" stopColor="#1d1d1d59" />
        </linearGradient>
      </defs>

      <path
        d={`M${position.x1} ${position.y1}L${position.x2} ${position.y2}`}
        stroke="url(#electric-gradient)"
        strokeWidth="4.59846"
        fill="none"
      />

      <path
        d="M1386.04 -2.41894C1143.24 77.7809 1007.12 130.033 764.326 205.395"
        stroke="url(#electric-gradient)"
        strokeWidth="4.59846"
        fill="none"
      />
      <path
        d="M0.981425 -5.42348C243.775 77.7855 383.899 130.038 626.692 205.4"
        stroke="url(#electric-gradient)"
        strokeWidth="4.59846"
        fill="none"
      />
      <path
        d="M1041.13 -5.61992L738.997 205.397"
        stroke="url(#electric-gradient)"
        strokeWidth="4.59846"
        fill="none"
      />
      <path
        d="M349.487 -5.41992C349.487 2.41992 537.407 126.91 649.488 205.397"
        stroke="url(#electric-gradient)"
        strokeWidth="4.59846"
        fill="none"
      />
    </svg>
  );
};

export default EcosystemLinearSVG;
