import React from "react";

const BlastRadiusAnalysisLogo = () => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.056 29.496L18.2453 22.7333M18.2453 22.7333L14.8987 25.7L15.6573 13.0733L22.6267 23.6293L18.2453 22.7333ZM8.22133 22.3773C6.9423 21.0981 5.99847 19.5232 5.47347 17.7921C4.94846 16.061 4.85848 14.2272 5.21148 12.453C5.56449 10.6789 6.34959 9.01912 7.49724 7.62085C8.6449 6.22257 10.1197 5.12891 11.791 4.43674C13.4622 3.74456 15.2784 3.47524 17.0787 3.65263C18.8789 3.83001 20.6076 4.44863 22.1116 5.45369C23.6157 6.45875 24.8486 7.81922 25.7013 9.41459C26.554 11.01 27.0001 12.791 27 14.6M11.0507 19.5493C10.2366 18.7353 9.63578 17.7331 9.30154 16.6315C8.96729 15.5299 8.9099 14.3628 9.13446 13.2337C9.35901 12.1046 9.85858 11.0483 10.5889 10.1584C11.3192 9.26851 12.2577 8.57246 13.3213 8.13193C14.3849 7.6914 15.5407 7.51998 16.6864 7.63287C17.8321 7.74576 18.9322 8.13946 19.8894 8.77911C20.8465 9.41876 21.6312 10.2846 22.1738 11.2999C22.7163 12.3153 23.0001 13.4487 23 14.6"
        stroke="#00FF82"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BlastRadiusAnalysisLogo;
